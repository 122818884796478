import { Typography } from '@mui/material'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
    SearchOutlinedIcon,
    KeyboardArrowDownOutlinedIcon,
    FlashOnOutlinedIcon,
    PlumbingOutlinedIcon,
} from '../../Assets'
import { GetServicesAction } from '../../Store/Actions/GetActions'
import { BASE_URL } from '../../Utils/response'
import DropDownCmp from '../DropDownCmp/DropDownCmp'
import InputCmp from '../InputCmp/InputCmp'
import styles from './FilterSider.module.css'

const FilterSideBar = ({
    handlefilterBySearch,
    handlesortBySearch,
    handleSearchByCity,
    handleSearchByService,
    filterObj,
    setFilterObj,
}) => {
    const [openNew, setOpenNew] = useState(false)
    const [openServices, setOpenServices] = useState(false)

    const [searchValue, setSearchValue] = useState('')

    const handleSortSearch = (value) => {
        setFilterObj({
            ...filterObj,
            sort: value,
        })
        handlesortBySearch(value)
        setOpenNew(!openNew)
    }

    const dispatch = useDispatch()
    const { servicesdata, servicesLoading } = useSelector(
        (state) => state.ServicesReducer
    )
    useEffect(() => {
        dispatch(GetServicesAction())
    }, [])

    const serviceSelect = (service) => {
        setFilterObj({
            ...filterObj,
            service: [service],
        })
        handleSearchByService(service)
        setOpenServices(false)
    }

    return (
        <section className={styles.sideBarWrapper}>
            <div className={styles.myJobHeader}>
                <div>
                    <Typography variant="h6" fontWeight={'bold'}>
                        Customize your list view
                    </Typography>
                </div>
            </div>
            {handlefilterBySearch && (
                <div className={styles.inputFields}>
                    <label htmlFor="">Search</label>
                    <div className={styles.searchInput}>
                        <input
                            type="text"
                            value={filterObj?.search}
                            placeholder="Search by Project name"
                            onChange={(e) =>
                                setFilterObj({
                                    ...filterObj,
                                    search: e.target.value,
                                })
                            }
                        />
                        <div
                            className={styles.searchIcon}
                            onClick={() => handlefilterBySearch(searchValue)}
                        >
                            <SearchOutlinedIcon />
                        </div>
                    </div>
                </div>
            )}
            {handlesortBySearch && (
                <div className={styles.dropDown}>
                    <label htmlFor="">Sort By New/Old</label>
                    <div
                        className={styles.customDropDown}
                        onClick={() => setOpenNew(!openNew)}
                    >
                        <p>{filterObj?.sort || 'Select'}</p>
                        <KeyboardArrowDownOutlinedIcon />
                    </div>

                    {openNew && (
                        <>
                            <div className={styles.customOption}>
                                <ul>
                                    <li
                                        onClick={() =>
                                            handleSortSearch('newest')
                                        }
                                    >
                                        Newest
                                    </li>
                                    <li
                                        onClick={() =>
                                            handleSortSearch('oldest')
                                        }
                                    >
                                        Oldest
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            )}
            {/* //LOCATION CITIES */}
            {handleSearchByCity && (
                <div className={styles.dropDown}>
                    <label htmlFor="">Search By City</label>
                    <div className={styles.searchInput}>
                        <input
                            type="text"
                            value={filterObj?.city}
                            placeholder="Search by City"
                            onChange={(e) =>
                                setFilterObj({
                                    ...filterObj,
                                    city: e.target.value,
                                })
                            }
                        />
                        <div
                            className={styles.searchIcon}
                            onClick={() => handleSearchByCity(filterObj?.city)}
                        >
                            <SearchOutlinedIcon />
                        </div>
                    </div>
                    {/* <div className={styles.searchCity}>
          <input
            placeholder="Search city"
            onChange={(e) => handleSearchCity(e.target.value)}
            // setSearchCity(e.target.value)
            // value={searchCity}
            onBlur={() => console.log("outside click")}
          />
          <KeyboardArrowDownOutlinedIcon />
        </div> */}
                    {/* {openLocation && (
          <>
            <div className={styles.customOption}>
              <ul>
                <li>Electric</li>
                <li>Plumbing</li>
              </ul>
            </div>
          </>
        )} */}
                    <>
                        {/* {USACities.length && (
            <div className={styles.customOption}>
              <ul>
                {USACities.map((city) => {
                  return <li key={city.id}>{city.label}</li>;
                })}
              </ul>
            </div>
          )} */}
                    </>
                </div>
            )}
            {/* ///SERVIES */}
            {handleSearchByService && (
                <div className={styles.dropDown}>
                    <label htmlFor="">Filter By Service</label>
                    <div
                        className={styles.customDropDown}
                        onClick={() => setOpenServices(!openServices)}
                    >
                        <p>{filterObj?.service[0] || 'Select'}</p>
                        <KeyboardArrowDownOutlinedIcon />
                    </div>

                    {openServices && (
                        <>
                            <div
                                className={`${styles.customOption} ${styles.servicesDropDownWrapper}`}
                            >
                                <ul>
                                    {servicesdata.length ? (
                                        servicesdata.map((service) => {
                                            return (
                                                <li
                                                    key={service.id}
                                                    onClick={() =>
                                                        serviceSelect(
                                                            service.slug
                                                        )
                                                    }
                                                >
                                                    {service.label}
                                                </li>
                                            )
                                        })
                                    ) : (
                                        <li
                                        // onClick={() => handleSortSearch("newest")}
                                        >
                                            No services
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            )}
        </section>
    )
}

export default FilterSideBar
