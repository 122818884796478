import PropTypes from 'prop-types'
import { Navbar, SideBarNav } from '..'
import './index.css'
import useGetBusinessArea from '../../Hooks/getBusinessArea'

function Layout(props) {
    const businessArea = useGetBusinessArea()

    return (
        <>
            <Navbar />

            <div class="contentContainer">
                {businessArea &&
                    !['dashboard', 'chat', 'profile'].includes(
                        businessArea
                    ) && (
                        <div class="sidebar ">
                            {' '}
                            <SideBarNav icon={true} />
                        </div>
                    )}
                <div class="content">{props.children}</div>
            </div>
        </>
    )
}

Layout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
}

export default Layout
