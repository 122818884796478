import * as Yup from 'yup'

const validation = {
    createJobValidation: Yup.object({
        jobTitle: Yup.string().required('Job title is required'),
        street: Yup.string().required('Password is required'),
        city: Yup.object()
            .shape({
                label: Yup.string(),
                id: Yup.number(),
            })
            .nullable()
            .required('City is required.'),
        state: Yup.object()
            .shape({
                label: Yup.string(),
                id: Yup.number(),
            })
            .nullable()
            .required('State is required.'),
        zipCode: Yup.string().required('Password is required'),
        // biddingStartDate: Yup.string().required("Password is required"),
        // biddingCloseDate: Yup.string().required("Password is required"),
        jobDesc: Yup.string().required('Password is required'),
        jobStatus: Yup.string().required('Job Status is required').nullable(),
        services: Yup.array().of(Yup.object()).min(1, 'at least 1 service'),
        budget: Yup.string().required('Budget is required'),
        jobStartDate: Yup.string().required('Job start Date is required'),
        biddingStartDate: Yup.string().required(
            'Bidding Start  Date is required'
        ),
        biddingCloseDate: Yup.string().required(
            'Bidding Close  Date is required'
        ),
    }),

    servicesInputValidaion: Yup.object({
        inviteProConnection: Yup.string().required('Field is required'),
        inviteEmail: Yup.string().email('email').required('Field is required'),
    }),

    signupValidation: Yup.object({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().email().required('Email Address is required'),
        userType: Yup.string().required('Password is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 16 characters'),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match'
        ),
    }),
    createProposalValidation: Yup.object({
        bid_amount: Yup.string().required('Bid Amount is required'),
        bid_duration: Yup.string().required('Bid Duration is required'),
        proposal_bid_note: Yup.string().required('Bid Note is required'),
    }),

    createBSProject: Yup.object({
        jobTitle: Yup.string().required('Job title is required'),
        street: Yup.string().required('Password is required'),
        city: Yup.object()
            .shape({
                label: Yup.string(),
                id: Yup.number(),
            })
            .nullable()
            .required('City is required.'),
        state: Yup.object()
            .shape({
                label: Yup.string(),
                id: Yup.number(),
            })
            .nullable()
            .required('State is required.'),
        zipCode: Yup.string().required('Password is required'),
        jobDesc: Yup.string().required('Password is required'),
        services: Yup.array().of(Yup.object()).min(1, 'at least 1 service'),
    }),

    pdfRename: Yup.object({
        pdfTitle: Yup.string().required('Field is required'),
    }),

    productValidation: Yup.object({
        title: Yup.string().required('Please enter Product name'),
        brandName: Yup.object()
            .typeError('Brand Name is Required')
            .required('Brand Name is Required'),
        category: Yup.object().typeError('Category is Required').required(''),
        colors: Yup.array()
            .of(
                Yup.object({
                    // finish: Yup.array().min(
                    //     1,
                    //     'At least one Finish is required'
                    // ),
                    // joint: Yup.string().required("Joint is required"),
                    // primer_id: Yup.string().required("Primer is required"),
                    // material_id : Yup.string().required("Material is required"),
                    // pattern : Yup.string().required("Material is required"),
                    // first_coat : Yup.string().required("Material is required"),
                    // second_coat : Yup.string().required("Material is required"),
                    // glass_type : Yup.string().required("Glass Type is required"),
                })
            )
            .min(1, 'At least one Finish is required'),
        // size: Yup.string().lowercase().notOneOf(['small','medium','large', 'extra Large','extra-large', 'extralarge', null], 'Cannot be Small, Medium, Large, Extra Large'),
        measurements: Yup.array()
            .of(
                Yup.object({
                    width: Yup.number()
                        .typeError('Must be a number')
                        .max(100, 'Cannot exceed 100')
                        .required('Required'),
                    depth: Yup.number()
                        .typeError('Must be a number')
                        .max(100, 'Cannot exceed 100')
                        .required('Required'),
                    length: Yup.number()
                        .typeError('Must be a number')
                        .max(100, 'Cannot exceed 100')
                        .required('Required'),
                    height: Yup.number()
                        .typeError('Must be a number')
                        .max(100, 'Cannot exceed 100')
                        .required('Required'),
                    weight: Yup.number()
                        .typeError('Must be a number')
                        .max(100, 'Cannot exceed 100')
                        .required('Required'),
                    //delivery_time: Yup.number().max(100, "Cannot exceed 100").required("Required"),
                })
            )
            .min(1, 'Select atleast 1 Measurement'),

        prices: Yup.array().of(
            Yup.object({
                price: Yup.number()
                    .typeError('Must be a number')
                    .max(100000, 'Cannot exceed 100000')
                    .required('Required'),
                quantity: Yup.number()
                    .typeError('Must be a number')
                    .max(100000, 'Cannot exceed 100000')
                    .required('Required'),
                discount_price: Yup.number()
                    .typeError('Must be a number')
                    .max(100000, 'Cannot exceed 100000'),
                //color_image: Yup.object().required("Required")
            })
        ),
    }),
}

export default validation
