import React, { useRef, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import styles from './ServiceAccordion.module.css'
import { ButtonCmp, DropDownCmp, InputCmp, WhiteButtonCmp } from '..'
import { Box } from '@mui/material'
import {
    CloudUploadIcon,
    HighlightOffOutlinedIcon,
    PdfIcon,
} from '../../Assets'
import DragNDrop from '../DragNDrop/DragNDrop'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validation } from '../../Config'
import { useEffect } from 'react'

export default function ServiceAccordion({
    setOpen,
    open,
    label,
    // append,
    setValue,
    finalServicesState,
    // replace,
    indexNum,
    invite_by_connections,
    invite_by_emails,
}) {
    const [tags, setTags] = React.useState(['Taimoor John', 'Jaffar', 'Inno'])

    const formValidation = validation.servicesInputValidaion

    const {
        handleSubmit,
        control,
        reset,
        setError,
        watch,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            inviteProConnection: '',
            inviteEmail: '',
        },
        // resolver: yupResolver(formValidation),
    })

    const inviteProConnectionState = watch('inviteProConnection')
    const inviteEmailState = watch('inviteEmail')

    const [invitePro, setInvitePro] = React.useState([])

    const [inviteEmail, setInviteEmail] = React.useState([])

    const inviteProHandler = (obj, btn) => {
        if (btn === 'invitePro') {
            if (!inviteProConnectionState) {
                setError('inviteProConnection', {
                    type: 'custom',
                    message: 'Your field is empty',
                })
                return
            }
            setInvitePro((prop) => [...prop, inviteProConnectionState])
            reset({
                ...getValues(),
                inviteProConnection: '',
            })
        }
    }
    ///email handler
    const inviteEmailHandler = (obj, btn) => {
        if (btn === 'email') {
            if (!inviteEmailState) {
                setError('inviteEmail', {
                    type: 'custom',
                    message: 'Your field is empty',
                })
                return
            }

            setInviteEmail((prop) => [...prop, inviteEmailState])
            reset({
                ...getValues(),
                inviteEmail: '',
            })
        }
    }

    useEffect(() => {
        invite_by_connections(indexNum, invitePro)
    }, [invitePro])

    useEffect(() => {
        invite_by_emails(indexNum, inviteEmail)
    }, [inviteEmail])

    const removeTags = (index, tagName) => {
        if (tagName === 'invitePro') {
            invitePro.splice(index, 1)
            setInvitePro([...invitePro])
        } else {
            inviteEmail.splice(index, 1)
            setInviteEmail([...inviteEmail])
        }
    }
    return (
        <div>
            <Accordion
                sx={{ my: '15px', border: '1px solid var(--borderColor)' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={styles.AccordionParent}
                    sx={{ padding: '10px 40px' }}
                >
                    <Typography fontWeight={'bold'} className={styles.fieldSet}>
                        {label}
                    </Typography>
                    {/* <Typography fontWeight={"bold"} >
            Accounting Service
          </Typography> */}
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '20px 40px' }}>
                    <Box className={styles.inviteFriends}>
                        <Typography fontWeight={'bold'} fontSize={'medium'}>
                            Invite User From Pro Connection
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                my: '20px',
                            }}
                        >
                            <InputCmp
                                label="User ID/Full Name"
                                control={control}
                                name="inviteProConnection"
                            />
                            <WhiteButtonCmp
                                title="Invite"
                                // onClick={() => {
                                //   setValue(`services.${indexNum}`, {
                                //     // ...finalServicesState,
                                //     attachment: "jaffar Aman",
                                //   });
                                // }}
                                // onClick={handleSubmit(
                                //   async (data) => await inviteProHandler(data, "invitePro")
                                // )}
                                onClick={() =>
                                    inviteProHandler('data', 'invitePro')
                                }
                            />
                        </Box>
                        <Box
                            sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}
                            className={styles.inviteTagsBox}
                        >
                            {invitePro?.map((tags, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <HighlightOffOutlinedIcon
                                            onClick={() =>
                                                removeTags(index, 'invitePro')
                                            }
                                            sx={{ cursor: 'pointer' }}
                                        />
                                        <Typography
                                            fontSize={'small'}
                                            fontWeight="bold"
                                        >
                                            {tags}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box className={styles.inviteViaEmail2} sx={{ my: '20px' }}>
                        <Typography fontSize={'medium'} fontWeight="bold">
                            Invite Via Email
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                my: '20px',
                            }}
                        >
                            <InputCmp
                                label="Email"
                                control={control}
                                type="email"
                                name="inviteEmail"
                            />
                            <ButtonCmp
                                title="Invite"
                                // onClick={() => {
                                //   setOpen(!open);
                                // }}
                                btnColor="success"
                                onClick={handleSubmit(
                                    async (data) =>
                                        await inviteEmailHandler(data, 'email')
                                )}
                            />
                        </Box>
                        <Box
                            sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}
                            className={styles.inviteTagsBox}
                        >
                            {inviteEmail?.map((tags, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <HighlightOffOutlinedIcon
                                            onClick={() => removeTags(index)}
                                            sx={{ cursor: 'pointer' }}
                                        />
                                        <Typography
                                            fontSize={'small'}
                                            fontWeight="bold"
                                        >
                                            {tags}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    {/* ///attachment// */}
                    {/* <Box
            className={styles.inviteViaEmail}
            sx={{ mt: "30px", mb: "20px" }}
          >
            <Typography fontSize={"medium"} fontWeight="bold">
              Attach Document(s)
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                my: "20px",
                alignItems: "flex-start",
              }}
              className={styles.dnd_wrapper}
            >
              <div>
                <DragNDrop pdf={true} />
              </div>

              <div className={styles.filesName}>
                {[0, 1, 2].map((pdf, index) => {
                  return (
                    <div className={styles.filesName_listing} key={index}>
                      <img src={PdfIcon} alt="" />
                      <Typography fontSize={"small"}>
                        This is sample-pdf-attachm...
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </Box>
          </Box> */}

                    {/* ////Browse build studio/// */}
                    {/* <Box className={styles.inviteViaEmail} sx={{ my: "20px" }}>
            <Typography fontSize={"medium"} fontWeight="bold">
              Or Browse From Build Studio
            </Typography>
            <Box
              sx={{ display: "flex", gap: 2, alignItems: "center", my: "20px" }}
            >
              <DropDownCmp
                control={control}
                name="Search or Select"
                label="Search or Select"
              />
            </Box>
          </Box> */}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
