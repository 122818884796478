import {
    deleteMethodCustomHeader,
    getMethodCustomHeader,
    postMethodCustomHeader,
    postMethodMultiPartHeader,
} from '../../Utils/response'
import { dateFormat } from '../../Utils/utils'
import ActionTypes from '../Constants'

const AllUserProjectAction = (
    perPage,
    page,
    searchParam,
    sortParam,
    locationParam,
    serviceParam
) => {
    console.log(searchParam, 'searchParam')
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_ALLUSER_PROJECT_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects?per_page=${perPage}&page=${page}&search=${
                    searchParam || ''
                }&sortBy=${sortParam || ''}&location=${locationParam || ''}&service=${
                    serviceParam || ''
                }`
            )
            console.log(response, 'response')
            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.GET_ALLUSER_PROJECT_SUCCESS,
                    payload: response.data,
                })
            } else {
                dispatch({
                    type: ActionTypes.GET_ALLUSER_PROJECT_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_ALLUSER_PROJECT_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const AwardedProjectAction = (
    perPage,
    page,
    setAlert,
    searchParam,
    sortParam
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_AWARDED_PROJECT_REQUEST,
            })

            // const response = await getMethodCustomHeader(
            //   `user/projects-awarded?per_page=${perPage}&page=${page}`
            // );
            const response = await getMethodCustomHeader(
                `user/projects-awarded?per_page=${perPage}&page=${page}&search=${
                    searchParam || ''
                }&sortBy=${sortParam || ''}`
            )
            console.log(response, 'response')
            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.GET_AWARDED_PROJECT_SUCCESS,
                    payload: response.data,
                })
            } else {
                dispatch({
                    type: ActionTypes.GET_ALLUSER_PROJECT_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            setAlert(error?.message, 'error')
            console.log(error?.message, 'errro new awareded')
            dispatch({
                type: ActionTypes.GET_ALLUSER_PROJECT_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const CreateJobAction = (data, setAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.CREATE_JOB_REQUEST,
            })
            const {
                city,
                state,
                biddingCloseDate,
                biddingStartDate,
                jobDesc,
                jobStatus,
                jobTitle,
                services,
                street,
                zipCode,
                budget,
                jobStartDate,
                pdf,
                inviteProConnection,
            } = data
            const objToSend = {
                city_id: city?.id,
                state_id: state?.id,
                country_id: 167,
                // project_start_date: "2022-09-23",
                title: jobTitle,
                biding_start_date: dateFormat(biddingStartDate),
                biding_end_date: dateFormat(biddingCloseDate),
                address: street,
                zipcode: zipCode,
                description: jobDesc,
                // services: servicesArray,
                status: jobStatus,
                project_start_date: dateFormat(jobStartDate),
                budget_amount: budget,
            }

            const formData = new FormData()
            for (var key in objToSend) {
                formData.append(key, objToSend[key])
            }
            for (var i = 0; i < services.length; i++) {
                formData.append(`services[${i}][service]`, services[i].slug)
            }

            for (var j = 0; j < inviteProConnection.length; j++) {
                formData.append(
                    `invite_by_connections[${j}]`,
                    inviteProConnection[i].email
                )
            }

            for (var k = 0; k < pdf.length; k++) {
                formData.append(`pdf[${k}][pdf]`, pdf[k].file)
                formData.append(`pdf[${k}][title]`, pdf[k].title)
            }

            const response = await postMethodMultiPartHeader(
                'user/projects',
                formData
            )
            console.log('response create jobs', response)
            ///success true
            if (response.data.success) {
                setAlert(response.data.message, 'success')
                dispatch({
                    type: ActionTypes.CREATE_JOB_SUCCESS,
                    payload: response.data,
                })
                navigate('/buildbid/jobs')
            } else {
                setAlert(response?.data.message, 'error')
                dispatch({
                    type: ActionTypes.CREATE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            setAlert(error.response?.data.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.CREATE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const singleJobDetailAction = (jobSlug) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SINGLE_JOB_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects/${jobSlug}/bids`
            )

            console.log('response', response)

            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.SINGLE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                dispatch({
                    type: ActionTypes.SINGLE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.SINGLE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const singleBidDetailAction = (projectSlug, projectId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SINGLE_BID_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects/${projectSlug}/bids/${projectId}`
            )

            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.SINGLE_BID_SUCCESS,
                    payload: response.data,
                })
            } else {
                dispatch({
                    type: ActionTypes.SINGLE_BID_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.SINGLE_BID_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const DeleteJobAction = (projectSlug, setAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.DELETE_JOB_REQUEST,
            })

            const response = await deleteMethodCustomHeader(
                `user/projects/${projectSlug}`
            )

            ///success true
            if (response.data.success) {
                navigate('/buildbid/jobs')
                dispatch({
                    type: ActionTypes.DELETE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.DELETE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.DELETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const CompletedJobsAction = (
    per_page,
    page,
    setAlert,
    searchParam,
    sortParam
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_COMPLETED_JOB_REQUEST,
            })

            // const response = await getMethodCustomHeader(
            //   `user/projects-completed?per_page=${per_page}&page=${page}`
            // );

            const response = await getMethodCustomHeader(
                `user/projects-completed?per_page=${per_page}&page=${page}&search=${
                    searchParam || ''
                }&sortBy=${sortParam || ''}`
            )

            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.GET_COMPLETED_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                dispatch({
                    type: ActionTypes.GET_COMPLETED_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///
            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_COMPLETED_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const GetDeleteJobsAction = (perPage, page, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_DELETE_JOB_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects-deleted?per_page=${perPage}&page=${page}`
            )
            ///success true
            if (response.data.success) {
                dispatch({
                    type: ActionTypes.GET_DELETE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                // setAlert(response.data?.message, "error");
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.GET_DELETE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_DELETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const RestoreDeleteJobAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.RESTORE_DELETE_JOB_REQUEST,
            })

            const response = await postMethodCustomHeader(
                `user/project-restore/${projectSlug}`
            )
            console.log('response', response)
            ///success true
            if (response.data.success) {
                setAlert(response.data?.data?.message, 'error')

                dispatch({
                    type: ActionTypes.RESTORE_DELETE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                // setAlert(response.data?.message, "error");
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.RESTORE_DELETE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.RESTORE_DELETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const AcceptProposalActon = (projectSlug, bidId, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.ACCEPT_PROPOSAL_REQUEST,
            })

            const response = await postMethodCustomHeader(
                `user/projects/${projectSlug}/bids/${bidId}`
            )
            ///success true
            if (response.data.success) {
                // setAlert(response.data?.data.message, "success");
                dispatch({
                    type: ActionTypes.ACCEPT_PROPOSAL_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.ACCEPT_PROPOSAL_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.RESTORE_DELETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const PermanentDeleteJobAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.PERMANENT_DELETE_JOB_REQUEST,
            })

            const response = await postMethodCustomHeader(
                `user/project-permanent-delete/${projectSlug}`
            )
            console.log('response', response)
            ///success true
            if (response.data.success) {
                setAlert(response.data?.data?.message, 'error')

                dispatch({
                    type: ActionTypes.PERMANENT_DELETE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                // setAlert(response.data?.message, "error");
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.PERMANENT_DELETE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.PERMANENT_DELETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

////GET project MileStone
const GetProjectMileStonesAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_AWARDED_JOB_MILESTONE_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects/${projectSlug}/milestones`
            )
            ///success true
            if (response.data.success) {
                // setAlert(response.data?.message, "success");
                dispatch({
                    type: ActionTypes.GET_AWARDED_JOB_MILESTONE_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.GET_AWARDED_JOB_MILESTONE_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            console.log(
                error.response.data.message,
                'newwwwwwwwwwwwww arha hai errro'
            )
            dispatch({
                type: ActionTypes.GET_AWARDED_JOB_MILESTONE_FAIL,
                payload: error.response.data,
            })
        }
    }
}

///ACCEPT Milestone Action
const AcceptMileStoneAction = (
    projectSlug,
    mileStoneId,
    setAlert,
    mileStoneStatus
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.ACCEPT_MILESTONE_REQUEST,
            })

            const objToSend = {
                status: mileStoneStatus,
            }
            const response = await postMethodCustomHeader(
                `user/projects/${projectSlug}/milestones/${mileStoneId}/action`,
                objToSend
            )
            console.log('response AcceptMileStoneAction', response)
            ///success true
            if (response.data.success) {
                setAlert(response.data.message, 'success')

                dispatch({
                    type: ActionTypes.ACCEPT_MILESTONE_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.ACCEPT_MILESTONE_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.ACCEPT_MILESTONE_FAIL,
                payload: error.response.data,
            })
        }
    }
}

////GET Single Project
const GetSingleJobAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_SINGLE_JOB_REQUEST,
            })

            console.log('get single project projectSlug', projectSlug)

            const response = await getMethodCustomHeader(
                `user/projects/${projectSlug}`
            )
            console.log('get single project', response)
            ///success true
            if (response.data.success) {
                // setAlert(response.data?.message, "success");
                dispatch({
                    type: ActionTypes.GET_SINGLE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.GET_SINGLE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_SINGLE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

////JOB UPDATE
const JobUpdateAction = (projectSlug, obj, setAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.JOB_UDPATE_REQUEST,
            })

            const {
                city,
                state,
                biddingCloseDate,
                biddingStartDate,
                jobDesc,
                jobStatus,
                jobTitle,
                services,
                street,
                zipCode,
                budget,
                jobStartDate,
                delete_pdf,
                pdf,
            } = obj

            // const servicesArray = services.map((obj, ind) => {
            //   return {
            //     service: obj.slug,
            //     invite_by_connections: obj.invite_by_connections,
            //     invite_by_emails: obj.invite_by_emails,
            //     attachments: [1, 12],
            //   };
            // });
            const objToSend = {
                city_id: city?.id,
                state_id: state?.id,
                country_id: 167,
                // project_start_date: "2022-09-23",
                title: jobTitle,
                biding_start_date: dateFormat(biddingStartDate),
                biding_end_date: dateFormat(biddingCloseDate),
                address: street,
                zipcode: zipCode,
                description: jobDesc,
                // services: servicesArray,
                status: jobStatus,
                project_start_date: dateFormat(jobStartDate),
                budget_amount: budget,
            }

            const formData = new FormData()
            for (var key in objToSend) {
                formData.append(key, objToSend[key])
            }

            for (var i = 0; i < services.length; i++) {
                formData.append(`services[${i}][service]`, services[i].slug)
            }

            const newPDF = pdf.filter((pdf) => !pdf.id)
            for (var j = 0; j < newPDF.length; j++) {
                formData.append(`pdf[${j}][pdf]`, newPDF[j].file)
                formData.append(`pdf[${j}][title]`, newPDF[j].title)
            }

            for (var k = 0; k < delete_pdf.length; k++) {
                formData.append(`delete_pdf[${k}]`, delete_pdf[k])
            }

            const response = await postMethodMultiPartHeader(
                `user/projects/${projectSlug}`,
                formData
            )

            ///success true
            if (response.data.success) {
                setAlert(response.data?.message, 'success')
                navigate('/buildbid/jobs')
                dispatch({
                    type: ActionTypes.JOB_UDPATE_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.JOB_UDPATE_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.JOB_UDPATE_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const AcceptEndContractAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.ACCEPT_END_CONTRACT_REQUEST,
            })

            const objToSend = {
                status: 'Accepted',
            }
            console.log('objToSend', objToSend)
            const response = await postMethodCustomHeader(
                `user/proposal-action/${projectSlug}`,
                objToSend
            )
            console.log(response, 'response end contract')
            ///success true
            if (response.data.success) {
                setAlert(response.data.message, 'success')
                dispatch({
                    type: ActionTypes.ACCEPT_END_CONTRACT_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.ACCEPT_END_CONTRACT_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.ACCEPT_END_CONTRACT_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const EmptyRestoreDeleteState = () => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.EMPTY_RESTORE_DELETE_STATE,
        })
    }
}

const EmptyPermanentDeleteState = () => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.EMPTY_PERMANENT_DELETE_STATE,
        })
    }
}

const EmptyDeleteJobState = () => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.EMPTY__DELETE_JOB_STATE,
        })
    }
}

const EmptyAcceptProposalState = () => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.EMPTY_ACCEPT_PROPOSAL_STATE,
        })
    }
}

const OwnerRatingAction = (projectSlug, objToSend, setAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.OWNER_RATING_REQUEST,
            })
            console.log(objToSend, 'objToSend action')
            const response = await postMethodCustomHeader(
                `user/project/${projectSlug}/contractor-review-to-freelancer`,
                objToSend
            )
            console.log(response, 'response rating...')

            ///success true
            if (response.data.success) {
                navigate('/completedjob')
                setAlert(response.data.message, 'success')
                dispatch({
                    type: ActionTypes.OWNER_RATING_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.OWNER_RATING_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.OWNER_RATING_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const BidderRatingAction = (projectSlug, objToSend, setAlert, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.BIDDER_RATING_REQUEST,
            })
            console.log(objToSend, 'objToSend action')
            const response = await postMethodCustomHeader(
                `user/project/${projectSlug}/freelancer-review-to-contractor`,
                objToSend
            )

            ///success true
            if (response.data.success) {
                navigate('/completedproject')
                setAlert(response.data.message, 'success')
                dispatch({
                    type: ActionTypes.BIDDER_RATING_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                dispatch({
                    type: ActionTypes.BIDDER_RATING_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.BIDDER_RATING_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const GetSingleCompleteJobAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_SINGLE_COMPLETE_JOB_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `user/projects/${projectSlug}`
            )
            console.log('get single complete project', response)
            ///success true
            if (response.data.success) {
                // setAlert(response.data?.message, "success");
                dispatch({
                    type: ActionTypes.GET_SINGLE_COMPLETE_JOB_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.GET_SINGLE_COMPLETE_JOB_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_SINGLE_COMPLETE_JOB_FAIL,
                payload: error.response.data,
            })
        }
    }
}

const GetSingleCompleteProjectAction = (projectSlug, setAlert) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_SINGLE_COMPLETE_PROJECT_REQUEST,
            })

            const response = await getMethodCustomHeader(
                `projects/${projectSlug}`
            )
            console.log('get single complete project', response)
            ///success true
            if (response.data.success) {
                // setAlert(response.data?.message, "success");
                dispatch({
                    type: ActionTypes.GET_SINGLE_COMPLETE_PROJECT_SUCCESS,
                    payload: response.data,
                })
            } else {
                setAlert(response.data?.message, 'error')
                console.log('false chalana hai ')
                dispatch({
                    type: ActionTypes.GET_SINGLE_COMPLETE_PROJECT_FAIL,
                    payload: response.data,
                })
            }
        } catch (error) {
            ///

            setAlert(error?.response?.data?.message, 'error')
            console.log(error.response.data.message, 'errro')
            dispatch({
                type: ActionTypes.GET_SINGLE_COMPLETE_PROJECT_FAIL,
                payload: error.response.data,
            })
        }
    }
}

export {
    AllUserProjectAction,
    AwardedProjectAction,
    CreateJobAction,
    singleJobDetailAction,
    singleBidDetailAction,
    DeleteJobAction,
    GetDeleteJobsAction,
    CompletedJobsAction,
    RestoreDeleteJobAction,
    EmptyRestoreDeleteState,
    EmptyDeleteJobState,
    AcceptProposalActon,
    EmptyAcceptProposalState,
    PermanentDeleteJobAction,
    EmptyPermanentDeleteState,
    GetProjectMileStonesAction,
    AcceptMileStoneAction,
    GetSingleJobAction,
    JobUpdateAction,
    AcceptEndContractAction,
    OwnerRatingAction,
    BidderRatingAction,
    GetSingleCompleteJobAction,
    GetSingleCompleteProjectAction,
}
