import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { OptionUnstyled } from '@mui/base'

import styles from './MuiSelectBox.module.css'
import { OutlinedInput } from '@mui/material'

export default function MuiSelectBox({
    label,
    options,
    style,
    onChange,
    ...props
}) {
    const [value, setValue] = React.useState('')

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    return (
        <Box sx={{ minWidth: 190 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label="SORT"
                    onChange={(e) => {
                        handleChange(e)
                        onChange(e)
                    }}
                    className={styles.muiSelectBox}
                    input={<OutlinedInput label={label} />}
                    sx={{
                        width: '100%',
                        ...style,
                    }}
                    {...props}
                >
                    {options ? (
                        options.map((option, index) => {
                            return (
                                <MenuItem value={option} key={index}>
                                    {option}
                                </MenuItem>
                            )
                        })
                    ) : (
                        <MenuItem value={10}>Ten</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    )
}
