import { combineReducers } from 'redux'
import {
    ConfirmPasswordReducers,
    ForgetReducers,
    GetAllUserReducers,
    LoginReducers,
    OtpVerificationReducers,
    SignupReducers,
    UserLogoutReducer,
    UserRoleReducer,
} from './AuthReducers'
import { fabricCanvas } from './fabricCanvas'
import {
    MyBidsProjectReducers,
    MyBidsProjectDetailsReducer,
    MyBidsAddToWishlistReducer,
    MyBidsWishlistedProjectsReducer,
    MyBidsAppliedProjectsReducer,
    MyBidsCreateProposalReducer,
    MyBidsAwardedProjectsReducer,
    MyBidsSubmittedBidDetailsReducer,
    MyBidsCompletedProjectReducers,
    AddMileStoneReducer,
    DeleteMileStoneReducer,
    EditMileStoneReducer,
    EndContractReducer,
    MyBidsUpdateProposalReducer,
    OwnerRatingReducers,
    BidderRatingReducers,
} from './BuildBids-MyBidsReducers'

import {
    DeleteJobReducer,
    GetAllUserProjectReducer,
    SingleBidsReducer,
    SingleProjectReducer,
    GetDeleteJobReducer,
    CompletedJobsReducer,
    RestoreDeleteJobReducer,
    AcceptProposalReducer,
    AwardedJobsReducer,
    CreateJobReducer,
    PermanentDeleteJobReducer,
    GetAwardedJobMilestoneReducer,
    AccpetedMileStoneReducer,
    GetSingleJobReducer,
    AcceptEndContractReducer,
    GetSingleCompleteJobReducer,
    GetSingleCompleteProjectReducer,
} from './BuildBids-MyJobReducers'

import { ExampleReducers } from './exampleReducer'
import {
    StatesReducers,
    CitiesReducers,
    ServicesReducer,
    AddedServicesReducer,
    SelectedServiceReducer,
    LicensedServicesReducer,
    OtherServicesReducer,
    BrandsReducers,
    ColorsReducers,
    FinishingsReducers,
    MaterialsReducers,
    CoatsReducers,
    PrimersReducers,
    PatternsReducers,
    GlassTypesReducers,
    DistributorsReducers,
    SuppliersReducers,
    CategoriesReducers,
    SymbolReducers,
    DashboardDataReducers,
} from './GetReducers'
import {
    ProfielWizardReducer,
    UserProfileReducer,
    UpdateProfileReducer,
    GetCoverProjectReducer,
    SingleUserProfileReducer,
} from './ProfileReducers'
import {
    AddNewProjectReducer,
    GetPortfolioProjectReducer,
    DeletePortfolioProjectReducer,
    GetSinglePortfolioProjectReducer,
    UpdatePortfolioProjectReducers,
    ProfileCoverProjectReducer,
    GetDeletePortfolioProjectReducer,
    PermanentDeleteProjectReducer,
    RestoreDeleteProjectReducer,
} from './ProjectGalleryReducers'

import {
    AddProductReducer,
    UserProductsReducer,
    ProductDetailsReducer,
    DeleteProductReducer,
    GetDeletedProductsReducer,
    RestoreDeletedProductReducer,
    PermanentDeleteProductReducer,
    UpdateProductReducer,
    ProductBulkImportReducer,
} from './ManufacturerReducers'
import {
    CreateConnectionReducer,
    getPendingRequestsReducer,
    getReceivedRequestsReducer,
    SearchConnectionReducer,
    RecievedConnectionRequestReducer,
    RemoveConnectionRequestReducer,
    GetProConnectionReducer,
    RemoveConnectionReducer,
} from './ProConnectionReducer'
import {
    BuildStduioProjectReducers,
    DeleteBuildStduioProjectReducers,
    GetDeleteBSProjectReducers,
    RestoreBuildStduioProjectReducers,
    PermanentBuildStduioProjectReducers,
    GetSingleBSProjectReducer,
    GetServiceSymbolsReducer,
    saveServicesDataReducer,
    GetAllProductsReducer,
    CreateBSProjectReducer,
    UploadSinglePDFReducer,
    GetBSProjectReducer,
    EditBSProjectReducer,
} from './BuildStudio/BuildStudioReducers'

import {
    GetNotificationCountReducer,
    GetNotificationReducer,
} from './NotificationReducers.js'
import { PDFRenameReducer, PDFRemoveReducer } from './BuildStudio/PDFReducers'

const combineReduers = combineReducers({
    fabricCanvas,
    ExampleReducers,
    LoginReducers,
    SignupReducers,
    StatesReducers,
    CitiesReducers,
    UserRoleReducer,
    ServicesReducer,
    AddedServicesReducer,
    SelectedServiceReducer,
    LicensedServicesReducer,
    OtherServicesReducer,
    BrandsReducers,
    ColorsReducers,

    MaterialsReducers,
    CoatsReducers,
    PrimersReducers,
    PatternsReducers,
    GlassTypesReducers,
    DistributorsReducers,
    SuppliersReducers,
    UpdateProfileReducer,
    GetAllUserProjectReducer,
    MyBidsProjectReducers,
    MyBidsProjectDetailsReducer,
    MyBidsAddToWishlistReducer,
    MyBidsWishlistedProjectsReducer,
    MyBidsCreateProposalReducer,
    MyBidsSubmittedBidDetailsReducer,
    MyBidsAwardedProjectsReducer,
    MyBidsAppliedProjectsReducer,
    MyBidsCompletedProjectReducers,
    SingleProjectReducer,
    SingleBidsReducer,
    DeleteJobReducer,
    GetDeleteJobReducer,
    RestoreDeleteJobReducer,
    AcceptProposalReducer,
    AwardedJobsReducer,
    CompletedJobsReducer,
    CreateJobReducer,
    PermanentDeleteJobReducer,
    GetAwardedJobMilestoneReducer,
    AccpetedMileStoneReducer,
    AddMileStoneReducer,
    DeleteMileStoneReducer,
    EditMileStoneReducer,
    GetSingleJobReducer,
    ProfielWizardReducer,
    EndContractReducer,
    AddNewProjectReducer,
    UserProfileReducer,
    AcceptEndContractReducer,
    GetPortfolioProjectReducer,
    DeletePortfolioProjectReducer,
    GetSinglePortfolioProjectReducer,
    UpdatePortfolioProjectReducers,
    ProfileCoverProjectReducer,
    GetCoverProjectReducer,
    AddProductReducer,
    UserProductsReducer,
    ProductDetailsReducer,
    GetDeletePortfolioProjectReducer,
    PermanentDeleteProjectReducer,
    RestoreDeleteProjectReducer,
    CategoriesReducers,
    SymbolReducers,
    DeleteProductReducer,
    GetDeletedProductsReducer,
    RestoreDeletedProductReducer,
    PermanentDeleteProductReducer,
    MyBidsUpdateProposalReducer,
    UpdateProductReducer,
    ProductBulkImportReducer,
    SearchConnectionReducer,
    CreateConnectionReducer,
    getPendingRequestsReducer,
    getReceivedRequestsReducer,
    RecievedConnectionRequestReducer,
    RemoveConnectionRequestReducer,
    GetProConnectionReducer,
    RemoveConnectionReducer,
    BuildStduioProjectReducers,
    DeleteBuildStduioProjectReducers,
    GetDeleteBSProjectReducers,
    RestoreBuildStduioProjectReducers,
    PermanentBuildStduioProjectReducers,
    GetSingleBSProjectReducer,
    GetServiceSymbolsReducer,
    saveServicesDataReducer,
    GetAllProductsReducer,
    CreateBSProjectReducer,
    UploadSinglePDFReducer,
    GetBSProjectReducer,
    EditBSProjectReducer,
    GetNotificationReducer,
    GetNotificationCountReducer,
    DashboardDataReducers,
    OwnerRatingReducers,
    ForgetReducers,
    OtpVerificationReducers,
    ConfirmPasswordReducers,
    PDFRenameReducer,
    PDFRemoveReducer,
    GetSingleCompleteJobReducer,
    GetSingleCompleteProjectReducer,
    BidderRatingReducers,
    SingleUserProfileReducer,
    GetAllUserReducers,
    UserLogoutReducer,
    FinishingsReducers,
})

export default combineReduers
