import { Grid } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <Grid
            container
            sx={{
                justifyContent: 'space-between',
            }}
        ></Grid>
    )
}

export default Footer
