import React from 'react'
import ProductTabPanel from './ProductTabPanel'

import { ButtonCmp } from '../../../Components'
import InputBox from '../../../Components/InputBox/InputBox'
import { useForm, Controller } from 'react-hook-form'
import OutlinedInput from '@mui/material/OutlinedInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TrashBigSvg } from '../../../Assets'
import {
    Box,
    MenuItem,
    Checkbox,
    FormControl,
    FormGroup,
    InputLabel,
    Select,
    Grid,
    Typography,
} from '@mui/material'
import { useDispatch } from 'react-redux'

const SizeTab = (props) => {
    const { tabValue, control, watch, measurementsAppend, measurementsRemove } =
        props

    const measurements = watch('measurements')

    const {
        control: sizeControl,
        getValues: getSize,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(
            Yup.object({
                size: Yup.string()
                    .lowercase()
                    .notOneOf(
                        [
                            'small',
                            'medium',
                            'large',
                            'extra Large',
                            'extra-large',
                            'extralarge',
                            null,
                        ],
                        'Cannot be Small, Medium, Large, Extra Large'
                    ),
            })
        ),
        defaultValue: { size: '' },
    })

    const addMoreSizes = () => {
        let size = getSize('size')

        if (
            measurements?.find(
                (x) => x.title.toLowerCase() === size.toLowerCase()
            )
        ) {
            return
        }

        if (Object.keys(errors).length === 0 && size !== null && size !== '') {
            measurementsAppend({
                custom: true,
                measurement_id: 5 + measurements.length,
                slug: size,
                label: size,
                title: size,
            })
        }
    }

    const removeCustomSize = (i) => {
        measurementsRemove(i)
    }

    const measurementsdata = [
        {
            measurement_id: 1,
            label: 'Small',
            slug: 'small',
        },
        {
            measurement_id: 2,
            label: 'Medium',
            slug: 'medium',
        },
        {
            measurement_id: 3,
            label: 'Large',
            slug: 'large',
        },
        {
            measurement_id: 4,
            label: 'Extra Large',
            slug: 'extra-large',
        },
    ]

    return (
        <ProductTabPanel value={tabValue} index={2}>
            <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item lg={4}>
                    <FormControl
                        sx={{ m: 1, width: '100%' }}
                        variant="outlined"
                    >
                        <InputLabel id="measurements-label">
                            Select Measurements
                        </InputLabel>
                        <Controller
                            name="measurements"
                            control={control}
                            defaultValue={[]}
                            input={<OutlinedInput />}
                            render={({ field }) => (
                                <Select
                                    labelId="measurements-label"
                                    label="Select Measurements"
                                    multiple
                                    displayEmpty
                                    value={field.value}
                                    onChange={(e) => {}}
                                    renderValue={(selected) => {
                                        return selected
                                            .filter((x) => !x.custom)
                                            .map((x) => {
                                                console.log(x)
                                                return measurementsdata.find(
                                                    (y) => y.slug === x.slug
                                                )?.label
                                            })
                                            .join(' | ')
                                    }}
                                    disabled={
                                        measurements?.filter((x) => x.custom)
                                            .length > 0
                                    }
                                >
                                    {measurementsdata.map((measurement) => (
                                        <MenuItem
                                            key={measurement.slug}
                                            value={measurement.slug}
                                            onClick={(e) => {
                                                let i = measurements.findIndex(
                                                    (x) =>
                                                        x.measurement_id ===
                                                        measurement.measurement_id
                                                )
                                                if (i >= 0) {
                                                    measurementsRemove(i)
                                                } else {
                                                    measurementsAppend({
                                                        measurement_id:
                                                            measurement.measurement_id,
                                                        slug: measurement.slug,
                                                        label: measurement.label,
                                                        title: '',
                                                        custom: false,
                                                    })
                                                }
                                            }}
                                        >
                                            <Checkbox
                                                checked={
                                                    -1 <
                                                    measurements?.findIndex(
                                                        (x) =>
                                                            x.measurement_id ===
                                                            measurement.measurement_id
                                                    )
                                                }
                                            />
                                            {measurement.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    {measurements?.filter((x) => x.custom).length > 0 ? (
                        <Box
                            sx={{
                                fontSize: '0.6rem',
                                color: '#6495ED',
                                marginLeft: '1rem',
                            }}
                        >
                            Remove custom sizes to enable dropdown
                        </Box>
                    ) : (
                        ''
                    )}
                </Grid>
                <Grid item lg={4}>
                    {' '}
                    <InputBox
                        name={'size'}
                        type="text"
                        control={sizeControl}
                        label="Size"
                        disabled={
                            measurements?.filter((x) => !x.custom).length < 4
                        }
                    />
                </Grid>
                <Grid item lg={4}>
                    <ButtonCmp
                        sx={{
                            fontSize: '0.7rem',
                            marginTop: '1rem',
                            marginLeft: '1rem',
                        }}
                        title="Add sizes"
                        onClick={() => {
                            addMoreSizes()
                        }}
                        disabled={
                            measurements?.filter((x) => !x.custom).length < 4
                        }
                    />
                </Grid>
            </Grid>
            {!measurements ? (
                <Box sx={{ padding: '2rem' }}>
                    <Typography>No measurements selected</Typography>
                </Box>
            ) : (
                measurements?.map((measurement, index) => (
                    <div key={index}>
                        <Box
                            sx={{
                                marginBottom: '1.8rem',
                                marginTop: '1.8rem',
                                display: 'flex',
                                flexDirection: 'row',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                fontSize: '1.6rem',
                            }}
                            id={`${measurement.measurement_id}-label`}
                        >
                            {/* <Box sx={{ display: "flex" }}>{`${measurement.label}`}</Box> */}
                            <Box>
                                <Box
                                    sx={{
                                        margin: '0.4rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                    id={`${measurement.measurement_id}-label`}
                                >
                                    <Box
                                        sx={{ display: 'flex' }}
                                    >{`${measurement.title}`}</Box>
                                </Box>

                                {/* <InputBox
										name={`measurements[${index}].title`}
										type="text"
										control={control}
										label="Size"
										disabled={!measurement.custom}
									/> */}
                            </Box>
                            {measurement.custom ? (
                                <Box
                                    sx={{
                                        marginTop: '0.8rem',
                                        marginLeft: '1.8rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            removeCustomSize(index)
                                        }}
                                    >
                                        <TrashBigSvg />
                                    </div>
                                </Box>
                            ) : (
                                ''
                            )}
                        </Box>
                        <FormGroup key={`${measurement.measurement_id}-input`}>
                            <Grid
                                container
                                columnSpacing={2}
                                rowSpacing={4}
                                sx={{ marginBottom: '1rem' }}
                            >
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].width`}
                                        type="number"
                                        control={control}
                                        label="Width"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].package_width`}
                                        type="number"
                                        control={control}
                                        label="Package Width"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].height`}
                                        type="number"
                                        control={control}
                                        label="Height"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].package_height`}
                                        type="number"
                                        control={control}
                                        label="Package Height"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].length`}
                                        type="number"
                                        control={control}
                                        label="Length"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].package_length`}
                                        type="number"
                                        control={control}
                                        label="Package Length"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].depth`}
                                        type="number"
                                        control={control}
                                        label="Depth"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].package_depth`}
                                        type="number"
                                        control={control}
                                        label="Package Depth"
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].weight`}
                                        type="number"
                                        control={control}
                                        label="Weight"
                                        rules={{
                                            max: {
                                                value: 100,
                                                message:
                                                    'Weight cannot exceed 100',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].package_weight`}
                                        type="number"
                                        control={control}
                                        label="Package Weight"
                                        rules={{
                                            max: {
                                                value: 100,
                                                message:
                                                    'Package Weight cannot exceed 100',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4}>
                                    <InputBox
                                        name={`measurements[${index}].delivery_time`}
                                        type="number"
                                        control={control}
                                        label="Delivery Time"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </div>
                ))
            )}
        </ProductTabPanel>
    )
}

export default SizeTab
