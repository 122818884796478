import axios from 'axios'
import { getUserToken } from './LoginAuth'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL
export const IMG_URL = process.env.REACT_APP_IMG_URL
export const Symbol_URL = process.env.REACT_APP_SYMBOLS_URL

const getHeader = () => {
    return {
        Authorization: 'Bearer ' + getUserToken(),
    }
}

const getMultiPartHeader = () => {
    return {
        Authorization: 'Bearer ' + getUserToken(),
        'content-type': 'multipart/form-data',
    }
}

const postMethodCustomHeader = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/${relativesUrl}`, obj, {
                headers: getHeader(),
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
const postMethodMultiPartHeader = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/${relativesUrl}`, obj, {
                headers: getMultiPartHeader(),
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const putMethodCustomHeader = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${BASE_URL}/${relativesUrl}`, obj, {
                headers: getHeader(),
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const putMethodMultiPartHeader = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${BASE_URL}/${relativesUrl}`, obj, {
                headers: getMultiPartHeader(),
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const postMethodWithoutToken = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${BASE_URL}/${relativesUrl}`, obj)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const getMethodWithoutToken = (relativesUrl) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}/${relativesUrl}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const getMethodCustomHeader = (relativesUrl) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${BASE_URL}/${relativesUrl}`, { headers: getHeader() })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

const deleteMethodCustomHeader = (relativesUrl, obj) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${BASE_URL}/${relativesUrl}`, {
                headers: getHeader(),
                data: obj,
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    postMethodCustomHeader,
    postMethodWithoutToken,
    getMethodWithoutToken,
    getMethodCustomHeader,
    deleteMethodCustomHeader,
    putMethodCustomHeader,
    postMethodMultiPartHeader,
    putMethodMultiPartHeader,
}
