import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import styles from './DropDownCmp.module.css'
import { Controller } from 'react-hook-form'

const DropDownCmp = ({
    options,
    label,
    style,
    onChange,
    control,
    name,
    placeholder,
    multiple,
    ...props
}) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={multiple === true ? [] : ''}
                render={({
                    field: { onChange, value, onBlur, ref },
                    fieldState: { error },
                }) => (
                    <Autocomplete
                        freeSolo
                        sx={{ width: '100%', ...style }}
                        className={styles.selectBox}
                        options={options ? options : list}
                        isOptionEqualToValue={(option, value) => {
                            return (
                                value === undefined ||
                                value === '' ||
                                option.id === value.id ||
                                option.label === value ||
                                option.id === value
                            )
                        }}
                        value={value}
                        onChange={(event, newValue) => {
                            onChange(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                onBlur={onBlur}
                                inputRef={ref}
                                placeholder={placeholder && placeholder}
                            />
                        )}
                        multiple={multiple}
                        {...props}
                    />
                )}
            />
        </>
    )
}

const list = [
    'The Shawshank Redemption',
    'The Godfather',
    'The Godfather: Part II',
    'The Dark Knight',
    '12 Angry Men',
    "Schindler's List",
    'Pulp Fiction',
]

export default DropDownCmp
