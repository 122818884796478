import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function TableSelectOpt({ style, label, options, onChange }) {
    const [age, setAge] = React.useState('')

    const handleChange = (event) => {
        setAge(event.target.value)
        onChange(event)
    }

    return (
        <div>
            <FormControl sx={{ minWidth: 190 }}>
                <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                        width: '100%',
                        ...style,
                    }}
                >
                    <MenuItem value="">
                        <em style={{ color: 'grey' }}>{label}</em>
                    </MenuItem>
                    {options?.map((option, index) => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    )
}
