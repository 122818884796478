import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

import TextTruncate from 'react-text-truncate'
import { postedDuration } from '../../../Utils/utils'
import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from '../../index'
import styles from '../ProjectListView/ProjectListView.module.css'

import { useDispatch } from 'react-redux'
import useAlert from '../../../Hooks/useAlert/useAlert'
import {
    PermanentBSDeleteProject,
    RestoreBSDeleteProject,
} from '../../../Store/Actions/BuildStudio/BuildStudioAction'

const TrashBSProjectCard = ({ deleteBtn, color, deltetJob }) => {
    const { setAlert } = useAlert()
    const dispatch = useDispatch()

    const [paraLine, setParaLine] = useState(true)
    const [hover, setHover] = useState(false)

    const paraHandler = () => {
        setParaLine(!paraLine)
    }

    const hoverHandler = (event) => {
        if (event === 'enter') {
            setHover(true)
            return
        }
        setHover(false)
    }

    const {
        description,
        title,
        services_count,
        services,
        slug,
        created_at,
        address,
        state,
        zipcode,
    } = deltetJob || {}

    const restoreJob = (projectSlug) => {
        dispatch(RestoreBSDeleteProject(projectSlug, setAlert))
    }

    const permanentJobDelete = (projectSlug) => {
        dispatch(PermanentBSDeleteProject(projectSlug, setAlert))
    }
    return (
        <section
            className={styles.jobCardWrapper}
            onMouseEnter={() => hoverHandler('enter')}
            onMouseLeave={() => hoverHandler('leave')}
        >
            <Box
                className={styles.cardHeader}
                // onClick={() => navigate(`${path}/${slug}`)}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {postedDuration(created_at)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'var(--light-green)',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        color: 'var(--green)',
                    }}
                    alignSelf={'flex-end'}
                    className={styles.cardSeriveWrapper}
                >
                    <div>
                        <Typography fontSize={'12px'}>
                            {services_count} Services required
                        </Typography>
                    </div>
                </Box>
            </Box>
            <section className={styles.headerList}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                    }}
                    className={styles.jobDetails}
                >
                    <LocationOnOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--green)',
                            ml: '-5px',
                            fontSize: '18px',
                        }}
                    />
                    <small>
                        {address}, {state?.label}, {zipcode}
                    </small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <EventAvailableOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--primary-color)',
                            fontSize: '18px',
                        }}
                    />
                    <small>Create Date: {created_at}</small>
                </Box>
            </section>
            <section className={styles.cardBody}>
                <TextTruncate
                    line={paraLine && 3}
                    element="span"
                    truncateText="…"
                    text={description}
                    // textTruncateChild={}
                    style={{ color: 'var(--textColor)', fontSize: 'medium' }}
                />
            </section>
            <small className={styles.paraMoreBtn} onClick={paraHandler}>
                {paraLine ? 'More' : 'Less'}
            </small>
            <section className={styles.cardFooter}>
                <div>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Services:
                    </Typography>
                </div>
                <ScrollableTabs
                    bgColor={color}
                    hover={hover}
                    servicesTags={services}
                />
            </section>
            {deleteBtn && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        my: 2,
                        gap: 2,
                    }}
                >
                    <WhiteButtonCmp
                        title="Permanent Delete Job"
                        style={{ borderColor: ' red !important', color: 'red' }}
                        onClick={() => {
                            permanentJobDelete(slug)
                        }}
                    />
                    <ButtonCmp
                        style={{ width: 'max-content', padding: '10px 15px' }}
                        title="Restore Job"
                        onClick={() => {
                            restoreJob(slug)
                        }}
                    />
                </Box>
            )}
        </section>
    )
}

export default TrashBSProjectCard
