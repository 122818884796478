import { lazy } from 'react'
// import loadable from "@loadable/component"

const Home = lazy(() => import('./Home/Home'))
const About = lazy(() => import('./About/About'))
const Jobs = lazy(() => import('./Jobs/Jobs'))
const ProjectDetail = lazy(() => import('./ProjectDetail/ProjectDetail'))
const CreateJob = lazy(() => import('./CreateJob/CreateJob'))
const TrashJob = lazy(() => import('./TrashJob/TrashJob'))
const CompletedJob = lazy(() => import('./CompletedJob/CompletedJob'))
const SignIn = lazy(() => import('./SignIn/SignIn'))
const JobRating = lazy(() => import('./JobRating/JobRating'))
const AwardedJob = lazy(() => import('./AwardedJob/AwardedJob'))
const AwardedJobDetail = lazy(
    () => import('./AwardedJobDetail/AwardedJobMilestone')
)
const MyBids = lazy(() => import('./MyBids/MyBids'))
const AwardedProject = lazy(() => import('./AwardedProject/AwardedProject'))
const AwardedBidDetail = lazy(
    () => import('./AwardedBidDetail/AwardedBidDetail')
)
const WishList = lazy(() => import('./WishList/WishList'))
const MyBidDetail = lazy(() => import('./MyBidDetail/MyBidDetail'))
const AppliedProjects = lazy(() => import('./AppliedProjects/AppliedProjects'))
const SubmitedBidDetails = lazy(
    () => import('./SubmitedBidDetails/SubmitedBidDetails')
)
const CompletedProject = lazy(
    () => import('./CompletedProject/CompletedProject')
)
const Profile = lazy(() => import('./Profile/Profile'))
const MyProjectGallery = lazy(
    () => import('./MyProjectGallery/MyProjectGallery')
)

const TrashGallery = lazy(() => import('./TrashGallery/TrashGallery'))
const ProConnection = lazy(() => import('./ProConnection/ProConnection'))
const PendingConnectionRequests = lazy(
    () => import('./PendingConnectionRequests/PendingConnectionRequests')
)
const ReceviedConnectionRequests = lazy(
    () => import('./ReceviedConnectionRequests/ReceviedConnectionRequests')
)
const SearchProConnection = lazy(
    () => import('./SearchProConnection/SearchProConnection')
)
const AddNewProject = lazy(() => import('./AddNewProject/AddNewProject'))
const Chat = lazy(() => import('./Chat/Chat'))
const SignUpCOnfirmation = lazy(
    () => import('./SignUpCOnfirmation/SignUpCOnfirmation')
)
const AccountActivation = lazy(
    () => import('./AccountActivation/AccountActivation')
)
const ManufacturerViewProduct = lazy(
    () => import('./ManufacturerViewProduct/ManufacturerViewProduct')
)
const ManufacturerTrashProduct = lazy(
    () => import('./ManufacturerTrashProduct/ManufacturerTrashProduct')
)
const AddManufacturerProduct = lazy(
    () => import('./AddManufacturerProduct/AddManufacturerProduct')
)

const SupplierViewProduct = lazy(
    () => import('./SupplierViewProduct/SupplierViewProduct')
)
const SupplierTrashProduct = lazy(
    () => import('./SupplierTrashProduct/SupplierTrashProduct')
)
const SupplierAddProduct = lazy(
    () => import('./SupplierAddProduct/SupplierAddProduct')
)

const ImportProduct = lazy(() => import('./ImportProduct/ImportProduct'))
const ViewProductDetail = lazy(
    () => import('./ViewProductDetail/ViewProductDetail')
)
const SingleBidDetails = lazy(
    () => import('./SingleBidDetails/SingleBidDetails')
)

const UpdateJob = lazy(() => import('./UpdateJob/UpdateJob'))
const UpdateAddNewProject = lazy(
    () => import('./UpdateAddNewProject/UpdateAddNewProject')
)

// const ProfileCompletionForm = lazy(() =>
//   import("./ProfileCompletionForm/ProfileCompletionForm")
// );
const Projects = lazy(() => import('./BuildStudio/Projects/Projects'))
const JobsDetail = lazy(() => import('./BuildStudio/JobsDetail/JobsDetail'))
const CreateNewProject = lazy(
    () => import('./BuildStudio/CreateNewProject/CreateNewProject')
)

const EditPDF = lazy(() => import('./BuildStudio/EditPDF/EditPDF'))
const BSProjectTrash = lazy(
    () => import('./BuildStudio/BSProjectTrash/BSProjectTrash')
)
const EditBSProject = lazy(
    () => import('./BuildStudio/EditBSProject/EditBSProject')
)

const NotificationPage = lazy(() => import('./Notification/Notification'))
const Website2 = lazy(() => import('./Website2/Website2'))
const ForgetPassword = lazy(() => import('./ForgetPassword/ForgetPassword'))
const BidderRating = lazy(() => import('./BidderRating/BidderRating'))
const OTPVerification = lazy(() => import('./OTPVerifiation/OTPVerification'))
const UserProfile = lazy(() => import('./UserProfile/UserProfile'))
const InsuranceDetail = lazy(() => import('./Insurance/Detail'))
const InsuranceList = lazy(() => import('./Insurance/InsuranceList'))

export {
    Home,
    About,
    Jobs,
    ProjectDetail,
    CreateJob,
    TrashJob,
    CompletedJob,
    SignIn,
    WishList,
    JobRating,
    MyBids,
    AwardedProject,
    AwardedBidDetail,
    MyBidDetail,
    AppliedProjects,
    SubmitedBidDetails,
    CompletedProject,
    Profile,
    Chat,
    AwardedJobDetail,
    AwardedJob,
    // ProfileCompletionForm,
    MyProjectGallery,
    TrashGallery,
    ProConnection,
    PendingConnectionRequests,
    SearchProConnection,
    AddNewProject,
    AccountActivation,
    SignUpCOnfirmation,
    ManufacturerViewProduct,
    ManufacturerTrashProduct,
    AddManufacturerProduct,
    ImportProduct,
    ViewProductDetail,
    SingleBidDetails,
    UpdateJob,
    UpdateAddNewProject,
    ReceviedConnectionRequests,
    Projects,
    JobsDetail,
    CreateNewProject,
    EditPDF,
    BSProjectTrash,
    EditBSProject,
    NotificationPage,
    Website2,
    ForgetPassword,
    BidderRating,
    OTPVerification,
    UserProfile,
    SupplierViewProduct,
    SupplierTrashProduct,
    SupplierAddProduct,
    InsuranceDetail,
    InsuranceList,
}

// export { default as Home } from "./Home/Home";
export { default as ProfileCompletionForm } from './ProfileCompletionForm/ProfileCompletionForm'
