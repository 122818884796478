import { Box, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    BodyLayout,
    ButtonCmp,
    LoaderCmp,
    WhiteButtonCmp,
} from '../../../Components'
import useAlert from '../../../Hooks/useAlert/useAlert'
import { getUserToken } from '../../../Utils/LoginAuth'
import styles from './AddProduct.module.css'
import ProductTabs from './ProductTabs'

import { yupResolver } from '@hookform/resolvers/yup'
import { validation } from '../../../Config'
import useGetUserRole from '../../../Hooks/getUserRole'
import { BASE_URL } from '../../../Utils/response'

const ProductMain = () => {
    const { productSlug } = useParams()
    const { setAlert } = useAlert()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const location = useLocation()

    const colorsdata = location.state.colorsdata
    const materialsdata = location.state.materialsdata
    const coatsdata = location.state.coatsdata
    const primersdata = location.state.primersdata
    const finishingsdata = location.state.finishingsdata
    const patternsdata = location.state.patternsdata
    const glassTypesdata = location.state.glassTypesdata

    // useEffect(() => {
    // 	dispatch(GetColorsAction());
    // 	dispatch(GetMaterialsAction());
    // 	dispatch(GetCoatsAction());
    // 	dispatch(GetPrimersAction());
    // 	dispatch(GetDistributorsAction());
    // 	dispatch(GetSuppliersAction());
    // 	dispatch(GetFinishingsAction());
    // 	dispatch(GetPatternsAction());
    // 	dispatch(GetGlassTypesAction());
    // }, []);

    const formValidation = validation.productValidation
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        handleSubmit,
        reset,
        watch,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formValidation),
    })

    const {
        fields: colorFields,
        append: colorAppend,
        remove: colorRemove,
        replace: colorReplace,
    } = useFieldArray({
        control,
        name: 'colors',
        defaultValue: [],
    })

    const {
        fields: imageFields,
        append: imageAppend,
        remove: imageRemove,
        replace: imageReplace,
    } = useFieldArray({
        control,
        name: 'images',
        defaultValue: [],
    })

    const {
        fields: measurementsFields,
        append: measurementsAppend,
        remove: measurementsRemove,
        replace: measurementsReplace,
    } = useFieldArray({
        control,
        name: 'measurements',
    })

    const {
        fields: priceFields,
        append: priceAppend,
        remove: priceRemove,
        replace: priceReplace,
    } = useFieldArray({
        control,
        name: 'prices',
        defaultValue: [],
    })

    const getHeader = () => {
        return {
            Authorization: 'Bearer' + ' ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }

    useEffect(() => {
        axios
            .get(`${BASE_URL}/products/${productSlug}`, {
                headers: getHeader(),
            })
            .then(async (response) => {
                setValue('title', response.data.data.product_detail.title)
                setValue(
                    'description',
                    response.data.data.product_detail.description
                )
                setValue('brandName', response.data.data.product_detail.brand)
                setValue('category', response.data.data.product_detail.category)
                setValue('type', response.data.data.product_detail.type)
                setValue(
                    'installer',
                    response.data.data.product_detail.installer
                )

                const convertToBlob = async () => {
                    try {
                        const promises =
                            response.data.data.product_detail.images.map(
                                async (image, index) => {
                                    const resp = await fetch(
                                        response.data.data.product_detail
                                            .ImgURL + image.image
                                    )
                                    console.log(resp)
                                    const blob = await resp.blob()
                                    const file = new File([blob], image.image, {
                                        type: blob.type,
                                    })
                                    return {
                                        image: file,
                                        saved: true,
                                        is_thumbnail: image.is_thumbnail === 1,
                                        imgSrc:
                                            response.data.data.product_detail
                                                .ImgURL + image.image,
                                    }
                                }
                            )

                        const blobs = await Promise.all(promises)
                        setValue(
                            'thumbnail',
                            blobs.find((x) => x.is_thumbnail)
                        )
                        imageReplace(blobs.filter((x) => !x.is_thumbnail))
                    } catch (error) {
                        console.error(
                            'Error converting images to blobs:',
                            error
                        )
                    }
                }

                convertToBlob()

                let colorList = await Promise.all(
                    response.data.data.product_detail.colors.map(
                        async (color, index) => {
                            const imageResult =
                                await convertImageToBlobAndReturn(
                                    response.data.data.product_detail.ImgURL +
                                        color.image,
                                    color.image
                                )

                            return {
                                color_title: color.color.title,
                                color_id: color.color_id,
                                color_code: color.color.code,
                                joint: color.joint,
                                imageSrc:
                                    response.data.data.product_detail.ImgURL +
                                    color.image,
                                material_id: materialsdata.find(
                                    (x) => x.id === color.material_id
                                ),
                                primer_id: primersdata.find(
                                    (x) => x.id === color.primer_id
                                ),
                                first_coat: coatsdata.find(
                                    (x) => x.id === color.coat_one_id
                                ),
                                glass_type: glassTypesdata.find(
                                    (x) => x.id === color.glass_type_id
                                ),
                                second_coat: coatsdata.find(
                                    (x) => x.id === color.coat_two_id
                                ),
                                pattern: patternsdata.find(
                                    (x) => x.id === color.pattern_id
                                ),
                                finish: color.finishings.map(
                                    (item) => item.finishing
                                ),
                                image: imageResult,
                            }
                        }
                    )
                )

                colorReplace(colorList)

                response.data.data.product_detail.colors.forEach((color) => {})

                let measurementsList =
                    response.data.data.product_detail.measurements.map(
                        (measurement) => {
                            return {
                                measurement_id: measurement.measurement_id,
                                slug: measurement.slug,
                                label: measurement.label,
                                title: measurement.title,
                                custom:
                                    [
                                        'Small',
                                        'Medium',
                                        'Large',
                                        'Extra Large',
                                    ].find((x) => x === measurement.title) ===
                                    null
                                        ? true
                                        : false,
                                width: measurement.width,
                                height: measurement.height,
                                weight: measurement.weight,
                                length: measurement.length,
                                depth: measurement.depth,
                                package_width: measurement.package_width,
                                package_height: measurement.package_height,
                                package_weight: measurement.package_weight,
                                package_length: measurement.package_length,
                                package_depth: measurement.package_depth,
                                delivery_time: measurement.delivery_time,
                            }
                        }
                    )
                measurementsReplace(measurementsList)

                let priceList = response.data.data.product_detail.variants.map(
                    (variant) => {
                        return {
                            size: variant.measurement_size,
                            color: variant.color_title,
                            color_hex: variant.color_code,
                            price: variant.price,
                            discount_price: variant.discounted_price,
                            quantity: variant.quantity,
                            model_no: variant.model_no,
                            sku: variant.sku,
                            available: !!variant.is_available,
                            is_default: !!variant.is_default,
                        }
                    }
                )

                priceReplace(priceList)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const colorImageSetter = (value) => {
        setValue(`prices[${value[0]}][color_image]`, value[1])
    }

    const imageSetter = (value) => {
        setValue(`images[${value[0]}][image]`, value[1])
    }

    let [loading, setLoading] = useState(false)

    const convertImageToBlob = async (index, imageUrl, fileName, setter) => {
        try {
            const response = await fetch(imageUrl)
            const blob = await response.blob()
            // Create a File object from the Blob
            const convertedFile = new File([blob], fileName, {
                type: blob.type,
            })
            console.log(convertedFile)
            setter([index, convertedFile])
        } catch (error) {
            console.error('Error converting image to blob:', error)
        }
    }

    const convertImageToBlobAndReturn = async (imageUrl, fileName) => {
        try {
            const response = await fetch(imageUrl)
            const blob = await response.blob()
            // Create a File object from the Blob
            const convertedFile = new File([blob], fileName, {
                type: blob.type,
            })

            return convertedFile
        } catch (error) {
            console.error('Error converting image to blob:', error)
        }
    }

    const userRole = useGetUserRole()

    const onError = (errors, e) => {
        console.log(errors)
        let errorString = []
        for (const [key, value] of Object.entries(errors)) {
            errorString.push(value.message)
            console.log(`${value.message}`)
        }
        setAlert(errorString.join('.'), 'error')
    }

    const onSubmit = (data) => {
        setLoading(true)
        const formData = new FormData()
        console.log(data)
        // Append object properties to FormData

        formData.append('title', data.title)
        formData.append('brand_id', data.brandName?.id)
        formData.append('category_id', data.category?.id)
        formData.append('description', data.description)
        formData.append('type', data.type)
        formData.append('installer', data.installer)
        formData.append('sku', data.sku)
        formData.append('have_colors', data.colors.length > 0)
        formData.append('weight_unit', 'LBS')
        formData.append('dimension_unit', 'IN')

        formData.append('have_colors', data.colors.length > 0)
        data.images
            .filter((x) => x.saved === false)
            .forEach((x, i) => {
                console.log(x)
                formData.append(`images[${i}][image]`, x.image)
                formData.append(
                    `images[${i}][is_thumbnail]`,
                    x.is_thumbnail === 1 ? true : false
                )
            })

        if (!data.thumbnail.saved) {
            formData.append(
                `images[${data.images.length - 1}][image]`,
                data.thumbnail.image
            )
            formData.append(
                `images[${data.images.length - 1}][is_thumbnail]`,
                true
            )
        }

        data.colors.forEach((x, i) => {
            formData.append(`colors[${i}][color_id]`, x.color_id)

            formData.append(`colors[${i}][material_id]`, x.material_id?.id)
            formData.append(`colors[${i}][primer_id]`, x.primer_id?.id)
            formData.append(`colors[${i}][coat_one_id]`, x.first_coat?.id)
            formData.append(`colors[${i}][coat_two_id]`, x.second_coat?.id)
            formData.append(`colors[${i}][pattern_id]`, x.pattern?.id)
            formData.append(`colors[${i}][glass_type_id]`, x.glass_type?.id)
            formData.append(`colors[${i}][joint]`, x.joint)
            formData.append(`colors[${i}][image]`, x.image)
            // x.finish.forEach((item, j) => {
            // 	formData.append(`colors[${i}][finishings][${j}]`, item?.id);
            // });
        })

        formData.append(`have_measurements`, data.measurements.length > 0)
        data.measurements.forEach((x, i) => {
            formData.append(`measurements[${i}][height]`, x.height)
            formData.append(`measurements[${i}][length]`, x.length)
            formData.append(`measurements[${i}][width]`, x.width)
            formData.append(`measurements[${i}][weight]`, x.weight)
            formData.append(`measurements[${i}][depth]`, x.depth)
            formData.append(
                `measurements[${i}][package_height]`,
                x.package_height
            )
            formData.append(
                `measurements[${i}][package_length]`,
                x.package_length
            )
            formData.append(
                `measurements[${i}][package_width]`,
                x.package_width
            )
            formData.append(
                `measurements[${i}][package_weight]`,
                x.package_weight
            )
            formData.append(
                `measurements[${i}][package_depth]`,
                x.package_depth
            )
            formData.append(`measurements[${i}][title]`, x.title)
            formData.append(
                `measurements[${i}][delivery_time]`,
                x.delivery_time
            )
        })

        data.prices.forEach((x, i) => {
            formData.append(`variants[${i}][size]`, x.size)
            formData.append(`variants[${i}][color]`, x.color)
            formData.append(`variants[${i}][color_code]`, x.color_hex)
            formData.append(`variants[${i}][quantity]`, x.quantity)
            formData.append(`variants[${i}][price]`, x.price)
            formData.append(
                `variants[${i}][discounted_price]`,
                x.discount_price
            )
            formData.append(`variants[${i}][model_no]`, x.model_no)
            formData.append(`variants[${i}][is_available]`, x.available)
            formData.append(`variants[${i}][is_default]`, x.is_default)
            formData.append(`variants[${i}][sku]`, x.sku)
            formData.append(
                `variants[${i}][variant]`,
                x.size.toLowerCase() + '-' + x.color.toLowerCase()
            )
        })

        axios
            .post(`${BASE_URL}/user/products/${productSlug}`, formData, {
                headers: getHeader(),
            })
            .then((response) => {
                setAlert(response.data.message || 'Product Updated', 'success')
                navigate(`/${userRole}/viewproduct`)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setAlert(
                    error.response.data.message || 'An error occurred',
                    'error'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return loading ? (
        <LoaderCmp />
    ) : (
        <BodyLayout>
            <div className={styles.myJobHeader}>
                <div>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Edit Product
                    </Typography>
                </div>
            </div>
            {}
            <div>
                <ProductTabs
                    {...{
                        control,
                        watch,
                        getValues,
                        setValue,
                        colorFields,
                        colorAppend,
                        colorRemove,
                        measurementsFields,
                        measurementsAppend,
                        measurementsRemove,
                        imageAppend,
                        imageRemove,
                        imageReplace,
                        priceFields,
                        priceAppend,
                        priceRemove,
                        priceReplace,
                    }}
                ></ProductTabs>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'flex-end',
                        mb: '1rem',
                        mr: '2rem',
                    }}
                >
                    {' '}
                    <WhiteButtonCmp title="Cancel" />{' '}
                    <ButtonCmp
                        title="Save"
                        onClick={() => {
                            handleSubmit(onSubmit, onError)()
                        }}
                    />
                </Box>
            </div>
        </BodyLayout>
    )
}

export default ProductMain
