import { TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

import styles from './TextArea.module.css'

const TextArea = ({ label, rows, style, name, control, ...props }) => {
    return (
        <>
            <Controller
                name={name}
                control={control ? control : 'test'}
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <TextField
                        id="outlined-multiline-static"
                        label={label}
                        multiline
                        rows={rows}
                        onChange={onChange}
                        sx={{
                            width: '100%',
                            ...style,
                        }}
                        // placeholder="e.g Full Kitchen Remodel"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={value}
                        error={!!error}
                        helperText={error ? error.message : null}
                        className={styles.textAreaField}
                        {...props}
                    />
                )}
            />
        </>
    )
}

export default TextArea
