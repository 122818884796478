import { createTheme, ThemeProvider } from '@mui/material'
import React, { Suspense } from 'react'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoaderCmp } from './Components'
import AlertPopup from './Components/AlertPopup/AlertPopup'
import ProtectedRoute from './Config/Routes/ProtectedRoute'
import AppRoutes from './Config/Routes/routes'
import {
    ProfileCompletionForm,
    SignIn,
    SignUpCOnfirmation,
    AccountActivation,
    NotificationPage,
    Website2,
    ForgetPassword,
    OTPVerification,
} from './Pages'
import SignUp from './Pages/SignUp/SignUp'
import { useDispatch, useSelector } from 'react-redux'
import AuthRoute from './Config/Routes/AuthRoute'
import Example from './Pages/Example/Example'
import { GetUserAction, UpdateDeviceID } from './Store/Actions/AuthActions'
import { GetUserProfileAction } from './Store/Actions/ProfileAction'
import useAlert from './Hooks/useAlert/useAlert'
import { messaging, onMessageListener } from './Config/firebase'
import { getToken, onMessage } from '@firebase/messaging'
import useNotificationPermission from './Hooks/notificationPermission/NotificationPermission'
import notificationRequestToken from './Utils/notificationRequestToken'
import { GetNotificationCount } from './Store/Actions/NotificationAction'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AboutPage from './Pages/Website2/AboutPage'
import ConfirmPassword from './Pages/ConfirmPassword/ConfirmPassword'
import ProfileSelection from './Pages/ProfileSelection/ProfileSelection'

const theme = createTheme({
    palette: {
        buttonColor: {
            // Purple and green play nicely together.
            main: '#005699',
            contrastText: '#fff',
        },
        successBtn: {
            // Purple and green play nicely together.
            main: 'var(--green)',
            contrastText: '#fff',
        },
        buttonWhite: {
            // Purple and green play nicely together.
            main: '#fff',
            contrastText: '#111827',
            // color: "black !important",
            border: '1px solid black !important',
        },
    },
})

function App() {
    const dispatch = useDispatch()
    const { profileDetails, profileDetailsLoading } = useSelector(
        (state) => state.UserProfileReducer
    )

    const token = localStorage.getItem('token')

    const { setAlert } = useAlert()
    useEffect(() => {
        if (token) {
            dispatch(GetUserProfileAction(setAlert))
        }
    }, [token])

    useEffect(() => {
        if (profileDetails?.id) {
            const getNotiToken = async () => {
                try {
                    const token = await notificationRequestToken()
                    if (profileDetails.device_token !== token) {
                        dispatch(UpdateDeviceID({ device_token: token }))
                    }
                } catch (error) {
                    console.log('error noti', error)
                }
            }
            getNotiToken()
            onMessage(messaging, (payload) => {
                if (payload) {
                    setAlert(payload.notification.body, 'success')
                    dispatch(GetNotificationCount())
                } else {
                    setAlert(payload.notification.body, 'error')
                }
            })
        }
    }, [profileDetails])

    useEffect(() => {
        AOS.init({
            duration: 1200,
        })
    }, [])
    window.addEventListener('load', AOS.refresh)

    return profileDetailsLoading ? (
        <LoaderCmp />
    ) : (
        <>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<div>LOADING....</div>}>
                    <Routes>
                        <Route path="/" element={<Website2 />} />
                        <Route path="/aboutpage" element={<AboutPage />} />
                        <Route element={<AuthRoute />}>
                            <Route path="/login" element={<SignIn />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route
                                path="/forgetpassword"
                                element={<ForgetPassword />}
                            />
                            <Route
                                path="/otp-verification"
                                element={<OTPVerification />}
                            />
                            <Route
                                path="/confirmpassword"
                                element={<ConfirmPassword />}
                            />

                            <Route
                                path="/signupconfirmation"
                                element={<SignUpCOnfirmation />}
                            />
                            {/* <Route path="*" element={<h1>404 page not found!</h1>} /> */}
                        </Route>
                        <Route
                            path="/accountactivation"
                            element={<AccountActivation />}
                        />
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="/notification"
                                element={<NotificationPage />}
                            />
                            {profileDetails && (
                                <Route
                                    path="/profilecompletion"
                                    element={<ProfileCompletionForm />}
                                />
                            )}
                            {profileDetails && (
                                <Route
                                    path="/profileselection"
                                    element={<ProfileSelection />}
                                />
                            )}

                            <Route path="/example" element={<Example />} />
                            <Route
                                path="*"
                                element={
                                    <AppRoutes
                                        profileDetails={profileDetails}
                                    />
                                }
                            ></Route>
                        </Route>
                    </Routes>
                </Suspense>
            </ThemeProvider>
            <AlertPopup />
            {/* <BackDropCmp setOpen={true} /> */}
        </>
    )
}

export default App
