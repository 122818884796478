import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import styles from './GridViewCard.module.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BiEdit, LocationOnOutlinedIcon, RiDeleteBin6Line } from '../../Assets'
import { postedDuration } from '../../Utils/utils'
import { DeleteJobAction } from '../../Store/Actions/BuildBidsJobAction'
import useAlert from '../../Hooks/useAlert/useAlert'
import IconCmp from '../IconCmp/IconCmp'

const GridViewCard = ({ path, getAllUSerProjectdata }) => {
    return (
        <Grid container className={styles.jobGridCardWrapper}>
            {getAllUSerProjectdata &&
                getAllUSerProjectdata.map((jobs, index) => {
                    return (
                        <Grid item xs={12} sm={6} key={jobs.id}>
                            <Card path={path} getAllUSerProjectdata={jobs} />
                        </Grid>
                    )
                })}
        </Grid>
    )
}

const Card = ({ path, getAllUSerProjectdata }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    const {
        biding_end_date,
        biding_start_date,
        description,
        title,
        project_start_date,
        proposals_count,
        services_count,
        services,
        slug,
        created_at,
        address,
        state,
        zipcode,
    } = getAllUSerProjectdata

    const handleDeleteJob = (e) => {
        e.stopPropagation()
        dispatch(DeleteJobAction(slug, setAlert))
    }

    const editJob = (e) => {
        e.stopPropagation()
        console.log('edit')
        navigate(`/buildbid/updatejob/${slug}`)
    }
    return (
        <div
            className={styles.gridviewCardWrapper}
            onClick={() => navigate(`${path}/${slug}`)}
        >
            <div className={styles.cardInfo}>
                <section className={styles.cardInfoDetails}>
                    <Typography variant="h5" className={styles.jobName}>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {postedDuration(created_at)}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                        }}
                    >
                        <LocationOnOutlinedIcon
                            fontSize="10px"
                            sx={{
                                marginRight: '5px',
                                ml: '-5px',
                                color: 'var(--primary-color)',
                            }}
                        />
                        <small>
                            {address}, {state?.label}, {zipcode}
                        </small>
                    </Box>
                </section>
                <section className={styles.cardInfoService}>
                    <section className={styles.actionButtonsContainer}>
                        <Box onClick={editJob}>
                            <IconCmp>
                                <BiEdit
                                    style={{ color: 'var(--milestoneText)' }}
                                />
                            </IconCmp>
                        </Box>

                        <Box onClick={handleDeleteJob}>
                            <IconCmp>
                                <RiDeleteBin6Line
                                    style={{ color: 'var(--redColor)' }}
                                />
                            </IconCmp>
                        </Box>
                    </section>
                    <Box
                        sx={{
                            backgroundColor: 'var(--light-green)',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            marginTop: '10px',
                            color: 'var(--green)',
                        }}
                        alignSelf={'flex-end'}
                        className={styles.cardSeriveWrapper}
                    >
                        <div>
                            <Typography fontSize={'12px'}>
                                {services_count} Services required
                            </Typography>
                        </div>
                    </Box>
                </section>
            </div>
            {/* <Box className={styles.cardInfoDate}>
        <Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography
              sx={{
                fontSize: "small",
                color: "var(--textColor)",
              }}
            >
              Create Date:
            </Typography>
            <Typography sx={{ fontSize: "small", color: "black" }}>
              6/3/2021
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
              # of bids Received:
            </Typography>
            <Typography sx={{ fontSize: "small", color: "black" }}>
              25
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
              Job Start Date:
            </Typography>
            <Typography sx={{ fontSize: "small", color: "black" }}>
              6/3/2021
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ fontSize: "small", color: "var(--textColor)" }}>
              Bid Closing Date:
            </Typography>
            <Typography sx={{ fontSize: "small", color: "black" }}>
              6/3/2021
            </Typography>
          </Box>
        </Box>
      </Box> */}
            <Box className={styles.cardInfoDate2}>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Create Date</td>
                                <td>:</td>
                                <td>{created_at.split('T')[0]}</td>
                            </tr>
                            <tr>
                                <td># of Bids Received</td>
                                <td>:</td>
                                <td>{proposals_count}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Job Start Date</td>
                                <td>:</td>
                                <td>6/3/2021</td>
                            </tr>
                            <tr>
                                <td>Bids Closing Date</td>
                                <td>:</td>
                                <td> {biding_end_date.split(' ')[0]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </div>
    )
}

export default GridViewCard
