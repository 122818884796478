import React from 'react'
import { Box, Typography } from '@mui/material'
import { CheckCircleOutlineIcon, TaskAltIcon } from '../../Assets'
import styles from './ProfileCompletionForm.module.css'

const FormProgressBar = ({
    profileComplete,
    docsAndRegComplete,
    servicesComplete,
    socialMediaComplete,
    connectionsComplete,
    barPercent,
    whichForm,
}) => {
    return (
        <>
            <section>
                <div className={styles.form_completion_status}>
                    <Typography>Profile Completion</Typography>
                    <Typography
                        sx={{ color: 'var(--primary-color)' }}
                        fontWeight="bold"
                    >
                        {barPercent === '2%' ? '0%' : barPercent}
                    </Typography>
                </div>
                <div className={styles.form_progressBar}>
                    <div style={{ width: barPercent }}></div>
                </div>
            </section>
            <section className={styles.formTick}>
                <div className={styles.completionTick}>
                    {!profileComplete ? (
                        <CheckCircleOutlineIcon
                            sx={{
                                color:
                                    whichForm === 'profileInfo'
                                        ? 'var(--primary-color)'
                                        : 'var(--disableIcon)',
                                fontSize: '40px',
                            }}
                        />
                    ) : (
                        <Box className={styles.successTick}>
                            <TaskAltIcon
                                sx={{
                                    color: 'white',
                                    fontSize: '28px',
                                }}
                            />
                        </Box>
                    )}

                    <Typography>Profile information</Typography>
                </div>
                <div className={styles.completionTick}>
                    {!docsAndRegComplete ? (
                        <CheckCircleOutlineIcon
                            sx={{
                                color:
                                    whichForm === 'docs&regs'
                                        ? 'var(--primary-color)'
                                        : 'var(--disableIcon)',
                                fontSize: '40px',
                            }}
                        />
                    ) : (
                        <Box className={styles.successTick}>
                            <TaskAltIcon
                                sx={{
                                    color: 'white',
                                    fontSize: '28px',
                                }}
                            />
                        </Box>
                    )}
                    <Typography>Document and registration</Typography>
                </div>
                <div className={styles.completionTick}>
                    {!servicesComplete ? (
                        <CheckCircleOutlineIcon
                            sx={{
                                color:
                                    whichForm === 'services'
                                        ? 'var(--primary-color)'
                                        : 'var(--disableIcon)',
                                fontSize: '40px',
                            }}
                        />
                    ) : (
                        <Box className={styles.successTick}>
                            <TaskAltIcon
                                sx={{
                                    color: 'white',
                                    fontSize: '28px',
                                }}
                            />
                        </Box>
                    )}
                    <Typography>Services</Typography>
                </div>
                <div className={styles.completionTick}>
                    {!socialMediaComplete ? (
                        <CheckCircleOutlineIcon
                            sx={{
                                color:
                                    whichForm === 'socialMedia'
                                        ? 'var(--primary-color)'
                                        : 'var(--disableIcon)',
                                fontSize: '40px',
                            }}
                        />
                    ) : (
                        <Box className={styles.successTick}>
                            <TaskAltIcon
                                sx={{
                                    color: 'white',
                                    fontSize: '28px',
                                }}
                            />
                        </Box>
                    )}
                    <Typography>Social Media</Typography>
                </div>
                {/* <div className={styles.completionTick}>
          {!connectionsComplete ? (
            <CheckCircleOutlineIcon
              sx={{
                color:
                  whichForm ===  "yourConnection"
                    ? "var(--primary-color)"
                    : "var(--disableIcon)",
                fontSize: "40px",
              }}
            />
          ) : (
            <Box className={styles.successTick}>
              <TaskAltIcon
                sx={{
                  color: "white",
                  fontSize: "28px",
                }}
              />
            </Box>
          )}
          <Typography>Your Connections </Typography>
        </div> */}
            </section>
        </>
    )
}

export default FormProgressBar
