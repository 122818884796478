import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import ProductTabPanel from './ProductTabPanel'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AddProduct.module.css'
import {
    InputCmp,
    TextAreaCmp,
    DropDownCmp,
    VIewImagesModal,
} from '../../../Components'

import { RiDeleteBin6Line } from '../../../Assets'
import ProductFileUploader from '../../../Components/ProductFileUploader/ProductFileUploader'

const GeneralTab = (props) => {
    const {
        tabValue,
        control,
        watch,
        imageFields,
        setValue,
        imageRemove,
        imageAppend,
    } = props

    const { brandsdata } = useSelector((state) => state.BrandsReducers)
    const { categoriesdata } = useSelector((state) => state.CategoriesReducers)

    const [thumbnailSrc, setThumbnailSrc] = useState('')
    const [thumbnailPicture, setThumbnailPicture] = useState({})

    const [gallerySrc, setGallerySrc] = useState('')
    const [galleryPictures, setGalleryPictures] = useState([])

    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        setThumbnailSrc(
            imageFields?.find((x) => x.is_thumbnail === true)?.imgSrc
        )
        setGalleryPictures(imageFields?.filter((x) => x.is_thumbnail === false))
    }, [imageFields])

    const [open, setOpen] = useState(false)

    const images = watch('images')
    const thumbnail = watch('thumbnail')

    const deleteGalleryPic = (ind) => {
        console.log(ind)
        imageRemove(ind)
    }

    const deleteThumbnailPic = () => {
        setThumbnailPicture({})
    }

    const thumbnailHandler = (file) => {
        setValue('thumbnail', {
            is_thumbnail: true,
            imgSrc: URL.createObjectURL(file),
            image: file,
            saved: false,
        })
    }

    const galleryHandler = (file) => {
        imageAppend({
            is_thumbnail: false,
            imgSrc: URL.createObjectURL(file),
            image: file,
            saved: false,
        })
    }

    return (
        <ProductTabPanel value={tabValue} index={0}>
            <section className={styles.productIntro_wrapper}>
                <Grid container columnSpacing={2} rowSpacing={5}>
                    <Grid item lg={12}>
                        <InputCmp
                            name="title"
                            control={control}
                            label="Product Name"
                        />
                    </Grid>
                    <Grid item lg={12}>
                        <TextAreaCmp
                            rows={3}
                            label="Description"
                            name="description"
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <DropDownCmp
                            name="category"
                            label="Category"
                            control={control}
                            options={categoriesdata && categoriesdata}
                            disableClearable
                            freeSolo={false}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <DropDownCmp
                            name="brandName"
                            label="Brand Name"
                            control={control}
                            options={brandsdata && brandsdata}
                            disableClearable
                            freeSolo={false}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <section>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Product Thumbnail
                            </Typography>
                        </section>
                        <Box sx={{ my: '40px' }}>
                            <ProductFileUploader handler={thumbnailHandler} />
                        </Box>
                        {thumbnail && (
                            <Box className={styles.uploadImgShow}>
                                <img src={thumbnail.imgSrc} alt="" />
                            </Box>
                        )}
                    </Grid>
                    <Grid item lg={8}>
                        <section>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Product Gallery
                            </Typography>
                        </section>

                        <Box sx={{ my: '40px' }}>
                            <ProductFileUploader handler={galleryHandler} />
                        </Box>
                        {images && (
                            <Box className={styles.listingGallerypic}>
                                {images
                                    ?.filter((x) => x.is_thumbnail === false)
                                    .map((img, index) => {
                                        return index === 0 ? (
                                            <Box
                                                key={index}
                                                className={styles.uploadImgShow}
                                            >
                                                <img src={img.imgSrc} alt="" />
                                                <section
                                                    className={
                                                        styles.editImgBox
                                                    }
                                                >
                                                    {/* <BiEdit color="white" fontSize="50px" /> */}
                                                    <RiDeleteBin6Line
                                                        color="white"
                                                        fontSize="50px"
                                                        onClick={() =>
                                                            deleteGalleryPic(
                                                                index
                                                            )
                                                        }
                                                    />
                                                </section>
                                            </Box>
                                        ) : index === 1 ? (
                                            <Box
                                                key={index}
                                                className={
                                                    styles.uploadImgShow2
                                                }
                                                onClick={() => {
                                                    setOpen(true)
                                                }}
                                            >
                                                <h1>
                                                    {images?.filter(
                                                        (x) =>
                                                            x.is_thumbnail ===
                                                            false
                                                    ).length - 1}
                                                    +
                                                </h1>
                                            </Box>
                                        ) : null
                                    })}
                                <VIewImagesModal
                                    open={open}
                                    setOpen={setOpen}
                                    projectImages={images?.filter(
                                        (x) => x.is_thumbnail === false
                                    )}
                                    deleteGalleryPic={deleteGalleryPic}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </section>
        </ProductTabPanel>
    )
}

export default GeneralTab
