import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styles from './productModals.module.css'
import { Grid } from '@mui/material'
import DragNDrop from '../../DragNDrop/DragNDrop'
import { useForm } from 'react-hook-form'
import InputCmp from '../../InputCmp/InputCmp'
import AutoCompleteWithIcon from '../../AutoCompleteWithIcon/AutoCompleteWithIcon'
import DropDownCmp from '../../DropDownCmp/DropDownCmp'
import TextAreaCmp from '../../TextAreaCmp/TextAreaCmp'
import BackDropCmp from '../../BackDrop/BackDrop'
import ButtonCmp from '../../ButtonCmp/ButtonCmp'
import WhiteButtonCmp from '../../ButtonCmp/WhiteButtonCmp'
import { useDispatch, useSelector } from 'react-redux'
import {
    GetBrandsAction,
    GetCategory,
    GetCoatsAction,
    GetColorsAction,
    GetDistributorsAction,
    GetGlassTypesAction,
    GetMaterialsAction,
    GetPatternsAction,
    GetPrimersAction,
    GetSuppliersAction,
    GetSymbolAction,
} from '../../../Store/Actions/GetActions'
import { useEffect } from 'react'
import { useState } from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    outline: 'none',
}

export default function AddNewProductModal({ open, setOpen }) {
    //   const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            // firstForm
            productName: '',
            brandName: '',
            modelName: '',
            modelNo: '',
            sku: '',
            description: '',
            category: '',

            // secondform
            // productCategory: "",
            colors: [],
            material: '',
            finish: '',
            width: '',
            height: '',
            length: '',
            distributor: '',
            supplier: '',
            room: '',
            quantity: '',
            style: '',
            SDL: '',
            firstCoat: '',
            secondCoat: '',
            primer: '',

            // thirdform
            pattern: '',
            glassType: '',
            installer: '',
            type: '',
            joint: '',
            depth: '',
            item: '',
            itemNo: '',
            doNotInclude: '',
            custom: '',
            other: '',

            ///gallery form
            symbol: [],
        },
    })

    const onSubmit = () => {
        console.log('HELLO ADD PRODUCT FORM......!!')
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(GetBrandsAction())
        dispatch(GetCategory())
        dispatch(GetColorsAction())
        dispatch(GetMaterialsAction())
        dispatch(GetCoatsAction())
        dispatch(GetPrimersAction())
        dispatch(GetDistributorsAction())
        dispatch(GetSuppliersAction())
        dispatch(GetSymbolAction())
        dispatch(GetPatternsAction())
        dispatch(GetGlassTypesAction())
    }, [])

    const [productThumbnailSrc, setProductThumbnailSrc] = useState('')
    const [productGallerySrc, setProductGallerySrc] = useState('')
    const [thumbnailPicture, setThumbnailPicture] = useState('')

    const getThumbnailPic = (img) => {
        console.log('coverPicture igm', img)
        setThumbnailPicture({
            is_thumbnail: true,
            image: img,
        })
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={styles.modalMainBox}>
                        {/* ///header/// */}
                        <Box
                            sx={{
                                borderBottom: '1px solid var(--borderColor)',
                                px: 4,
                                py: 2,
                            }}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Product Customization
                            </Typography>
                        </Box>

                        {/* ////PRODUCT FORM/// */}

                        <Box
                            sx={{
                                px: 4,
                                py: 2,
                                // background: "green",
                                // maxHeight: "100%",
                                // display: "flex",
                            }}
                            //   className={styles.modalMainBox}
                        >
                            <Grid container columnSpacing={2}>
                                <Grid item lg={4}>
                                    <Box>
                                        <DragNDrop
                                            images={true}
                                            setImageSrc={setProductThumbnailSrc}
                                            getFile={getThumbnailPic}
                                        />
                                        {productThumbnailSrc && (
                                            <Box
                                                className={styles.uploadImgShow}
                                            >
                                                <img
                                                    src={productThumbnailSrc}
                                                    alt=""
                                                />
                                                <section
                                                    className={
                                                        styles.editImgBox
                                                    }
                                                >
                                                    {/* <BiEdit color="white" fontSize="50px" />
                          <RiDeleteBin6Line color="white" fontSize="50px" /> */}
                                                </section>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    lg={8}
                                    //   sx={{
                                    //     maxHeight: "100%",
                                    //     overflowY: "auto",
                                    //     background: "red",
                                    //   }}
                                >
                                    <ProductForm
                                        control={control}
                                        handleSubmit={handleSubmit}
                                        onSubmit={onSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

const ProductForm = ({ control, handleSubmit, onSubmit }) => {
    const { brandsLoading } = useSelector((state) => state.BrandsReducers)
    const { colorsLoading } = useSelector((state) => state.ColorsReducers)
    const { materialsLoading } = useSelector((state) => state.MaterialsReducers)
    const { coatsLoading } = useSelector((state) => state.CoatsReducers)
    const { primersLoading } = useSelector((state) => state.PrimersReducers)
    const { symbolsLoading } = useSelector((state) => state.SymbolReducers)
    const { categoriesLoading } = useSelector(
        (state) => state.CategoriesReducers
    )
    const { distributorsLoading } = useSelector(
        (state) => state.DistributorsReducers
    )
    const { suppliersLoading } = useSelector((state) => state.SuppliersReducers)
    const { addProductLoading } = useSelector(
        (state) => state.AddProductReducer
    )
    const { brandsdata } = useSelector((state) => state.BrandsReducers)
    const { categoriesdata } = useSelector((state) => state.CategoriesReducers)
    const { colorsdata } = useSelector((state) => state.ColorsReducers)
    const { materialsdata } = useSelector((state) => state.MaterialsReducers)
    const { coatsdata } = useSelector((state) => state.CoatsReducers)
    const { primersdata } = useSelector((state) => state.PrimersReducers)
    const { suppliersdata } = useSelector((state) => state.SuppliersReducers)
    const { distributorsdata } = useSelector(
        (state) => state.DistributorsReducers
    )
    const { symbolsdata } = useSelector((state) => state.SymbolReducers)
    const { patternsLoading, patternsdata } = useSelector(
        (state) => state.PatternsReducers
    )
    const { glassTypesLoading, glassTypesdata } = useSelector(
        (state) => state.GlassTypesReducers
    )
    return (
        <section>
            <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item lg={6}>
                    <InputCmp
                        name="productName"
                        control={control}
                        label="Product Name"
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="brandName"
                        label="Brand Name"
                        control={control}
                        options={brandsdata && brandsdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="modelName"
                        control={control}
                        label="Model Name"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="modelNo"
                        control={control}
                        label="Model Number"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="sku" control={control} label="SKU" />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="category"
                        label="Category"
                        control={control}
                        options={categoriesdata && categoriesdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={12}>
                    <TextAreaCmp
                        rows={3}
                        label="Description"
                        name="description"
                        control={control}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        label="Color"
                        name="colors"
                        options={colorsdata && colorsdata}
                        multiple
                        control={control}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <AutoCompleteWithIcon
                        control={control}
                        name="symbol"
                        multiple={true}
                        options={symbolsdata && symbolsdata}
                        label={'Symbols'}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        label="Material"
                        name="material"
                        options={materialsdata && materialsdata}
                        control={control}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="finish" control={control} label="Finish" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="width"
                        type="number"
                        control={control}
                        label="Width"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="height"
                        type="number"
                        control={control}
                        label="Height"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="length"
                        type="number"
                        control={control}
                        label="Length"
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        label="Distributor"
                        name="distributor"
                        options={distributorsdata && distributorsdata}
                        control={control}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        label="Supplier"
                        name="supplier"
                        options={suppliersdata && suppliersdata}
                        control={control}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="room"
                        type="number"
                        control={control}
                        label="Room"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="quantity"
                        type="number"
                        control={control}
                        label="Quantity"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="style" control={control} label="Style" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="SDL" control={control} label="SDL" />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="firstCoat"
                        control={control}
                        label="FirstCoat"
                        options={coatsdata && coatsdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="secondCoat"
                        control={control}
                        label="Second Coat"
                        options={coatsdata && coatsdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="primer"
                        control={control}
                        label="Primer"
                        options={primersdata && primersdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="pattern"
                        control={control}
                        label="Pattern"
                        options={patternsdata && patternsdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="joint" control={control} label="Joint" />
                </Grid>
                <Grid item lg={6}>
                    <DropDownCmp
                        name="glassType"
                        label="Glass Type"
                        control={control}
                        options={glassTypesdata && glassTypesdata}
                        disableClearable
                        freeSolo={false}
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="installer"
                        control={control}
                        label="Installer"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="type" control={control} label="Type" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="depth" control={control} label="Depth" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="item" control={control} label="Item" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="itemNo" control={control} label="Item No" />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp
                        name="doNotInclude"
                        control={control}
                        label="Do Not Include"
                    />
                </Grid>
                <Grid item lg={6}>
                    <InputCmp name="custom" control={control} label="Custom" />
                </Grid>
                <Grid item lg={12}>
                    <InputCmp name="other" control={control} label="Other" />
                </Grid>
                {/* <Grid container columnSpacing={8}>
          <Grid item lg={6}>
            <section className={styles.uploadHeadings}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product Thumbnail
              </Typography>
            </section>
            <Box sx={{ my: "40px" }}>
              <DragNDrop
                images={true}
                // setImageSrc={setProductThumbnailSrc}
                // getFile={getThumbnailPic}
              />
            </Box> */}
                {/* {productThumbnailSrc && (
              <Box className={styles.uploadImgShow}>
                <img src={productThumbnailSrc.imgSrc} alt="" />
                <section className={styles.editImgBox}> */}
                {/* <BiEdit color="white" fontSize="50px" />
                        <RiDeleteBin6Line color="white" fontSize="50px" /> */}
                {/* </section>
              </Box>
            )} */}
                {/* </Grid>
          <Grid item lg={6}>
            <section className={styles.uploadHeadings}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product Gallery
              </Typography>
            </section>

            <Box sx={{ my: "40px" }}>
              <DragNDrop
                images={true}
                // getFile={getProductPics}
                // setImageSrc={setProductGallerySrc}
              />
            </Box> */}
                {/* {productGallerySrc && (
              <Box className={styles.listingGallerypic}>
                {productGallerySrc.map((img, index) => {
                  return index === 0 ? (
                    <Box key={index} className={styles.uploadImgShow}>
                      <img src={img.imgSrc} alt="" />
                      <section className={styles.editImgBox}>
                        <RiDeleteBin6Line
                          color="white"
                          fontSize="50px"
                          onClick={() => deleteGalleryPic(index)}
                        />
                      </section>
                    </Box>
                  ) : index === 1 ? (
                    <Box
                      key={index}
                      className={styles.uploadImgShow2}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <h1>{productGallerySrc.length - 1}+</h1>
                    </Box>
                  ) : null;
                })}
                <VIewImagesModal
                  open={open}
                  setOpen={setOpen}
                  projectImages={productGallerySrc}
                  deleteGalleryPic={deleteGalleryPic}
                />
              </Box>
            )} */}
                {/* </Grid>
        </Grid> */}
            </Grid>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '40px 0',
                }}
            >
                <WhiteButtonCmp
                    style={{
                        marginRight: '20px',
                    }}
                    title="Cancel"
                    //   onClick={() => setEditProduct(false)}
                />
                <ButtonCmp
                    title="Create and Add Product"
                    onClick={handleSubmit(onSubmit)}
                />
            </div>
            <BackDropCmp
                setOpen={
                    brandsLoading ||
                    colorsLoading ||
                    materialsLoading ||
                    coatsLoading ||
                    primersLoading ||
                    patternsLoading ||
                    glassTypesLoading ||
                    distributorsLoading ||
                    suppliersLoading ||
                    categoriesLoading ||
                    symbolsLoading
                }
            />
        </section>
    )
}
