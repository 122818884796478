import React from 'react'

import { FavoriteBorderOutlinedIcon } from '../../Assets'
import styles from './IconCmp.module.css'

const IconCmp = ({ style, children, onClick }) => {
    return (
        <div
            className={styles.iconWrapper}
            onClick={onClick}
            style={{ ...style }}
        >
            {children}
        </div>
    )
}

export default IconCmp
