import ActionTypes from '../Constants'

const INITIALSTATE = {
    ///profile widget
    profileWizardLoading: false,
    profileWizarddata: '',
    profileWizardError: '',

    ///profile Details
    profileDetailsLoading: false,
    profileDetails: '',
    profileDetailsError: '',

    ///single profile Details
    singleProfileDetailsLoading: false,
    singleProfileDetails: '',
    singleProfileDetailsError: '',

    ///update profile Details
    UpdateProfileLoading: false,
    UpdateProfiledata: '',
    UpdateProfileError: '',

    ///get cover project
    getCoverProjectLoading: false,
    getCoverProjectdata: '',
    getCoverProjectError: '',
}

const ProfielWizardReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.PROFILE_WIZARD_REQUEST:
            return {
                ...state,
                profileWizardLoading: true,
            }
        case ActionTypes.PROFILE_WIZARD_SUCCESS:
            return {
                ...state,
                profileWizardLoading: false,
                profileWizarddata: action.payload.data,
                profileWizardError: '',
            }
        case ActionTypes.PROFILE_WIZARD_FAIL:
            return {
                ...state,
                profileWizardLoading: false,
                profileWizarddata: '',
                profileWizardError: action.payload.message,
            }
        case ActionTypes.EMPTY_PROFILE_WIZARD_STATE:
            return {
                ...state,
                profileWizardLoading: false,
                profileWizarddata: '',
                profileWizardError: '',
            }
        default:
            return state
    }
}

const UserProfileReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                profileDetailsLoading: true,
            }
        case ActionTypes.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profileDetailsLoading: false,
                profileDetails: action.payload.data[0],
                profileDetailsError: '',
            }
        case ActionTypes.GET_USER_PROFILE_FAIL:
            return {
                ...state,
                profileDetailsLoading: false,
                profileDetails: '',
                profileDetailsError: action.payload.message,
            }
        default:
            return state
    }
}

const SingleUserProfileReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_SINGLE_USER_PROFILE_REQUEST:
            return {
                ...state,
                singleProfileDetailsLoading: true,
            }
        case ActionTypes.GET_SINGLE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                singleProfileDetailsLoading: false,
                singleProfileDetails: action.payload.data,
                singleProfileDetailsError: '',
            }
        case ActionTypes.GET_SINGLE_USER_PROFILE_FAIL:
            return {
                ...state,
                singleProfileDetailsLoading: false,
                singleProfileDetails: '',
                singleProfileDetailsError: action.payload.message,
            }
        default:
            return state
    }
}

const UpdateProfileReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                UpdateProfileLoading: true,
            }
        case ActionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                UpdateProfileLoading: false,
                UpdateProfiledata: action.payload.data,
                UpdateProfileError: '',
            }
        case ActionTypes.UPDATE_PROFILE_FAIL:
            return {
                ...state,
                UpdateProfileLoading: false,
                UpdateProfiledata: '',
                UpdateProfileError: action.payload.message,
            }
        default:
            return state
    }
}

const GetCoverProjectReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_PROFILE_COVER_PROJECT_REQUEST:
            return {
                ...state,
                getCoverProjectLoading: true,
            }
        case ActionTypes.GET_PROFILE_COVER_PROJECT_SUCCESS:
            return {
                ...state,
                getCoverProjectLoading: false,
                getCoverProjectdata: action.payload.data,
                getCoverProjectError: '',
            }
        case ActionTypes.GET_PROFILE_COVER_PROJECT_FAIL:
            return {
                ...state,
                getCoverProjectLoading: false,
                getCoverProjectdata: '',
                getCoverProjectError: action.payload.message,
            }
        default:
            return state
    }
}

export {
    ProfielWizardReducer,
    UserProfileReducer,
    UpdateProfileReducer,
    GetCoverProjectReducer,
    SingleUserProfileReducer,
}
