import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ButtonCmp } from '..'
import { AccountAlert, LocationOnOutlinedIcon } from '../../Assets'

import useAlert from '../../Hooks/useAlert/useAlert'
import {
    CreateConnectionAction,
    // remove Connection / Freind
    RemoveConnectionAction,
    // remove Connection / Freind request
    RemoveConnectionRequestAction,
} from '../../Store/Actions/ProConnectionAction'
import ThreeDotsMenu from '../ThreeDotsMenu/ThreeDotsMenu'
import styles from './ConnectionCard.module.css'

const ConnectionCard = ({
    pending,
    open,
    setOpen,
    userConnectin,
    setUserDetails,
}) => {
    const { name, is_connected, image, ImgURL, email, services } =
        userConnectin || {}
    console.log('pending status', pending)
    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    //send request handler
    const createConnection = () => {
        dispatch(CreateConnectionAction(email, setAlert))
    }
    const removeConnectionRequest = () => {
        dispatch(RemoveConnectionRequestAction(email, setAlert))
    }

    const removeConnection = () => {
        dispatch(RemoveConnectionAction(email, setAlert))
    }

    return (
        <section className={styles.connectionCard}>
            <div className={styles.connectionCardImg}>
                <img src={ImgURL + image} alt="" />
                {pending && (
                    <div className={styles.threeDots}>
                        <ThreeDotsMenu
                            removeConnectionRequest={removeConnectionRequest}
                        />
                    </div>
                )}
            </div>
            <div className={styles.connectionCardName}>
                <Typography
                    onClick={() => {
                        setOpen(!open)
                        setUserDetails(userConnectin)
                    }}
                    noWrap
                    variant="h5"
                    fontWeight={'bold'}
                    sx={{ cursor: 'pointer' }}
                >
                    {name && name}
                </Typography>
                <Typography noWrap sx={{ fontSize: '14px', my: '5px' }}>
                    Contractor
                </Typography>
                <Typography
                    noWrap
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        justifyContent: 'center',
                        my: '5px',
                    }}
                >
                    <LocationOnOutlinedIcon
                        sx={{ color: 'var(--primary-color)' }}
                    />
                    New Orleans, LA
                </Typography>
            </div>
            <div className={styles.connectionCardTags}>
                {services && services?.length > 0 ? (
                    <>
                        <section className={styles.scrollTabsBar2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <img src={AccountAlert} alt="" />
                                <Typography sx={{ fontSize: '14px' }}>
                                    {services[0].service.title}
                                </Typography>
                            </Box>
                        </section>
                        <Box>
                            <Typography sx={{ fontSize: '14px' }}>
                                {services.length - 1} more...
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <section className={styles.scrollTabsBar2}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <img src={AccountAlert} alt="" />
                                <Typography sx={{ fontSize: '14px' }}>
                                    No service
                                </Typography>
                            </Box>
                        </section>
                    </>
                )}
            </div>
            <div className={styles.connectionCardBtn}>
                {is_connected ? (
                    <ButtonCmp
                        title="DisConnect"
                        style={{ backgroundColor: 'var(--redColor)' }}
                        onClick={removeConnection}
                    />
                ) : pending ? (
                    <ButtonCmp title="pending Request" disabled />
                ) : (
                    <ButtonCmp title="Connect" onClick={createConnection} />
                )}
            </div>
        </section>
    )
}

export default ConnectionCard
