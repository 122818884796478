import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const BoxCmp = ({ title, price }) => {
    return (
        <Box>
            <Card
                sx={{
                    minWidth: 220,
                    minHeight: 160,
                    borderRadius: 2,
                    marginBottom: 5,
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            mt: 7,
                        }}
                    >
                        {title ? title : 'Insurance'}
                    </Typography>
                    <Typography sx={{ mt: 5.5 }}>
                        {price ? `$${price}` : '$25'}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
}

export default BoxCmp
