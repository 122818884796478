import IconCmp from '../IconCmp/IconCmp'
import { CloseIcon } from '../../Assets'
import DragNDrop from '../DragNDrop/DragNDrop'
import styles from './ImgModal.module.css'
import WhiteButtonCmp from '../ButtonCmp/WhiteButtonCmp'
import ButtonCmp from '../ButtonCmp/ButtonCmp'
import Backdrop from '@mui/material/Backdrop'

import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Avatar from 'react-avatar-edit'
import Pic1 from '../../Assets/Images/BuildLinkCompleteLogo.png'
import { useEffect } from 'react'
import { dataURLtoFile } from '../../Utils/dataURLtofile'
import { useDispatch } from 'react-redux'
import {
    UpdateProfileAction,
    UpdateProfilePicAction,
} from '../../Store/Actions/ProfileAction'
import useAlert from '../../Hooks/useAlert/useAlert'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    outline: 'none !important',
    p: 4,
}

export default function ImgModal({
    open,
    setOpen,
    getImages,
    setGetImages,
    getImagesFucntion,
}) {
    //   const [getImage, setGetImage] = React.useState(false);
    //   const [profileImgSrc, setProfileImgSrc] = React.useState(false);
    const [imgCrop, setImgCrop] = React.useState(false)
    const [storeImg, setStoreImg] = React.useState([])

    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    const onCrop = (view) => {
        setImgCrop(view)
    }

    const onClose = () => {
        setImgCrop(null)
    }

    const savaImage = () => {
        setStoreImg([...storeImg, { imgCrop }])
    }
    const { setAlert } = useAlert()
    const distpatch = useDispatch()

    useEffect(() => {
        if (storeImg.length > 0) {
            const fileName = dataURLtoFile(
                storeImg[0].imgCrop,
                'croping-img.png'
            )
            console.log(fileName, 'fileName')
            setGetImages(fileName)
            distpatch(
                UpdateProfilePicAction(
                    fileName,
                    'profilePic',
                    setAlert,
                    setOpen
                )
            )
        }
    }, [storeImg])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style}>
                    {/* ///header/// */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant={'h6'}>Upload Image</Typography>

                        <IconCmp>
                            <CloseIcon onClick={handleClose} />
                        </IconCmp>
                    </Box>

                    {/* ///BODY/// */}
                    <Box className={styles.modalBody}>
                        {/* {!profileImgSrc && (
              <Box className={styles.imageuploadBox}>
                <DragNDrop
                  images={true}
                  setImageSrc={setProfileImgSrc}
                  getFile={setGetImage}
                />
              </Box>
            )} */}
                        {/* {profileImgSrc && <img src={profileImgSrc} alt="pic" />} */}
                        <Avatar
                            width={'100%'}
                            height={400}
                            onCrop={onCrop}
                            onClose={onClose}
                            minCropRadius={80}
                            cropRadius={80}
                        />
                    </Box>

                    {/* ///footer// */}
                    <Box className={styles.footerModal}>
                        <WhiteButtonCmp title="cancel" onClick={handleClose} />
                        <ButtonCmp onClick={savaImage} title="Save" />
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
