import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

export default function PaginationRounded({
    handlePaginate,
    total,
    currentPage,
    isMyBids,
    pagination,
    showNumber,
}) {
    const totalData = showNumber ? showNumber : 15

    return pagination && pagination?.total > totalData ? (
        <Stack spacing={2}>
            <Pagination
                count={pagination?.last_page}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={(event, page) => handlePaginate(page)}
            />
        </Stack>
    ) : (
        <Stack spacing={2}>
            {isMyBids ? (
                <Pagination
                    count={total}
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    onChange={(event, page) => handlePaginate(page)}
                />
            ) : (
                <Pagination count={10} variant="outlined" shape="rounded" />
            )}
        </Stack>
    )
}
