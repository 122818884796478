import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, onMessage } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: 'AIzaSyCgP2-N8fDNfBiwG1axtdJqUcrhH45Y8Os',
    authDomain: 'build-links.firebaseapp.com',
    projectId: 'build-links',
    storageBucket: 'build-links.appspot.com',
    messagingSenderId: '405690995888',
    appId: '1:405690995888:web:08916256208e8f23f61775',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db, messaging }

const messaging = getMessaging(app)

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
