import { Grid, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ManuCard } from '../../Assets'
import ButtonCmp from '../ButtonCmp/ButtonCmp'
import WhiteButtonCmp from '../ButtonCmp/WhiteButtonCmp'

import styles from './SupplierProductCard.module.css'

const SupplierProductCard = ({ product, path, handleDeleteProduct }) => {
    const navigate = useNavigate()
    const { title, brand, model_no, sku, model_name, images, ImgURL } =
        product || {}

    const thumbnail =
        images.length > 0 && images.find((item) => item.is_thumbnail === 1)

    return (
        <div
            className={styles.ManufacturerCard_wrapper}
            onClick={() => navigate(path)}
        >
            <section className={styles.ManufacturerCard_wrapper_imgSection}>
                <img
                    alt={title}
                    src={
                        thumbnail?.image ? ImgURL + thumbnail?.image : ManuCard
                    }
                />
            </section>
            <section className={styles.ManufacturerCard_wrapper_content}>
                <div>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {title ? title : 'GenAir Elite'}
                    </Typography>
                </div>
                {/* <Box sx={{ display: "flex", gap: "20px", my: "10px" }}>
          <Typography sx={{ color: "var(--textColor)", fontSize: "14px" }}>
            Brand Name:
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {brand?.label ? brand.label : "Genair"}
          </Typography>
        </Box> */}
                <Box sx={{ display: 'flex', gap: '20px', my: '10px' }}>
                    <Typography
                        sx={{ color: 'var(--textColor)', fontSize: '12px' }}
                    >
                        SKU:
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                        {sku ? sku : '0548'}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'space-between',
                        mt: '20px',
                    }}
                >
                    <section>
                        <Typography
                            sx={{ color: 'var(--textColor)', fontSize: '14px' }}
                        >
                            Model Number
                        </Typography>
                        <Typography
                            sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                            {model_no ? model_no : '43434-20e'}
                        </Typography>
                    </section>
                    <section>
                        <Typography
                            sx={{ color: 'var(--textColor)', fontSize: '14px' }}
                        >
                            Brand
                        </Typography>
                        <Typography
                            sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                            {brand?.label ? brand.label : 'Genair'}
                        </Typography>
                    </section>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: 2,
                        mt: '20px',
                    }}
                >
                    <WhiteButtonCmp
                        onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteProduct(product.slug)
                        }}
                        title="Delete"
                        style={{
                            width: '100%',
                            borderColor: ' red !important',
                            color: 'red',
                        }}
                    />
                    {/* <ButtonCmp
            style={{ width: "100%", padding: "15px 15px" }}
            title="Restore"
          /> */}
                </Box>
            </section>
        </div>
    )
}

export default SupplierProductCard
