import * as React from 'react'
import Switch from '@mui/material/Switch'

const label = { inputProps: { 'aria-label': 'controlled' } }

export default function SwitchCmp({ onClick, value }) {
    return (
        <div>
            <Switch {...label} checked={value} onChange={onClick} />
        </div>
    )
}
