import { Box, Rating, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RatingCmp } from '..'

import {
    ChatIcon,
    LocationOnOutlinedIcon,
    WatchLaterOutlinedIcon,
} from '../../Assets'
import styles from './CompletedBidCard.module.css'

const CompletedBidCard = ({ projectFB, companyFB, path, completedBidData }) => {
    const navigate = useNavigate()

    const { bid_amount, project, user: projectOwner } = completedBidData

    const {
        job_start_date,
        job_completion_date,
        job_awarded_date,
        address,
        city,
        state,
        user,
        zipcode,
        rating_as_owner,
        contractor_review,
        freelancer_review,
        rating_as_bidder,
    } = project

    const { company_name, label } = user || {}

    console.log('completedBidData', completedBidData)

    return (
        <section
            className={styles.card_wrapper}
            onClick={() => {
                navigate(path)
            }}
        >
            <section className={styles.project_wrappper}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography
                            variant="h5"
                            fontWeight={'bold'}
                            className={styles.bidName}
                        >
                            {project?.label}
                        </Typography>
                    </Box>
                    <Box sx={{ margin: '5px 0 ' }}>
                        <Typography
                            sx={{
                                fontSize: 'small',
                                display: 'flex',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                            }}
                        >
                            <LocationOnOutlinedIcon
                                fontSize="small"
                                sx={{
                                    color: 'var(--primary-color)',
                                    marginLeft: '-5px',
                                }}
                            />
                            {/* 312 Main Street, New Orleans LA, 70115 */}
                            {address}, {city?.label}, {state?.label}, {zipcode}
                        </Typography>
                    </Box>
                </Box>

                <div className={styles.projectDate}>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Start Date:
                        </Typography>
                        <Typography fontSize="14px">
                            {job_start_date}
                        </Typography>
                    </section>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Completition Date:
                        </Typography>
                        <Typography fontSize="14px">
                            {job_completion_date}
                        </Typography>
                    </section>
                </div>
                <div className={styles.project__feedback}>
                    {contractor_review ? (
                        <>
                            <RatingCmp
                                readOnly={true}
                                ratingValue={rating_as_bidder}
                            />
                            <Typography>
                                {rating_as_bidder} of 5 reviews
                            </Typography>
                        </>
                    ) : (
                        <>
                            <WatchLaterOutlinedIcon fontSize="small" />
                            <Typography fontSize="small">
                                No Feedback Received
                            </Typography>
                        </>
                    )}
                </div>
            </section>

            {/* ///company details */}
            <section className={styles.company_wrappper}>
                <Box
                    sx={{ display: ' flex', justifyContent: 'space-between' }}
                    className={styles.companyName}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography variant="h5" fontWeight={'bold'}>
                                {company_name || label}
                            </Typography>
                        </Box>
                        <Box sx={{ margin: '5px 0 ' }}>
                            <Typography
                                sx={{
                                    fontSize: 'small',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <LocationOnOutlinedIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'var(--primary-color)',
                                        marginLeft: '-5px',
                                    }}
                                />
                                {projectOwner?.street_address},
                                {projectOwner?.city?.label || user?.city?.name},
                                {projectOwner?.state?.label ||
                                    projectOwner?.state?.name}
                                ,{projectOwner?.zip}
                            </Typography>
                        </Box>
                    </Box>
                    <div>
                        <Typography
                            variant="h5"
                            fontWeight={'bold'}
                            color={{ color: 'var(--green)' }}
                        >
                            ${bid_amount}
                        </Typography>
                        <Typography
                            fontSize="small"
                            sx={{ textAlign: 'right' }}
                        >
                            Project Cost
                        </Typography>
                    </div>
                </Box>

                <div className={styles.projectDate}>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Awarded Date:
                        </Typography>
                        <Typography fontSize="small">
                            {job_awarded_date}
                        </Typography>
                    </section>
                    {/* <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Awarded Date:
            </Typography>
            <Typography fontSize="small">8/25/2021</Typography>
          </section> */}
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Contact Details:
                        </Typography>
                        <Typography
                            fontSize="small"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                cursor: 'pointer',
                            }}
                        >
                            <img src={ChatIcon} alt="" />
                            Message
                        </Typography>
                    </section>
                </div>
                <div className={styles.project__feedback}>
                    {freelancer_review ? (
                        <>
                            <RatingCmp
                                readOnly={true}
                                ratingValue={rating_as_owner}
                            />
                            <Typography>
                                {rating_as_owner} of 5 reviews
                            </Typography>
                        </>
                    ) : (
                        <>
                            <WatchLaterOutlinedIcon fontSize="small" />
                            <Typography fontSize="small">
                                No Feedback Given
                            </Typography>
                        </>
                    )}
                </div>
            </section>
        </section>
    )
}

export default CompletedBidCard
