import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

export default function BackDropCmp({ setOpen }) {
    //   const [open, setOpen] = React.useState(false);
    //   const handleClose = () => {
    //     setOpen(false);
    //   };
    //   const handleToggle = () => {
    //     setOpen(!open);
    //   };

    return (
        <div>
            {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={setOpen}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
