import React, { useEffect, useState } from 'react'
import styles from './BidDetails.module.css'
import { Box, Rating, Typography } from '@mui/material'
import {
    ButtonCmp,
    ModalCard,
    RatingCmp,
    ScrollableTabs,
    BackDropCmp,
} from '..'
import ProfilePic from '../../Assets/Images/Mask Group 18.png'
import PdfIcon from '../../Assets/Icons/pdf.png'
import {
    KeyboardArrowLeftOutlinedIcon,
    KeyboardArrowRightOutlinedIcon,
    LocationOnOutlinedIcon,
    VerifiedOutlinedIcon,
} from '../../Assets'
import { useDispatch, useSelector } from 'react-redux'
import {
    AcceptProposalActon,
    EmptyAcceptProposalState,
} from '../../Store/Actions/BuildBidsJobAction'
import useAlert from '../../Hooks/useAlert/useAlert'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Utils/response'

const BidDetails = ({
    setAccordion,
    accordion,
    bidsDetails,
    setBidsDetails,
    myBid,
    singleBidDetailsdata,
    nextBidHandler,
    prevBidHandler,
}) => {
    const dispatch = useDispatch()
    const { acceptProposalLoading, acceptProposalData, acceptProposalMsg } =
        useSelector((state) => state.AcceptProposalReducer)

    const { setAlert } = useAlert()
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)

    const {
        bid_amount,
        proposal_bid_note,
        project,
        proposal_services,
        user,
        created_at,
        id,
        status,
        next,
        previous,
        proposal_pdf,
        img_url,
    } = singleBidDetailsdata

    const acceptProposalHandler = () => {
        dispatch(AcceptProposalActon(project.slug, id, setAlert))
        setOpen(!open)
    }

    useEffect(() => {
        if (acceptProposalData) {
            setAlert(acceptProposalMsg, 'success')
            dispatch(EmptyAcceptProposalState())
            navigate('/buildbid/awardedjob')
        }
    }, [acceptProposalData])

    return (
        <div className={styles.bidDetails__wrapper}>
            <section className={styles.bidNavigation}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    className={styles.bidNavigation__pageBack}
                    onClick={() => navigate(-1)}
                >
                    <Box
                        className={styles.iconBox}
                        onClick={() => {
                            setAccordion(!accordion)
                            setBidsDetails(!bidsDetails)
                        }}
                    >
                        <KeyboardArrowLeftOutlinedIcon fontSize={'small'} />
                    </Box>
                    <Typography fontWeight={'bold'}> Back to list</Typography>
                </Box>
                <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className={styles.bidNavigation_bidNav}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            paddingRight: 2,
                            marginRight: 2,
                            borderRight: '1px solid var(--borderColor)',
                            opacity: previous ? 1 : 0.5,
                            pointerEvents: previous ? 'visible' : 'none',
                        }}
                        className={styles.bidNav__prev}
                        onClick={() => prevBidHandler()}
                    >
                        <Box className={styles.iconBox}>
                            <KeyboardArrowLeftOutlinedIcon fontSize={'small'} />
                        </Box>
                        <Typography sx={{ color: 'var(--textColor)' }}>
                            Previous Bid
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            opacity: next ? 1 : 0.5,
                            pointerEvents: next ? 'visible' : 'none',
                        }}
                        className={styles.bidNav__next}
                        onClick={() => {
                            nextBidHandler()
                        }}
                    >
                        <Typography sx={{ color: 'var(--textColor)' }}>
                            Next Bid
                        </Typography>
                        <Box className={styles.iconBox}>
                            <KeyboardArrowRightOutlinedIcon
                                fontSize={'small'}
                            />
                        </Box>
                    </Box>
                </Box>
            </section>
            <section className={styles.bidDetails__wallpaper}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h5">{user?.name}.</Typography>
                    </Box>
                    <Box
                        sx={{
                            margin: '5px 0 ',
                            display: 'flex',
                            gap: 3,
                            flexWrap: 'Wrap',
                        }}
                        className={styles.profilePic_deatils}
                    >
                        <Typography
                            sx={{
                                fontSize: 'small',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <LocationOnOutlinedIcon sx={{ color: 'white' }} />
                            {user?.street_address
                                ? user?.street_address
                                : '312 Main Street, New Orleans LA, 70115'}
                        </Typography>
                        {user?.is_verify === 1 && (
                            <Typography
                                fontSize={'15px'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <VerifiedOutlinedIcon sx={{ color: 'white' }} />
                                Verified
                            </Typography>
                        )}
                        <RatingCmp ratingValue={user?.rating} readOnly={true} />
                    </Box>
                </Box>
                <div className={styles.profilePic_wrapper}>
                    <section className={styles.picture}>
                        <img src={ProfilePic} alt="" />
                    </section>
                </div>
            </section>
            <Box style={{ padding: '30px 50px' }}>
                <Typography fontSize={'15px'} sx={{ fontSize: 'small' }}>
                    Bid Amount
                </Typography>
                <Typography variant="h4" fontWeight="bold">
                    ${bid_amount}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 'small',
                        color: 'var(--textColor)',
                        mt: '5px',
                    }}
                >
                    Response Date: {created_at}
                </Typography>
            </Box>
            {/* //Tags// */}

            <section className={styles.cardFooter}>
                <div>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Services:
                    </Typography>
                </div>
                <ScrollableTabs servicesTags={proposal_services} />
            </section>

            {/* //Proposal// */}
            <Box className={styles.proposalWrapper}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    Proposal
                </Typography>
                <Typography>{proposal_bid_note}</Typography>
            </Box>

            {/* //Attachment// */}
            {proposal_pdf?.length > 0 && (
                <section className={styles.attachmentWrapper}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Attachment
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            my: '20px',
                        }}
                    >
                        {proposal_pdf?.map((pdf, index) => {
                            return (
                                <a
                                    key={index}
                                    href={img_url + pdf?.original_pdf}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.pdfBox}
                                    // onClick={() =>
                                    //   navigate(
                                    //     `${BASE_URL}/uploads/proposals/${pdf.original_pdf}`
                                    //   )
                                    // }
                                >
                                    <img src={PdfIcon} alt="" />
                                    <div>
                                        {/* <Typography>This is sample-pdf-attachment...</Typography> */}
                                        <Typography>{pdf?.title}</Typography>
                                    </div>
                                </a>
                            )
                        })}
                    </Box>
                </section>
            )}

            {status === 'Pending' && (
                <Box sx={{ padding: '0 50px', margin: '30px 0' }}>
                    <ButtonCmp
                        title={'Accept Proposal'}
                        style={{ width: 'max-content' }}
                        onClick={() => {
                            setOpen(!open)
                        }}
                    />
                </Box>
            )}
            <ModalCard
                open={open}
                projectName={project?.label}
                companyName={user?.label}
                setOpen={setOpen}
                onClick={() => {
                    acceptProposalHandler()
                }}
            />
            <BackDropCmp setOpen={acceptProposalLoading} />
        </div>
    )
}

export default BidDetails
