import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './ConfirmPassword.module.css'
import logo from '../../Assets/Images/BuildLinkCompleteLogo.png'
import buildBid from '../../Assets/Images/Build-BID2.png'
import buildStudio from '../../Assets/Images/BuildStudiologo-gold.png'
import grid from '../../Assets/Images/Group194.png'
import {
    AlertCmp,
    BackDropCmp,
    InputCmp,
    LottieFilesCmp,
} from '../../Components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmPasswordAction } from '../../Store/Actions/AuthActions'
import useAlert from '../../Hooks/useAlert/useAlert'
import { ForgetPasswordAnimation } from '../../Assets'

export default function ConfirmPassword() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const otpCode = location.state

    const { confirmPasswordLoading } = useSelector(
        (state) => state.ConfirmPasswordReducers
    )

    const { setAlert } = useAlert()

    useEffect(() => {
        if (!otpCode) {
            navigate('/')
        }
    }, [])

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 16 characters'),
        password_confirmation: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 16 characters'),
    })

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: '',
            password_confirmation: '',
        },
        resolver: yupResolver(validationSchema),
    })

    const [notificationToken, setNotificationToken] = useState(null)

    const onSubmit = (obj) => {
        const objToSend = {
            ...obj,
            code: otpCode,
        }
        console.log(objToSend, 'objToSend haha')
        dispatch(ConfirmPasswordAction(objToSend, setAlert, navigate))
    }

    return (
        <div className={styles.signInPage}>
            <Paper className={styles.paper}>
                <div className={styles.signInArea}>
                    <img className={styles.logo} src={logo} alt="logo" />

                    <Typography className={styles.typo1}>
                        Write Password
                    </Typography>
                    <form
                        name="loginForm"
                        onSubmit={handleSubmit(onSubmit)}
                        className={styles.form}
                    >
                        <Box className={styles.controller}>
                            <InputCmp
                                name="password" //imporant
                                control={control} //imporant
                                type="password"
                                label="Password"
                                autoFocus
                            />
                        </Box>
                        <Box className={styles.controller}>
                            <InputCmp
                                name="password_confirmation" //imporant
                                control={control} //imporant
                                type="password"
                                label="Confirm Password"
                            />
                        </Box>
                        <Button
                            variant="contained"
                            // color="secondary"
                            className={styles.button}
                            aria-label="Sign in"
                            size="large"
                            type="submit"
                        >
                            Submit
                        </Button>

                        <div className={styles.belowSignIn}>
                            <div className={styles.belowSignInBorder} />
                            <Typography
                                className={styles.belowSignInTypo}
                                color="text.secondary"
                            >
                                Powered By
                            </Typography>
                            <div className={styles.belowSignInBorder} />
                        </div>

                        <div className={styles.socialMedia}>
                            <img
                                className={styles.logo}
                                src={buildBid}
                                alt="logo"
                            />
                            <img
                                className={styles.buildLogos}
                                src={buildStudio}
                                alt="logo"
                            />
                        </div>
                    </form>
                </div>
            </Paper>

            <Box
                className={styles.secondBox}
                sx={{ backgroundColor: 'primary.main' }}
            >
                <LottieFilesCmp animation={ForgetPasswordAnimation} />
                <img src={grid} alt="" className={styles.grid} />
            </Box>
            <BackDropCmp setOpen={confirmPasswordLoading} />
        </div>
    )
}
