import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './Store'
import 'react-image-gallery/styles/css/image-gallery.css'
import { AlertProvider } from './Store/Context/AlertContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <AlertProvider>
                <App />
            </AlertProvider>
        </BrowserRouter>
    </Provider>
)

reportWebVitals()
