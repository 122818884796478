import React, { useState, useEffect, useRef } from 'react'
import { ButtonCmp, TextAreaCmp } from '../../Components'
import { useDispatch } from 'react-redux'
import styles from './Detail.module.css'
import { BASE_URL } from '../../Utils/response'
import { getUserToken } from '../../Utils/LoginAuth'
import { Avatar, Box, Card, Grid, Typography, Link } from '@mui/material'
import useAlert from '../../Hooks/useAlert/useAlert'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { validation } from '../../Config'
import { DropDownCmp } from '../../Components'
import InputBox from '../../Components/InputBox/InputBox'
import { FaRegFilePdf } from 'react-icons/fa'
import { IoMdAttach } from 'react-icons/io'
import Button from '@mui/material/Button'
import BoxCmp from './BoxCmp'

const Detail = () => {
    const { setAlert } = useAlert()
    let [loading, setLoading] = useState(false)
    const { orderId } = useParams()
    const location = useLocation()
    const order = location.state

    console.log(order.verification_order_details)

    // Convert strings to Date objects
    let date1 = new Date(order.updated_at)
    let date2 = new Date()

    date2.setDate(date2.getDate() + 8)

    // Calculate the difference in time (milliseconds)
    let timeDifference = date2 - date1

    // Convert time difference from milliseconds to days
    let dayDifference = (timeDifference / (1000 * 60 * 60 * 24)).toLocaleString(
        'en-US',
        { maximumFractionDigits: 0 }
    )

    const getHeader = () => {
        return {
            Authorization: 'Bearer' + ' ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }

    const handleAccept = () => {
        const formData = new FormData()
        formData.append('status', 'Accepted')

        axios
            .post(
                `${BASE_URL}/user-verifications-orders/${orderId}/verifications-orders-status`,
                formData,
                {
                    headers: getHeader(),
                }
            )
            .then((response) => {
                setAlert(response.data.message || 'Order Accepted', 'success')
                navigate(`/buildinsurance/orders/general`)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setAlert(
                    error.response.data.message || 'An error occurred',
                    'error'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleReject = () => {
        const formData = new FormData()
        formData.append('status', 'Declined')

        axios
            .post(
                `${BASE_URL}/user-verifications-orders/${orderId}/verifications-orders-status`,
                formData,
                {
                    headers: getHeader(),
                }
            )
            .then((response) => {
                setAlert(response.data.message || 'Order Accepted', 'success')
                navigate(`/buildinsurance/orders/general`)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setAlert(
                    error.response.data.message || 'An error occurred',
                    'error'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const navigate = useNavigate()

    const formValidation = validation.productValidation
    const { control, handleSubmit, getValues, setValue } = useForm({
        mode: 'onChange',
        //resolver: yupResolver(formValidation),
    })

    const {
        fields: certificateFields,
        append: certificateAppend,
        replace: certificateReplace,
    } = useFieldArray({
        control,
        name: 'certificates',
    })

    useEffect(() => {
        certificateReplace([])
        order.requested_for_user.certificates
            .filter((item) => item.status !== 'Verified')
            .forEach((item) => {
                certificateAppend({
                    certificate_id: item.id,
                    certificate_expiry: item.expiry,
                    certificate_comment: item.comment,
                    certificate_status: item.status,
                    certificate_attachment: item.ImgURL + item.document,
                    certificate_attachment_name: '',
                })
            })
    }, [])

    const onSubmit = (data) => {
        const formData = new FormData()
        formData.append(`status`, 'Completed')
        data.certificates.forEach((x, i) => {
            formData.append(`certificates[${i}][id]`, x.certificate_id)
            formData.append(
                `certificates[${i}][certificate_status]`,
                x.certificate_status
            )
            formData.append(
                `certificates[${i}][certificate_expiry]`,
                x.certificate_expiry
            )
            formData.append(
                `certificates[${i}][certificate_comment]`,
                x.certificate_comment
            )
            formData.append(
                `certificates[${i}][certificate_attachment]`,
                x.certificate_attachment
            )
        })

        axios
            .post(
                `${BASE_URL}/user-verifications-orders/${orderId}/change-verifications-orders-status`,
                formData,
                {
                    headers: getHeader(),
                }
            )
            .then((response) => {
                setAlert(response.data.message || 'Order Updated', 'success')
                navigate(`/buildinsurance/orders/Accepted`)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setAlert(
                    error.response.data.message || 'An error occurred',
                    'error'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Box
                sx={{ backgroundColor: 'rgb(241 245 249)', paddingTop: '2rem' }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        // gap: 2,
                    }}
                >
                    <BoxCmp title={'General Insurance'} price={'298'} />
                    <BoxCmp title={'Construction Insurance'} price={'878'} />
                    <BoxCmp title={''} price={'8'} />
                    <BoxCmp title={''} />
                </Box>
                <Box
                    sx={{
                        marginRight: '2rem',
                        display: 'flex',
                        justifyContent: 'end',
                        gap: 2,
                    }}
                >
                    {order.status === 'Pending' && (
                        <>
                            <ButtonCmp
                                title={'Reject'}
                                btnColor={'error'}
                                onClick={handleReject}
                            ></ButtonCmp>
                            <ButtonCmp
                                onClick={handleAccept}
                                title={'Accept'}
                                btnColor={'success'}
                            ></ButtonCmp>
                        </>
                    )}
                    {order.status === 'Accepted' && (
                        <div>
                            {dayDifference <= 8 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <p>
                                        Time left to Submit : {dayDifference}{' '}
                                        days
                                    </p>
                                </Box>
                            )}
                            {dayDifference > 8 && (
                                <Box sx={{}}>Verification Time Expired</Box>
                            )}
                        </div>
                    )}
                </Box>

                <Card
                    sx={{
                        minWidth: 275,
                        margin: '20px',
                        padding: '20px',
                        borderRadius: '10px',
                    }}
                >
                    <div className={styles.profile_information_heading}>
                        <Typography
                            noWrap
                            variant="h5"
                            sx={{ color: 'var(--black)', fontWeight: 'bold' }}
                        >
                            General Details
                        </Typography>
                    </div>

                    <div
                        className={styles.detailsBoxes}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p className="font-bold">Company Name</p>
                            <p>
                                {order.requested_for_user.company_name
                                    ? order.requested_for_user?.company_name
                                    : 'N/A'}
                            </p>
                        </div>
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p className="font-bold">Insurance Category</p>
                            <p>
                                {order.requested_for_user.company_name
                                    ? order.requested_for_user?.company_name
                                    : 'N/A'}
                            </p>
                        </div>
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p className="font-bold">City, State</p>
                            <p>
                                {order.requested_for_user.company_name
                                    ? order.requested_for_user?.company_name
                                    : 'N/A'}
                            </p>
                        </div>
                    </div>

                    {/* //row 2 */}
                    <div
                        className={styles.detailsBoxes}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p className="font-bold">Date of Verified</p>
                            <p>25-07-2024</p>
                        </div>
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p className="font-bold">Date if Expiration</p>
                            <p>26-04-2024</p>
                        </div>
                        <div style={{ minWidth: '200px', margin: '0 10px' }}>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </Card>

                <Card
                    sx={{
                        minWidth: 275,
                        margin: '20px',
                        padding: '20px',
                        borderRadius: '10px',
                    }}
                >
                    <div className={styles.profile_information_heading}>
                        <Typography noWrap variant="h5" fontWeight={'bold'}>
                            Verify Certificates{' '}
                        </Typography>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        {' '}
                        <div>
                            {order.status === 'Completed' && (
                                <div>
                                    {' '}
                                    <Typography
                                        noWrap
                                        variant="h6"
                                        fontWeight={'bold'}
                                    >
                                        Verification Submitted
                                    </Typography>
                                    <div
                                        style={{
                                            marginTop: '1rem',
                                        }}
                                    >
                                        {order.verification_order_details &&
                                            order.verification_order_details.map(
                                                (item) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                gap: '1rem',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            {' '}
                                                            <div>
                                                                {' '}
                                                                <Typography
                                                                    noWrap
                                                                    variant="h6"
                                                                    fontWeight={
                                                                        'bold'
                                                                    }
                                                                >
                                                                    <Link
                                                                        href={
                                                                            order.requested_for_user.certificates.find(
                                                                                (
                                                                                    cert
                                                                                ) =>
                                                                                    cert.id ===
                                                                                    item.user_certificate_id
                                                                            )
                                                                                ?.ImgURL +
                                                                            order.requested_for_user.certificates.find(
                                                                                (
                                                                                    cert
                                                                                ) =>
                                                                                    cert.id ===
                                                                                    item.user_certificate_id
                                                                            )
                                                                                ?.document
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        View
                                                                        Certificate
                                                                    </Link>
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                Comment:{' '}
                                                                {!item.comment ||
                                                                item.comment ===
                                                                    undefined
                                                                    ? item.comment
                                                                    : '-'}
                                                            </div>
                                                            <div>
                                                                Status:{' '}
                                                                {item.status}
                                                            </div>
                                                            <div>
                                                                Expiry:{' '}
                                                                {
                                                                    item.expiry_date
                                                                }
                                                            </div>
                                                            <div>
                                                                {' '}
                                                                <Typography
                                                                    noWrap
                                                                    variant="h6"
                                                                    fontWeight={
                                                                        'bold'
                                                                    }
                                                                >
                                                                    <Link
                                                                        href={
                                                                            item.img_url +
                                                                            item.attachment
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        View
                                                                        Attachment
                                                                    </Link>
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                    </div>
                                </div>
                            )}{' '}
                            {order.status !== 'Completed' &&
                                certificateFields &&
                                certificateFields.map((item, index) => {
                                    return (
                                        <div
                                            key={item.id}
                                            style={{
                                                minWidth: 275,
                                                margin: '20px',
                                                padding: '20px',
                                                borderRadius: '5px',
                                                borderColor: '#f1f5f9',
                                                borderStyle: 'solid',
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '2rem',
                                                        marginTop: '1rem',
                                                        // flexGrow: 1,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: '3.5rem',
                                                            alignItems:
                                                                'center',
                                                            flexGrow: 3,
                                                        }}
                                                    >
                                                        <p>Expiry</p>
                                                        <InputBox
                                                            name={`certificates[${index}].certificate_expiry`}
                                                            type="date"
                                                            control={control}
                                                            disabled={
                                                                order.status !==
                                                                'Accepted'
                                                            }
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: '1rem',
                                                            flexGrow: 4.5,
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <p>Status</p>
                                                        <DropDownCmp
                                                            name={`certificates[${index}].certificate_status`}
                                                            options={[
                                                                'Verified',
                                                                'Not Verified',
                                                                'Expired',
                                                            ]}
                                                            control={control}
                                                            disableClearable
                                                            freeSolo={false}
                                                            disabled={
                                                                order.status !==
                                                                'Accepted'
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: '1rem',
                                                            flexGrow: 1,
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Controller
                                                            disabled={
                                                                order.status !==
                                                                'Accepted'
                                                            }
                                                            name={`certificates[${index}].certificate_attachment`}
                                                            control={control}
                                                            defaultValue=""
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <>
                                                                    <input
                                                                        accept="/*"
                                                                        disabled={
                                                                            order.status !==
                                                                            'Accepted'
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'none',
                                                                        }}
                                                                        id={`certificates-${index}-certificate_attachment_upload`}
                                                                        type="file"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const file =
                                                                                e
                                                                                    .target
                                                                                    .files[0]

                                                                            setValue(
                                                                                `certificates[${index}].certificate_attachment_name`,
                                                                                file.name
                                                                            )
                                                                            setValue(
                                                                                `certificates[${index}].certificate_attachment`,
                                                                                file
                                                                            )
                                                                        }}
                                                                    />
                                                                    <label
                                                                        htmlFor={`certificates-${index}-certificate_attachment_upload`}
                                                                    ></label>
                                                                </>
                                                            )}
                                                        />
                                                        {
                                                            item.certificate_attachment_name
                                                        }
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '2rem',
                                                        marginTop: '1rem',
                                                        marginBottom: '2rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Comment
                                                    <TextAreaCmp
                                                        name={`certificates[${index}].certificate_comment`}
                                                        type="text"
                                                        control={control}
                                                        disabled={
                                                            order.status !==
                                                            'Accepted'
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        gap: '1rem',
                                                    }}
                                                >
                                                    <div>
                                                        <Link
                                                            href={
                                                                item.certificate_attachment
                                                            }
                                                            target="_blank"
                                                            style={{
                                                                textDecoration:
                                                                    'none',
                                                            }}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="buttonColor"
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    gap: '0.5rem',
                                                                    borderRadius: 10,
                                                                    fontSize:
                                                                        '12px',
                                                                    minWidth:
                                                                        '160px',
                                                                    padding:
                                                                        '13px 20px',
                                                                }}
                                                            >
                                                                <FaRegFilePdf
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                    }}
                                                                />
                                                                View Certificate
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <input
                                                                type="file"
                                                                // ref={fileInputRef}
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                color="buttonColor"
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    gap: '0.5rem',
                                                                    borderRadius: 10,
                                                                    fontSize:
                                                                        '12px',
                                                                    minWidth:
                                                                        '160px',
                                                                    padding:
                                                                        '13px 20px',
                                                                }}
                                                                // onClick={handleClick}
                                                            >
                                                                <IoMdAttach
                                                                    style={{
                                                                        fontSize:
                                                                            '16px',
                                                                    }}
                                                                />
                                                                Attach File
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            {order.status !== 'Accepted' ||
                                (dayDifference <= 8 && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            marginRight: '1rem',
                                        }}
                                    >
                                        <ButtonCmp
                                            title={'Submit'}
                                            onClick={() => {
                                                handleSubmit(onSubmit)()
                                            }}
                                            disabled={
                                                order.status !== 'Accepted'
                                            }
                                        ></ButtonCmp>
                                    </div>
                                ))}
                        </div>
                    </div>
                </Card>
            </Box>
        </>
    )
}

export default Detail
