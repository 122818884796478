import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { IconCmp } from '..'
import { BiEdit, CheckTick, RiDeleteBin6Line } from '../../Assets'
import useAlert from '../../Hooks/useAlert/useAlert'
import { AcceptMileStoneAction } from '../../Store/Actions/BuildBidsJobAction'
import { DeleteMileStoneAction } from '../../Store/Actions/BuildBidsMyBids'
import styles from './MileStoneAccordion.module.css'

const MileStoneAccordion = ({
    milestoneName,
    status,
    formVisible,
    setFormVisible,
    milestone,
    projetslug,
    projectOwner,
    setEditMileStone,
    editMileStone,
    setMileStoneIndexNumber,
    indexNum,
    setIsStatusShow,
}) => {
    const [accEditJob, accSetEditJob] = useState(false)

    const { setAlert } = useAlert()

    const dispatch = useDispatch()
    // const selector = useSelector();

    const handleEdit = (e) => {
        e.stopPropagation()
        // accSetEditJob(!accEditJob);
        setFormVisible(true)
        setEditMileStone(milestone)
        setMileStoneIndexNumber(indexNum)
        setIsStatusShow(true)
    }

    const deleteMilestone = (e) => {
        e.stopPropagation()
        dispatch(DeleteMileStoneAction(projetslug, milestone?.id, setAlert))
    }

    const mileStoneAccepted = (e, mileStoneStatus) => {
        e.stopPropagation()
        console.log('hello')
        dispatch(
            AcceptMileStoneAction(
                projetslug,
                milestone?.id,
                setAlert,
                mileStoneStatus
            )
        )
    }

    return (
        <div>
            <Accordion
                sx={{
                    padding: '10px 30px',
                    border: 'none',
                    borderBottom: '1px solid var(--borderColor)',
                    borderRadius: '0 !important',
                }}
                // expanded={true}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon
                            sx={{
                                pointerEvents: 'auto',
                            }}
                        />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        pointerEvents: 'none',
                    }}
                >
                    <Box className={styles.accordionHeader}>
                        <Typography
                            fontWeight={'bold'}
                            variant="h6"
                            //   sx={{ width: "100%" }}
                        >
                            {milestoneName}
                        </Typography>
                        <MileStoneStatus status={status} />
                    </Box>

                    {!editMileStone &&
                        !projectOwner &&
                        status !== 'Delete Requested' &&
                        status !== 'Changes Requested' &&
                        status !== 'Complete Requested' && (
                            <Box className={styles.accordionBtns}>
                                {/* {!accEditJob && ( */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        pointerEvents: 'auto',
                                    }}
                                    onClick={(e) => deleteMilestone(e)}
                                >
                                    <Typography fontSize={'small'}>
                                        Delete Job
                                    </Typography>
                                    <Box className={styles.iconBox}>
                                        <RiDeleteBin6Line />
                                    </Box>
                                </Box>
                                {/* )} */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        pointerEvents: 'auto',
                                    }}
                                    onClick={(e) => handleEdit(e)}
                                >
                                    <Typography
                                        fontSize={'small'}
                                        sx={{ ml: '10px' }}
                                    >
                                        Edit Job
                                    </Typography>
                                    <Box className={styles.iconBox}>
                                        <BiEdit />
                                    </Box>
                                </Box>
                            </Box>
                        )}

                    {/* ///Milestone Accept || Delete Req || Edit Req */}
                    {projectOwner && status === 'Created' ? (
                        <Box className={styles.accordionBtns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    pointerEvents: 'auto',
                                }}
                                onClick={(e) =>
                                    mileStoneAccepted(e, 'Accepted')
                                }
                            >
                                <Typography
                                    fontSize={'small'}
                                    sx={{ ml: '10px' }}
                                >
                                    Accept Milestone
                                </Typography>
                                <Box className={styles.iconBox}>
                                    <BiEdit />
                                </Box>
                            </Box>
                        </Box>
                    ) : projectOwner && status === 'Delete Requested' ? (
                        <Box className={styles.accordionBtns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    pointerEvents: 'auto',
                                }}
                                onClick={(e) =>
                                    mileStoneAccepted(e, 'Accepted')
                                }
                            >
                                <Typography
                                    fontSize={'small'}
                                    sx={{
                                        ml: '10px',
                                        color: 'var(--redColor)',
                                    }}
                                >
                                    Accept Delete Milestone
                                </Typography>
                                <Box
                                    className={styles.iconBox}
                                    sx={{
                                        ':hover': {
                                            backgroundColor:
                                                'var(--redColor) !important ',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    <BiEdit />
                                </Box>
                            </Box>
                        </Box>
                    ) : projectOwner && status === 'Changes Requested' ? (
                        <Box className={styles.accordionBtns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    pointerEvents: 'auto',
                                }}
                                onClick={(e) =>
                                    mileStoneAccepted(e, 'Accepted')
                                }
                            >
                                <Typography
                                    fontSize={'small'}
                                    sx={{ ml: '10px' }}
                                >
                                    Accept Edit Milestone Request
                                </Typography>
                                <Box className={styles.iconBox}>
                                    <BiEdit />
                                </Box>
                            </Box>
                        </Box>
                    ) : projectOwner && status === 'Complete Requested' ? (
                        <Box className={styles.accordionBtns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    pointerEvents: 'auto',
                                }}
                                onClick={(e) =>
                                    mileStoneAccepted(e, 'Accepted')
                                }
                            >
                                <Typography
                                    fontSize={'small'}
                                    sx={{ ml: '10px' }}
                                >
                                    Accept Complete Milestone Request
                                </Typography>
                                <Box className={styles.iconBox}>
                                    <BiEdit />
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Box className={styles.jobName}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconCmp
                style={{
                  backgroundColor: "var(--lightIcon)",
                  width: "30px",
                  height: "30px",
                }}
              >
                <img width="14px" src={AccountAlert} alt="" />
              </IconCmp>
              <Typography fontWeight={"500"} fontSize={"medium"}>
                Plumbing Contractor
              </Typography>
            </Box>
          </Box> */}
                    <Box sx={{ my: 2 }} className={styles.mileStone_details}>
                        <Box>
                            <Typography
                                fontSize={'small'}
                                sx={{ color: 'var(--textColor)' }}
                            >
                                Milestone Start Date:
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                                {milestone?.start_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                fontSize={'small'}
                                sx={{ color: 'var(--textColor)' }}
                            >
                                Milestone Completion Date:
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                                {milestone?.end_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                fontSize={'small'}
                                sx={{ color: 'var(--textColor)' }}
                            >
                                Payment Release Date:
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                                {milestone?.end_date}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                fontSize={'small'}
                                sx={{ color: 'var(--textColor)' }}
                            >
                                Milestone Amount:
                            </Typography>
                            <Typography
                                fontWeight={'bold'}
                                sx={{ color: 'var(--primary-color)', mt: 1 }}
                            >
                                ${milestone?.amount}
                            </Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const MileStoneStatus = ({ status }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mx: '10px',
            }}
            className={styles.projectStatus}
        >
            {status === 'Completed' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--green)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--green)' }}
                    >
                        Completed
                    </Typography>
                </>
            )}
            {status === 'Active' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--primary-color)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--priamry-color)' }}
                    >
                        Active
                    </Typography>
                </>
            )}
            {status === 'Accepted' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--lightIcon)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--milestoneText)' }}
                    >
                        Not Started
                    </Typography>
                </>
            )}
            {status === 'Created' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--lightIcon)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--milestoneText)' }}
                    >
                        Not Accepted
                    </Typography>
                </>
            )}
            {status === 'Rejected' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--lightIcon)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--milestoneText)' }}
                    >
                        Rejected
                    </Typography>
                </>
            )}
            {status === 'Delete Requested' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--redColor)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--redColor)' }}
                    >
                        Delete Request
                    </Typography>
                </>
            )}
            {status === 'Changes Requested' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--primary-color)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--primary-color)' }}
                    >
                        MileStone Edit Request
                    </Typography>
                </>
            )}
            {status === 'Complete Requested' && (
                <>
                    <IconCmp
                        style={{
                            backgroundColor: 'var(--primary-color)',
                            width: '30px',
                            height: '30px',
                        }}
                    >
                        <img width="14px" src={CheckTick} alt="" />
                    </IconCmp>
                    <Typography
                        fontWeight={'bold'}
                        fontSize={'small'}
                        sx={{ color: 'var(--primary-color)' }}
                    >
                        MileStone Complete Request
                    </Typography>
                </>
            )}
        </Box>
    )
}

export default MileStoneAccordion
