import { getUserToken } from '../../../Utils/LoginAuth'
import styles from './OrderListing.module.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { BodyLayout, ButtonCmp, LoaderCmp } from '../../../Components'
import { Grid, Typography, Box } from '@mui/material'

import { BASE_URL } from '../../../Utils/response'

import { useNavigate, useParams } from 'react-router-dom'

const OrderListing = () => {
    const [orders, setOrders] = useState([])
    const navigate = useNavigate()

    const { status } = useParams()

    const getHeader = () => {
        return {
            Authorization: 'Bearer ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }

    useEffect(() => {
        axios
            .get(`${BASE_URL}/manufacturer-orders?status=${status}`, {
                headers: getHeader(),
            })
            .then((response) => {
                setOrders(response.data.data)
            })
    }, [status])

    return (
        <BodyLayout className={styles.mainBox}>
            <Box sx={{ padding: '2rem' }}>
                <section className={styles.myJobHeader}>
                    <div>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            Your Order
                        </Typography>
                    </div>
                </section>
                <section className={styles.productWrapper}>
                    {orders.length === 0 ? (
                        <LoaderCmp />
                    ) : (
                        orders?.records?.map((order, index) => {
                            return (
                                <Grid
                                    container
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        my: '20px',
                                        p: '20px',
                                        borderBottom: '2px solid #ECECEC',

                                        cursor: 'pointer',
                                    }}
                                >
                                    <Grid item lg={4}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '10px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <section>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--textColor)',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Order No.
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {order?.order_number}
                                                </Typography>
                                            </section>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Box>
                                            <section>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--textColor)',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Payment Status
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {order?.payment_status}
                                                </Typography>
                                            </section>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3}>
                                        {' '}
                                        <Typography
                                            sx={{
                                                color: 'var(--textColor)',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Order Status
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {order?.order_status}
                                        </Typography>{' '}
                                    </Grid>
                                    <Grid item lg={2}>
                                        {' '}
                                        <Box>
                                            <section>
                                                <ButtonCmp
                                                    style={{
                                                        width: 'max-content',
                                                        padding: '10px 15px',
                                                    }}
                                                    title="View"
                                                    onClick={() =>
                                                        navigate(
                                                            `../orders/detail/${order?.order_number}`,
                                                            {
                                                                state: {
                                                                    order: order,
                                                                },
                                                            }
                                                        )
                                                    }
                                                />
                                            </section>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        })
                    )}
                </section>
                {orders?.pagination?.total > 15 && (
                    <section className={styles.paginationWrapper}>
                        {/* <PaginationRounded
							handlePaginate={handlePaginate}
							pagination={userProductsdata && userProductsdata?.pagination}
							currentPage={currentPage}
						/> */}
                    </section>
                )}
            </Box>
        </BodyLayout>
    )
}

export default OrderListing
