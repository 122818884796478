import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { IconCmp } from '..'

import { useNavigate } from 'react-router-dom'
import {
    ChatIcon,
    CheckTick,
    greenCheck,
    LocationOnOutlinedIcon,
    phoneIcon,
} from '../../Assets'
import useAlert from '../../Hooks/useAlert/useAlert'
import { AcceptEndContractAction } from '../../Store/Actions/BuildBidsJobAction'
import styles from './AwardedJobCard.module.css'

const AwardedJobCard = ({ onClick, awardedJobData, messageNotShow }) => {
    const {
        label,
        address,
        zipcode,
        project_start_date,
        proposals,
        state,
        job_completion_date,
        job_awarded_date,
        slug,
    } = awardedJobData

    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    const jobCompleteHandler = (e) => {
        e.stopPropagation()
        dispatch(AcceptEndContractAction(slug, setAlert))
    }

    const navigate = useNavigate()
    const sendMessage = () => {
        navigate('/chat', {
            state: {
                userLocation: proposals && proposals[0]?.user,
            },
        })
    }

    return (
        <section className={styles.card_wrapper} onClick={onClick}>
            <section className={styles.project_wrappper}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 'bold' }}
                            className={styles.bidName}
                        >
                            {label}
                        </Typography>
                    </Box>
                    <Box sx={{ margin: '5px 0 ' }}>
                        <Typography
                            sx={{
                                fontSize: 'small',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <LocationOnOutlinedIcon
                                fontSize="small"
                                sx={{
                                    color: 'var(--primary-color)',
                                    marginLeft: '-5px',
                                }}
                            />
                            {address}, {state?.label}, {zipcode}
                        </Typography>
                    </Box>
                </Box>

                <Box className={styles.projectDate}>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Start Date:
                        </Typography>
                        <Typography fontSize="14px">
                            {project_start_date}
                        </Typography>
                    </section>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Completition Date:
                        </Typography>
                        <Typography fontSize="14px">
                            {job_completion_date}
                        </Typography>
                    </section>
                </Box>
            </section>

            {/* ///company details */}
            <section className={styles.company_wrappper}>
                <Box
                    sx={{ display: ' flex', justifyContent: 'space-between' }}
                    className={styles.companyName}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                {proposals && proposals[0]?.user?.label}
                            </Typography>
                        </Box>
                        <Box sx={{ margin: '5px 0 ' }}>
                            <Typography
                                sx={{
                                    fontSize: 'small',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LocationOnOutlinedIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'var(--primary-color)',
                                        marginLeft: '-5px',
                                    }}
                                />
                                {proposals && proposals[0]?.user?.street_address
                                    ? proposals[0]?.user?.street_address
                                    : 'New Orleans, LA, 70115'}
                            </Typography>
                        </Box>
                    </Box>

                    {proposals &&
                    proposals[0]?.status === 'Complete Requested' ? (
                        <div
                            className={styles.proposalStatus}
                            onClick={(e) => {
                                jobCompleteHandler(e)
                            }}
                        >
                            <Typography
                                fontWeight={'bold'}
                                fontSize={'small'}
                                sx={{ color: 'var(--primary-color)' }}
                            >
                                Job Complete Request
                            </Typography>
                            <IconCmp
                                style={{
                                    backgroundColor: 'var(--primary-color)',
                                    width: '30px',
                                    height: '30px',
                                }}
                            >
                                <img width="14px" src={CheckTick} alt="" />
                            </IconCmp>
                        </div>
                    ) : proposals && proposals[0]?.status === 'Completed' ? (
                        <div>
                            <img src={greenCheck} alt="" />
                        </div>
                    ) : null}
                </Box>

                <div className={styles.projectDate}>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Job Awarded Date:
                        </Typography>
                        <Typography fontSize="14px">
                            {job_awarded_date}
                        </Typography>
                    </section>
                    <section>
                        <Typography
                            fontSize="small"
                            sx={{ color: 'var(--textColor)' }}
                        >
                            Contact Details:
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography
                                fontSize="14px"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <img src={phoneIcon} alt="" />

                                {proposals && proposals[0]?.user?.phone
                                    ? proposals[0]?.user?.phone
                                    : '+1 123 123 1234'}
                            </Typography>
                            {!messageNotShow && (
                                <Typography
                                    fontSize="small"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        sendMessage()
                                    }}
                                >
                                    <img src={ChatIcon} alt="" />
                                    Message
                                </Typography>
                            )}
                        </Box>
                    </section>
                </div>
            </section>
        </section>
    )
}

export default AwardedJobCard
