import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const ITEM_HEIGHT = 48

export default function PDFFileThreeDots({
    editPdfFunction,
    renamePDF,
    pdfDelete,
}) {
    const options = [
        {
            onClick: renamePDF,
            label: 'Rename',
        },
        {
            onClick: editPdfFunction,
            label: 'Edit',
        },
        // {
        //   onClick: () => {},
        //   label: "Extract Pages",
        // },
        {
            onClick: pdfDelete,
            label: 'Remove',
        },
    ]

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (action) => {
        // if (action === "Cancel request") {
        //   removeConnectionRequest();
        // }
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.label}
                        selected={option === 'Pyxis'}
                        onClick={() => handleClose(option.onClick())}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}
