import { getToken } from '@firebase/messaging'
import { messaging } from '../Config/firebase'

const notificationRequestToken = () => {
    return new Promise((resolve, reject) => {
        function requestPermission() {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.')
                    getToken(messaging, {
                        vapidKey:
                            'BB_HVaKpVTQvjMw2b2xHTq0yhdRqcaih3FbdsPSszFT55FWD8haZEis2nlsM-nwxwfKCgL7nVG6ax97dcZTt4lo',
                    })
                        .then((token) => {
                            resolve(token)
                        })
                        .catch((err) => {
                            reject(err)
                        })
                } else {
                    console.log('Notification permission denied.')
                    reject('Notification permission denied.')
                }
            })
        }
        requestPermission()
    })
}

export default notificationRequestToken
