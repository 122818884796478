import React from 'react'
import styles from './ProfileCompletionForm.module.css'
import { AccountAlert, CloseIcon } from '../../Assets'
import { Box, Grid, Typography } from '@mui/material'
import { ButtonCmp, DropDownCmp, WhiteButtonCmp } from '../../Components'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import {
    GetLicensedServicesAction,
    GetOtherServicesAction,
} from '../../Store/Actions/GetActions'
import { useDispatch, useSelector } from 'react-redux'
import {
    EmptyProfileWizardState,
    ProfileWizardAction,
} from '../../Store/Actions/ProfileAction'
import useAlert from '../../Hooks/useAlert/useAlert'

const ServicesForm = ({ setWhichForm, setBarPercent, setServicesComplete }) => {
    const navigate = useNavigate()
    const [servicesArr, setServicesArr] = useState([])
    const [otherServicesArr, setOtherServicesArr] = useState([])

    const { setAlert } = useAlert()
    const dispatch = useDispatch()
    const { licensedServicesdata } = useSelector(
        (state) => state.LicensedServicesReducer
    )
    const { otherServicesdata } = useSelector(
        (state) => state.OtherServicesReducer
    )

    const { control, handleSubmit, watch, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            licensedService: '',
            otherService: '',
        },
    })

    const licensedServiceState = watch('licensedService')
    const otherServiceState = watch('otherService')
    const { profileWizarddata } = useSelector(
        (state) => state.ProfielWizardReducer
    )
    useEffect(() => {
        if (licensedServiceState) {
            servicesArr.push(licensedServiceState)
            setServicesArr([...new Set(servicesArr)])
            setValue('licensedService', '')
        }
    }, [licensedServiceState])

    useEffect(() => {
        if (otherServiceState) {
            otherServicesArr.push(otherServiceState)
            setOtherServicesArr([...new Set(otherServicesArr)])
            setValue('otherService', '')
        }
    }, [otherServiceState])

    useEffect(() => {
        dispatch(GetLicensedServicesAction())
        dispatch(GetOtherServicesAction())
    }, [])

    const onSubmit = (obj) => {
        const objSent = {
            servicesArr,
            otherServicesArr,
        }
        dispatch(ProfileWizardAction(objSent, 'services', setAlert))

        // setWhichForm("socialMedia");
        // setBarPercent("75%");
        // setServicesComplete(true);
    }

    const removeService = (ind, whichService) => {
        if (whichService === 'licensedService') {
            servicesArr.splice(ind, 1)
            setServicesArr([...servicesArr])
        } else {
            otherServicesArr.splice(ind, 1)
            setOtherServicesArr([...otherServicesArr])
        }
    }

    useEffect(() => {
        if (profileWizarddata) {
            setWhichForm('socialMedia')
            setBarPercent('75%')
            setServicesComplete(true)
            dispatch(EmptyProfileWizardState())
        }
    }, [profileWizarddata])

    return (
        <section className={styles.profileInfo}>
            <div>
                <Typography noWrap variant="h5" fontWeight={'bold'}>
                    Services
                </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <section className={styles.servicesLicensed}>
                    <Typography noWrap variant="h6" fontWeight={'bold'}>
                        Licensed Service
                    </Typography>
                    <Box sx={{ my: '50px' }}>
                        <Grid container>
                            <Grid item lg={4}>
                                <DropDownCmp
                                    name="licensedService" //imporant
                                    control={control} //imporant
                                    label="Select Services"
                                    options={
                                        licensedServicesdata &&
                                        licensedServicesdata
                                    }
                                    disableClearable
                                    freeSolo={false}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* ///tags// */}
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        {servicesArr.map((service, ind) => {
                            return (
                                <section
                                    key={service.id}
                                    className={styles.scrollTabsBar}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                        }}
                                    >
                                        <img src={AccountAlert} alt="" />
                                        {service.label}
                                    </Box>
                                    <Box>
                                        <CloseIcon
                                            sx={{
                                                cursor: 'pointer',
                                                fontSize: '18px',
                                            }}
                                            onClick={() =>
                                                removeService(
                                                    ind,
                                                    'licensedService'
                                                )
                                            }
                                        />
                                    </Box>
                                </section>
                            )
                        })}
                    </Box>
                </section>

                {/* ////other services// */}
                <section className={styles.servicesLicensed}>
                    <Typography noWrap variant="h6" fontWeight={'bold'}>
                        Other Service
                    </Typography>
                    <Box sx={{ my: '50px' }}>
                        <Grid container>
                            <Grid item lg={4}>
                                <DropDownCmp
                                    name="otherService" //imporant
                                    control={control} //imporant
                                    label="Select Services"
                                    options={
                                        otherServicesdata && otherServicesdata
                                    }
                                    disableClearable
                                    freeSolo={false}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* ///tags// */}
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        {otherServicesArr.map((service, ind) => {
                            return (
                                <section
                                    key={service.id}
                                    className={styles.scrollTabsBar}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                        }}
                                    >
                                        <img src={AccountAlert} alt="" />
                                        {service.label}
                                    </Box>
                                    <Box>
                                        <CloseIcon
                                            sx={{
                                                cursor: 'pointer',
                                                fontSize: '18px',
                                            }}
                                            onClick={() =>
                                                removeService(
                                                    ind,
                                                    'otherService'
                                                )
                                            }
                                        />
                                    </Box>
                                </section>
                            )
                        })}
                    </Box>
                </section>
                {/* ////btn */}
                <section className={styles.formBtns}>
                    <WhiteButtonCmp
                        title="Cancel"
                        onClick={() => {
                            navigate('/profile')
                        }}
                    />
                    <ButtonCmp
                        title="Skip Step"
                        onClick={() => {
                            setWhichForm('socialMedia')
                        }}
                    />
                    <ButtonCmp title="Save and Continue" type="submit" />
                </section>
            </form>
        </section>
    )
}

export default ServicesForm
