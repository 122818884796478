import IconButton from '@mui/material/IconButton'
import react from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styles from './ModalCard.module.css'
import { HighlightOffOutlinedIcon, ReportOutlinedIcon } from '../../Assets'
import { ButtonCmp, WhiteButtonCmp } from '..'

export default function ModalCard({
    open,
    setOpen,
    projectName,
    companyName,
    onClick,
}) {
    //   const [open, setOpen] = React.useState(true);

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={styles.modalWrapper}>
                        {/* ///header// */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid var(--borderColor)',
                                padding: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <ReportOutlinedIcon
                                    sx={{ color: 'var(--redColor)' }}
                                />
                                <Typography fontSize={15}>
                                    Are You Sure you want to award
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{
                                        color: 'black',
                                    }}
                                    onClick={handleClose}
                                >
                                    <HighlightOffOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        {/* ///body/// */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontSize={25} sx={{ mt: '30px' }}>
                                {projectName}
                            </Typography>
                            <Typography
                                sx={{
                                    margin: '10px 0',
                                    color: 'Var(--textColor)',
                                }}
                            >
                                To
                            </Typography>
                            <Typography fontSize={25} sx={{ mb: '30px' }}>
                                {companyName}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    mt: '20px ',
                                    mb: ' 30px ',
                                }}
                            >
                                <WhiteButtonCmp
                                    title="No"
                                    onClick={() => {
                                        handleClose()
                                    }}
                                />
                                <ButtonCmp title="Yes" onClick={onClick} />
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
