import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import PlaylistAddCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCircleOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Logo from '../../Assets/Images/logo.png'
import BuildLinkLogo from '../../Assets/Images/BuildLinkCompleteLogo.png'
import BuildStudioLogo from '../../Assets/Images/BuildStudiologo-gold.png'
import { Stack, Typography } from '@mui/material'

import styles from './SideBar.module.css'
import {
    Briefcase,
    BriefcasePlus,
    CheckBox,
    MultiCheckBox,
    TrashSvg,
    GavelSvg,
    ClockCheckSvg,
    WhishListSvg,
    ProConnectionSvg,
    NewConnectionSvg,
    ConnectionRequestsSvg,
} from '../../Assets'
import useGetUserPermissions from '../../Hooks/getUserPermissions'
import useGetBusinessArea from '../../Hooks/getBusinessArea'
import IconButton from '@mui/material/IconButton'

//NEW SIDEBAR
const drawerWidth = 250

const SideBarNav = ({ sideBarClose }) => {
    const userPermissions = useGetUserPermissions()
    const businessArea = useGetBusinessArea()

    return (
        <Box sx={{ width: drawerWidth }}>
            {businessArea &&
                businessArea === 'buildlink' &&
                userPermissions &&
                userPermissions.includes('Pro Connection - Connections') && (
                    <BuildLinkProConnections sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildlink' &&
                userPermissions &&
                userPermissions.includes(
                    'Pro Connection - My Project Gallery'
                ) && <BuildLinkMyProjectGallery sideBarClose={sideBarClose} />}

            {businessArea &&
                businessArea === 'buildbid' &&
                userPermissions &&
                userPermissions.includes('Build Bid - Jobs') && (
                    <BuildBidJobs sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildbid' &&
                userPermissions &&
                userPermissions.includes('Build Bid - My Bids') && (
                    <BuildBidMyBids sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildstudio' &&
                userPermissions &&
                userPermissions.includes('Build Studio - Jobs') && (
                    <BuildStudioOptions sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildshop' &&
                userPermissions &&
                userPermissions.includes('Shop - Products') && (
                    <BuildShopProducts sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildshop' &&
                userPermissions &&
                userPermissions.includes('Shop - Orders') && (
                    <BuildShopOrders sideBarClose={sideBarClose} />
                )}

            {businessArea &&
                businessArea === 'buildinsurance' &&
                userPermissions &&
                userPermissions.includes('Linksurance - Orders') && (
                    <InsuranceOptions sideBarClose={sideBarClose} />
                )}
        </Box>
    )
}

const BuildBidJobs = ({ sideBarClose }) => {
    let navigate = useNavigate()
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <NavLink
                to="/buildbid/jobs"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <Briefcase />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Jobs" />
                </ListItemButton>
            </NavLink>

            <List component="div">
                {[
                    {
                        icon: <BriefcasePlus />,
                        text: 'Create New Job',
                        to: 'buildbid/createjob',
                    },
                    {
                        icon: <CheckBox />,
                        text: 'Awarded Jobs',
                        to: 'buildbid/awardedjob',
                    },
                    {
                        icon: <MultiCheckBox />,
                        text: 'Completed Jobs',
                        to: 'buildbid/completedjob',
                    },
                    {
                        icon: <TrashSvg />,
                        text: 'Trash',
                        to: 'buildbid/trashjob',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            // onClick={list.onClick}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                {list.icon}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
        </List>
    )
}

const BuildBidMyBids = ({ sideBarClose }) => {
    let navigate = useNavigate()
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {' '}
            <NavLink
                to="/buildbid/mybids"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <GavelSvg />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="My Bids" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
            </NavLink>
            {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
            <List component="div">
                {[
                    {
                        icon: <ClockCheckSvg />,
                        text: 'Applied Bids',
                        onClick: () => navigate('/'),
                        to: 'buildbid/appliedprojects',
                    },
                    {
                        icon: <CheckBox />,
                        text: 'Awarded Bids',
                        onClick: () => navigate('/about'),
                        to: 'buildbid/awardedproject',
                    },
                    {
                        icon: <MultiCheckBox />,
                        text: 'Completed Bids',
                        onClick: () => navigate('/'),
                        to: 'buildbid/completedproject',
                    },
                    {
                        icon: <WhishListSvg />,
                        text: 'Wish list',
                        onClick: () => navigate('/'),
                        to: 'buildbid/wishlist',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            key={index}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                {list.icon}
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
            {/* </Collapse> */}
        </List>
    )
}

const BuildLinkProConnections = ({ sideBarClose }) => {
    let navigate = useNavigate()

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {/* /// Jobs Listing// */}

            <NavLink
                to="/buildlink/proconnection"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <ProConnectionSvg />
                    </ListItemIcon>
                    <ListItemText
                        className={styles.list}
                        primary="Pro Connection"
                    />
                </ListItemButton>
            </NavLink>
            <List component="div">
                {[
                    {
                        icon: <NewConnectionSvg />,
                        text: 'Search Connection',
                        onClick: () => navigate('/about'),
                        to: 'buildlink/searchproconnection',
                    },
                    {
                        icon: <ConnectionRequestsSvg />,
                        text: 'Pending Requests',
                        onClick: () => navigate('/'),
                        to: 'buildlink/pendingrequests',
                    },
                    {
                        icon: <ConnectionRequestsSvg />,
                        text: 'Recevied Requests',
                        onClick: () => navigate('/'),
                        to: 'buildlink/receviedrequests',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            key={index}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                <ListItemIcon sx={{ minWidth: '37px' }}>
                                    {list.icon}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
        </List>
    )
}

const BuildLinkMyProjectGallery = ({ sideBarClose }) => {
    let navigate = useNavigate()

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <NavLink
                to="/buildlink/myprojectgallery"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <Briefcase />
                    </ListItemIcon>
                    <ListItemText
                        className={styles.list}
                        primary="My Project Gallery"
                    />
                </ListItemButton>
            </NavLink>
            <List component="div">
                {[
                    {
                        icon: <BriefcasePlus />,
                        text: 'Add New Project',
                        onClick: () => navigate('/'),
                        to: 'buildlink/addnewproject',
                    },
                    {
                        icon: <TrashSvg />,
                        text: 'Trash',
                        onClick: () => navigate('/'),
                        to: 'buildlink/trashgallery',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            key={index}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                <ListItemIcon sx={{ minWidth: '37px' }}>
                                    {list.icon}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
        </List>
    )
}
const BuildStudioOptions = ({ sideBarClose }) => {
    let navigate = useNavigate()

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <NavLink
                to="/buildstudio/jobs"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <Briefcase />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Jobs" />
                </ListItemButton>
            </NavLink>
            <List component="div">
                {[
                    {
                        icon: <BriefcasePlus />,
                        text: 'Create New Project',
                        // onClick: () => navigate("/createjob"),
                        to: 'buildstudio/createnewproject',
                    },
                    {
                        icon: <TrashSvg />,
                        text: 'Trash',
                        onClick: () => navigate('/about'),
                        to: 'buildstudio/trash',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            // onClick={list.onClick}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                <ListItemIcon sx={{ minWidth: '37px' }}>
                                    {list.icon}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
        </List>
    )
}

const BuildShopProducts = ({ sideBarClose }) => {
    let navigate = useNavigate()

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {/* /// Jobs Listing// */}

            <NavLink
                to="/buildshop/viewproduct"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <Inventory2OutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Products" />
                </ListItemButton>
            </NavLink>
            <List component="div">
                {[
                    // {
                    //   icon: <BriefcasePlus />,
                    //   text: "View Products",
                    //   // onClick: () => navigate("/createjob"),
                    //   to: "/",
                    // },
                    // {
                    //   icon: <EyeSvg />,
                    //   text: "Edit Product",
                    //   onClick: () => navigate("/about"),
                    //   to: "/",
                    // },
                    {
                        icon: (
                            <ControlPointOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        ),
                        text: 'Add A New Product',
                        onClick: () => navigate('/'),
                        to: 'buildshop/addproduct',
                    },
                    {
                        icon: (
                            <PlaylistAddCircleOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        ),
                        text: 'Import Products',
                        onClick: () => navigate('/'),
                        to: 'buildshop/importproduct',
                    },
                    {
                        icon: <TrashSvg />,
                        text: 'Trash',
                        onClick: () => navigate('/'),
                        to: 'buildshop/trashedproducts',
                    },
                ].map((list, index) => (
                    <NavLink
                        to={`/${list.to}`}
                        className={({ isActive }) =>
                            isActive ? styles.activeClassName : undefined
                        }
                        key={index}
                    >
                        <ListItemButton
                            className={styles.listBtn}
                            sx={{ pl: sideBarClose ? 4 : 2 }}
                            // onClick={list.onClick}
                        >
                            <ListItemIcon sx={{ minWidth: '37px' }}>
                                <ListItemIcon sx={{ minWidth: '37px' }}>
                                    {list.icon}
                                </ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                className={styles.list}
                                primary={list.text}
                            />
                        </ListItemButton>
                    </NavLink>
                ))}
            </List>
        </List>
    )
}

const BuildShopOrders = ({ sideBarClose }) => {
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {/* /// Jobs Listing// */}

            <NavLink to="buildshop/orders/pending">
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <BallotOutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Orders" />
                </ListItemButton>
            </NavLink>
            <List>
                <NavLink
                    to={`buildshop/orders/pending`}
                    className={({ isActive }) =>
                        isActive ? styles.activeClassName : undefined
                    }
                >
                    <ListItemButton
                        className={styles.listBtn}
                        sx={{ pl: sideBarClose ? 4 : 2 }}
                        // onClick={list.onClick}
                    >
                        <ListItemIcon sx={{ minWidth: '37px' }}>
                            <TimerOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            className={styles.list}
                            primary={'Pending Orders'}
                        />
                    </ListItemButton>
                </NavLink>

                <NavLink
                    to={`buildshop/orders/shipped`}
                    className={({ isActive }) =>
                        isActive ? styles.activeClassName : undefined
                    }
                >
                    <ListItemButton
                        className={styles.listBtn}
                        sx={{ pl: sideBarClose ? 4 : 2 }}
                        // onClick={list.onClick}
                    >
                        <ListItemIcon sx={{ minWidth: '37px' }}>
                            <LocalShippingOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            className={styles.list}
                            primary={'Shipped Orders'}
                        />
                    </ListItemButton>
                </NavLink>

                <NavLink
                    to={`buildshop/orders/delivered`}
                    className={({ isActive }) =>
                        isActive ? styles.activeClassName : undefined
                    }
                >
                    <ListItemButton
                        className={styles.listBtn}
                        sx={{ pl: sideBarClose ? 4 : 2 }}
                        // onClick={list.onClick}
                    >
                        <ListItemIcon sx={{ minWidth: '37px' }}>
                            <MeetingRoomOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            className={styles.list}
                            primary={'Delivered Orders'}
                        />
                    </ListItemButton>
                </NavLink>

                <NavLink
                    to={`buildshop/orders/completed`}
                    className={({ isActive }) =>
                        isActive ? styles.activeClassName : undefined
                    }
                >
                    <ListItemButton
                        className={styles.listBtn}
                        sx={{ pl: sideBarClose ? 4 : 2 }}
                    >
                        <ListItemIcon sx={{ minWidth: '37px' }}>
                            <AssignmentTurnedInOutlinedIcon
                                sx={{ color: 'black' }}
                                fontSize="small"
                            />
                        </ListItemIcon>
                        <ListItemText
                            className={styles.list}
                            primary={'Completed Orders'}
                        />
                    </ListItemButton>
                </NavLink>
            </List>
        </List>
    )
}

const InsuranceOptions = ({ sideBarClose }) => {
    let navigate = useNavigate()

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {/* /// Jobs Listing// */}

            <NavLink
                to="/buildinsurance/orders/general"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton className={styles.listBtn}>
                    <ListItemIcon>
                        <BallotOutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Orders" />
                </ListItemButton>
            </NavLink>
            <NavLink
                to="/buildinsurance/orders/Pending"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton
                    className={styles.listBtn}
                    sx={{ pl: sideBarClose ? 4 : 2 }}
                >
                    <ListItemIcon>
                        <AnnouncementOutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText
                        className={styles.list}
                        primary="New Orders"
                    />
                </ListItemButton>
            </NavLink>
            <NavLink
                to="/buildinsurance/orders/Accepted"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton
                    className={styles.listBtn}
                    sx={{ pl: sideBarClose ? 4 : 2 }}
                >
                    <ListItemIcon>
                        <TimerOutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText
                        className={styles.list}
                        primary="In Development"
                    />
                </ListItemButton>
            </NavLink>
            <NavLink
                to="/buildinsurance/orders/Completed"
                className={({ isActive }) =>
                    isActive ? styles.activeClassName : undefined
                }
            >
                <ListItemButton
                    className={styles.listBtn}
                    sx={{ pl: sideBarClose ? 4 : 2 }}
                >
                    <ListItemIcon>
                        <AssignmentTurnedInOutlinedIcon
                            sx={{ color: 'black' }}
                            fontSize="small"
                        />
                    </ListItemIcon>
                    <ListItemText className={styles.list} primary="Completed" />
                </ListItemButton>
            </NavLink>
        </List>
    )
}

const SideBarHeader = ({ sideBarClose, icon, handleDrawerToggle }) => {
    const businessArea = useGetBusinessArea()

    return (
        <Toolbar
            sx={{
                p: !sideBarClose ? '0 !important' : 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {icon && sideBarClose && (
                <Stack
                    component="div"
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ width: '100%' }}
                >
                    {/* <Box
						p={"8px"}
						borderRight={"1px solid var(--borderColor)"}
					>
						<img
							src={Logo2}
							alt="icon"
							className={styles.menuIcon}
						/>
					</Box> */}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                            padding: '0 8px',
                        }}
                    >
                        {businessArea && businessArea === 'buildlink' && (
                            <img
                                src={BuildLinkLogo}
                                alt="icon"
                                className={styles.menuIcon}
                                width={100}
                                height={40}
                                style={{
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                        {businessArea && businessArea === 'buildstudio' && (
                            <img
                                src={BuildStudioLogo}
                                alt="icon"
                                className={styles.menuIcon}
                                width={100}
                                height={40}
                                style={{
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                        {businessArea && businessArea === 'buildbid' && (
                            <img
                                src={Logo}
                                alt="icon"
                                className={styles.menuIcon}
                                // width={105}
                                // height={40}
                                // style={{
                                //   objectFit: "contain",
                                // }}
                            />
                        )}
                        {businessArea && businessArea === 'buildshop' && (
                            <>
                                <img
                                    src={BuildLinkLogo}
                                    alt="icon"
                                    className={styles.menuIcon}
                                    width={100}
                                    height={40}
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                        )}

                        {businessArea && businessArea === 'buildinsurance' && (
                            <>
                                <img
                                    src={BuildLinkLogo}
                                    alt="icon"
                                    className={styles.menuIcon}
                                    width={100}
                                    height={40}
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </>
                        )}

                        {businessArea &&
                            (businessArea === 'dashboard' ||
                                businessArea === 'chat') && (
                                <>
                                    <img
                                        src={BuildLinkLogo}
                                        alt="icon"
                                        className={styles.menuIcon}
                                        width={100}
                                        height={40}
                                        style={{
                                            objectFit: 'cover',
                                        }}
                                    />
                                </>
                            )}

                        <MenuOpenIcon
                            onClick={() => handleDrawerToggle()}
                            className={styles.menuIcon}
                        />
                    </Box>
                </Stack>
            )}
            {!sideBarClose && (
                <Typography
                    variant="h6"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    noWrap
                    component="div"
                >
                    {!sideBarClose && (
                        <IconButton
                            onClick={handleDrawerToggle}
                            sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'inline-block',
                                },
                            }}
                        >
                            <MenuOpenIcon />
                        </IconButton>
                    )}
                </Typography>
            )}
        </Toolbar>
    )
}

export default SideBarNav
