import React, { useState } from 'react'
import TrackingOrderBox from './TrackingOrderBox'
import axios from 'axios'
import { BASE_URL } from '../../../Utils/response'
import { getUserToken } from '../../../Utils/LoginAuth'
import { ButtonCmp } from '../../../Components'

const TrackingButtonCmp = (data) => {
    const [isVisible, setIsVisible] = useState(false)
    const [trackingData, setTrackingData] = useState([])

    const getHeader = () => {
        return {
            Authorization: 'Bearer ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }
    const fetchData = async () => {
        try {
            const res = await axios.post(
                `${BASE_URL}/ups-tracking`,
                { tracking_id: data.data.trackingId },
                {
                    headers: getHeader(),
                }
            )

            setTrackingData(
                res?.data?.data?.trackResponse?.shipment[0]?.package[0]
                    ?.activity
            )
        } catch (error) {
            console.error(
                'There was a problem with the fetch operation:',
                error
            )
        }
    }

    // Function to toggle visibility
    const toggleVisibility = () => {
        if (!isVisible) {
            fetchData()
        }
        setIsVisible(!isVisible)
    }
    return (
        <div>
            <ButtonCmp
                style={{ width: 'max-content', padding: '10px 15px' }}
                title="Track Order"
                onClick={toggleVisibility}
            />
            {isVisible && <TrackingOrderBox trackingData={trackingData} />}
        </div>
    )
}

export default TrackingButtonCmp
