import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import styles from './ProjectGridView.module.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    BiEdit,
    LocationOnOutlinedIcon,
    RiDeleteBin6Line,
} from '../../../Assets'
import { postedDuration } from '../../../Utils/utils'
import { DeleteJobAction } from '../../../Store/Actions/BuildBidsJobAction'
import useAlert from '../../../Hooks/useAlert/useAlert'
import IconCmp from '../../IconCmp/IconCmp'
import { DeleteBuildStudioProject } from '../../../Store/Actions/BuildStudio/BuildStudioAction'

const ProjectGridView = ({ path, projectDetails }) => {
    return (
        <Grid container className={styles.jobGridCardWrapper}>
            {projectDetails &&
                projectDetails.map((jobs, index) => {
                    return (
                        <Grid item xs={12} sm={6} key={jobs.id}>
                            <Card path={path} projectDetails={jobs} />
                        </Grid>
                    )
                })}
        </Grid>
    )
}

const Card = ({ path, projectDetails }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    const { title, services_count, slug, created_at, address, state, zipcode } =
        projectDetails

    const handleDeleteJob = (e) => {
        e.stopPropagation()
        dispatch(DeleteBuildStudioProject(slug, setAlert))
    }

    const editJob = (e) => {
        e.stopPropagation()
        navigate(`/updatejob/${slug}`)
    }
    return (
        <Box
            className={styles.gridviewCardWrapper}
            onClick={() => {
                navigate(`jobdetail/${slug}`)
            }}
        >
            <div className={styles.cardInfo}>
                <section className={styles.cardInfoDetails}>
                    <Typography variant="h5" className={styles.jobName}>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {postedDuration(created_at)}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                        }}
                    >
                        <LocationOnOutlinedIcon
                            fontSize="10px"
                            sx={{
                                marginRight: '5px',
                                ml: '-5px',
                                color: 'var(--primary-color)',
                            }}
                        />
                        <small>
                            {address}, {state?.label}, {zipcode}
                        </small>
                    </Box>
                </section>
                <section className={styles.cardInfoService}>
                    <section className={styles.actionButtonsContainer}>
                        <Box
                            onClick={editJob}
                            className={styles.editBtnIcon}
                            sx={{
                                color: 'var(--milestoneText)',
                            }}
                        >
                            <IconCmp>
                                <BiEdit />
                            </IconCmp>
                        </Box>

                        <Box
                            onClick={handleDeleteJob}
                            className={styles.deleteBtnIcon}
                        >
                            <IconCmp>
                                <RiDeleteBin6Line />
                            </IconCmp>
                        </Box>
                    </section>
                    <Box
                        sx={{
                            backgroundColor: 'var(--light-green)',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            marginTop: '10px',
                            color: 'var(--green)',
                        }}
                        alignSelf={'flex-end'}
                        className={styles.cardSeriveWrapper}
                    >
                        <div>
                            <Typography fontSize={'12px'}>
                                {services_count} Services required
                            </Typography>
                        </div>
                    </Box>
                </section>
            </div>
            <Box className={styles.cardInfoDate2}>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Create Date</td>
                                <td>:</td>
                                <td>{created_at.split('T')[0]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </Box>
    )
}

export default ProjectGridView
