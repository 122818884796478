import TrackingButtonCmp from './TrackingButtonCmp'
import {
    BodyLayout,
    ButtonCmp,
    DatePickerCmp,
    TimePickerCmp,
} from '../../../Components'
import InputBox from '../../../Components/InputBox/InputBox'
import styles from './OrderDetail.module.css'
import { Grid, Typography, Box, Modal } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { BASE_URL } from '../../../Utils/response'
import axios from 'axios'
import { getUserToken } from '../../../Utils/LoginAuth'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import useAlert from '../../../Hooks/useAlert/useAlert'

import { useForm, useFieldArray } from 'react-hook-form'

const OrderDetail = () => {
    const location = useLocation()
    const { setAlert } = useAlert()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [orderDetails, setOrderDetails] = useState([])

    const handlePickupSubmit = (order) => {
        const formData = new FormData()

        const data = getValues()
        formData.append(
            'pickup_date',
            moment(data.pickupDate).format('YYYYMMDD')
        )
        formData.append(
            'pickup_close_time',
            moment(data.pickupToTime).format('HHmm')
        )
        formData.append(
            'pickup_ready_time',
            moment(data.pickupFromTime).format('HHmm')
        )

        formData.append('order_id', order.id)

        const getHeader = () => {
            return {
                Authorization: 'Bearer' + ' ' + getUserToken(),
                'content-type': 'multipart/form-data',
            }
        }

        axios
            .post(`${BASE_URL}/pickup-creation`, formData, {
                headers: getHeader(),
            })
            .then((response) => {
                // if (
                //     response.data.data.LabelRecoveryResponse.Response
                //         .ResponseStatus === '1'
                // ) {

                handleClose()
                setAlert(response.data.message || 'Pickup Created', 'success')
                navigate('/buildshop/orders/shipped')
            })
            .catch((error) => {})
    }
    let { order } = location.state

    const { control, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            pickupDate: '',
            pickupFromTime: '',
            pickupToTime: '',
        },
    })

    const handlePrintLabel = async (label) => {
        console.log(label.split(','))
        const base64PDF = await multipleBase64GifsToPdf(label.split(','))
        console.log(base64PDF)
        const byteCharacters = atob(base64PDF)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
            type: 'application/pdf',
        })

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = blobUrl

        // Set target to _blank to open in a new tab
        link.target = '_blank'

        // For security reasons, add rel="noopener noreferrer"
        link.rel = 'noopener noreferrer'

        // Append the anchor to the body (necessary in some browsers)
        document.body.appendChild(link)

        // Programmatically click the anchor to trigger the new tab
        link.click()

        // Remove the anchor from the document
        document.body.removeChild(link)
    }

    // Utility to convert Base64 GIF to a PNG Blob using a canvas
    function base64GifToPngBlob(base64Gif) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = `data:image/gif;base64,${base64Gif}`

            img.onload = () => {
                // Create a canvas to draw the GIF on
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height
                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0)

                // Convert canvas to Blob (PNG format)
                canvas.toBlob((blob) => {
                    if (blob) resolve(blob)
                    else reject(new Error('Failed to convert GIF to PNG'))
                }, 'image/png')
            }

            img.onerror = reject
        })
    }

    // Function to convert multiple Base64 GIFs to a single PDF
    async function multipleBase64GifsToPdf(base64Gifs) {
        const pdfDoc = await PDFDocument.create()

        for (const base64Gif of base64Gifs) {
            // Convert Base64 GIF to PNG Blob
            const pngBlob = await base64GifToPngBlob(base64Gif)

            // Convert the PNG Blob to ArrayBuffer for embedding
            const pngBytes = new Uint8Array(await pngBlob.arrayBuffer())

            // Embed the PNG in the PDF
            const pngImage = await pdfDoc.embedPng(pngBytes)

            // Get the dimensions of the PNG
            const { width, height } = pngImage

            // Add a new page with the same size as the image
            const page = pdfDoc.addPage([width, height])

            // Draw the PNG on the page
            page.drawImage(pngImage, {
                x: 0,
                y: 0,
                width,
                height,
            })
        }

        // Serialize the PDF to bytes
        const pdfBytes = await pdfDoc.save()

        // Convert the PDF bytes to Base64 if needed
        const pdfBase64 = btoa(String.fromCharCode(...pdfBytes))
        return pdfBase64
    }

    return (
        <BodyLayout className={styles.mainBox}>
            <Box sx={{ padding: '2rem' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Order Details
                </Typography>
            </Box>
            <section>
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        my: '20px',
                        px: '20px',
                        pb: '20px',
                        gap: '1rem',
                        cursor: 'pointer',
                    }}
                >
                    <Grid item lg={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                flexDirection: 'column',
                            }}
                        >
                            <section>
                                <Typography
                                    sx={{
                                        color: 'var(--textColor)',
                                        fontSize: '14px',
                                    }}
                                >
                                    Order No.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {order?.order_number}
                                </Typography>
                            </section>
                        </Box>
                    </Grid>
                    <Grid item lg={3}>
                        <Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: 'var(--textColor)',
                                        fontSize: '14px',
                                    }}
                                >
                                    Payment Status
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {order?.payment_status}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={2}>
                        {' '}
                        <Typography
                            sx={{ color: 'var(--textColor)', fontSize: '14px' }}
                        >
                            Order Status
                        </Typography>
                        <Typography
                            sx={{ fontSize: '18px', fontWeight: 'bold' }}
                        >
                            {order?.order_status}
                        </Typography>{' '}
                    </Grid>
                    <Grid item lg={2}>
                        {order?.order_status == 'Pending' && (
                            <ButtonCmp
                                style={{
                                    width: 'max-content',
                                    padding: '10px 15px',
                                }}
                                title="Package Order"
                                onClick={() => {
                                    setOrderDetails(order.order_details)
                                    handleOpen()
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </section>
            <Grid>
                {order.order_details.map((item, index) => {
                    return (
                        <Box
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                my: '20px',
                                pb: '20px',
                                px: '20px',
                                gap: '2rem',
                                borderBottom: '2px solid #ECECEC',
                                cursor: 'pointer',
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: '2rem',
                                }}
                            >
                                <Grid item lg={3} sx={{ cursor: 'pointer' }}>
                                    <section className={styles.cardImg}>
                                        <img
                                            alt={item.product.title}
                                            src={
                                                item.product.ImgURL +
                                                item.product.thumbnail
                                            }
                                            width="200px"
                                        />
                                    </section>
                                </Grid>
                                <Grid item lg={3}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <section>
                                            <Typography
                                                sx={{
                                                    color: 'var(--textColor)',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Product Name
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {item?.product.title}
                                            </Typography>
                                        </section>
                                    </Box>
                                </Grid>
                                <Grid item lg={3}>
                                    <Box>
                                        <section>
                                            <Typography
                                                sx={{
                                                    color: 'var(--textColor)',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Variant
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {item?.variant}
                                            </Typography>
                                        </section>
                                    </Box>
                                </Grid>
                                <Grid item lg={3}>
                                    {' '}
                                    <Typography
                                        sx={{
                                            color: 'var(--textColor)',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Quantity
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {Number(item?.quantity)}
                                    </Typography>{' '}
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    sx={{
                                        display: 'flex',
                                        gap: '2rem',
                                        px: '4rem',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {item.shipping_label && (
                                        <ButtonCmp
                                            style={{
                                                width: 'max-content',
                                                padding: '10px 15px',
                                            }}
                                            title="Print Label"
                                            onClick={() => {
                                                handlePrintLabel(
                                                    item.shipping_label
                                                )
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Box>
                            <Box>
                                <Grid item>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '2rem',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '2rem',
                                                width: 'auto',
                                            }}
                                        >
                                            <TrackingButtonCmp
                                                data={{
                                                    trackingId:
                                                        item.tracking_id,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    )
                })}
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box className={styles.modalWrapper}>
                    <Box
                        sx={{
                            padding: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {' '}
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Select Pickup Date
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                padding: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <Box>
                                <DatePickerCmp
                                    label={'Pickup Date'}
                                    name="pickupDate"
                                    control={control}
                                />
                            </Box>
                            <Box>
                                <TimePickerCmp
                                    label={'From Pickup Time'}
                                    name="pickupFromTime"
                                    control={control}
                                />
                            </Box>
                            <Box>
                                <TimePickerCmp
                                    label={'To Pickup Time'}
                                    name="pickupToTime"
                                    control={control}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                            }}
                        >
                            <ButtonCmp
                                style={{
                                    width: 'max-content',
                                    padding: '10px 15px',
                                }}
                                title="Confirm"
                                onClick={() => handlePickupSubmit(order)}
                            />
                            <ButtonCmp
                                style={{
                                    width: 'max-content',
                                    padding: '10px 15px',
                                }}
                                title="Cancel"
                                onClick={handleClose}
                                btnColor={'error'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </BodyLayout>
    )
}

export default OrderDetail
