import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import TextTruncate from 'react-text-truncate'
import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from '..'
import { BidIcon } from '../../Assets'
import { postedDuration } from '../../Utils/utils'
import styles from '../JobCards/ListView.module.css'

import { useDispatch } from 'react-redux'
import useAlert from '../../Hooks/useAlert/useAlert'
import {
    PermanentDeleteJobAction,
    RestoreDeleteJobAction,
} from '../../Store/Actions/BuildBidsJobAction'

const TrashJobCard = ({ deleteBtn, color, path, deltetJob }) => {
    const navigate = useNavigate()
    const { setAlert } = useAlert()
    const dispatch = useDispatch()

    const [paraLine, setParaLine] = useState(true)
    const [hover, setHover] = useState(false)

    const paraHandler = () => {
        setParaLine(!paraLine)
    }

    const hoverHandler = (event) => {
        if (event === 'enter') {
            setHover(true)
            return
        }
        setHover(false)
    }

    const {
        biding_end_date,
        biding_start_date,
        description,
        title,
        project_start_date,
        proposals_count,
        services_count,
        services,
        slug,
        created_at,
        address,
        state,
        zipcode,
    } = deltetJob

    const restoreJob = (projectSlug) => {
        dispatch(RestoreDeleteJobAction(projectSlug, setAlert))
    }

    const permanentJobDelete = (projectSlug) => {
        dispatch(PermanentDeleteJobAction(projectSlug, setAlert))
    }
    return (
        <section
            className={styles.jobCardWrapper}
            onMouseEnter={() => hoverHandler('enter')}
            onMouseLeave={() => hoverHandler('leave')}
        >
            <Box
                className={styles.cardHeader}
                // onClick={() => navigate(`${path}/${slug}`)}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {postedDuration(created_at)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'var(--light-green)',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        color: 'var(--green)',
                    }}
                    alignSelf={'flex-end'}
                    className={styles.cardSeriveWrapper}
                >
                    <div>
                        <Typography fontSize={'12px'}>
                            {services_count} Services required
                        </Typography>
                    </div>
                </Box>
            </Box>
            <section className={styles.headerList}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                    }}
                    className={styles.jobDetails}
                >
                    <LocationOnOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--green)',
                            ml: '-5px',
                            fontSize: '18px',
                        }}
                    />
                    <small>
                        {address}, {state?.label}, {zipcode}
                    </small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <EventAvailableOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--primary-color)',
                            fontSize: '18px',
                        }}
                    />
                    <small>Create Date: {created_at}</small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <EventBusyOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'red',
                            fontSize: '18px',
                        }}
                    />
                    <small>Bid Closing Date: {biding_end_date}</small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <EventBusyOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'red',
                            fontSize: '18px',
                        }}
                    />
                    <small># of Bid Received: {proposals_count}</small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <img src={BidIcon} style={{ marginRight: '5px' }} alt="" />
                    <small>Job Start Date: 5/25/2021</small>
                </Box>
            </section>
            <section className={styles.cardBody}>
                <TextTruncate
                    line={paraLine && 3}
                    element="span"
                    truncateText="…"
                    text={description}
                    // textTruncateChild={}
                    style={{ color: 'var(--textColor)', fontSize: 'medium' }}
                />
            </section>
            <small className={styles.paraMoreBtn} onClick={paraHandler}>
                {paraLine ? 'More' : 'Less'}
            </small>
            <section className={styles.cardFooter}>
                <div>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Services:
                    </Typography>
                </div>
                <ScrollableTabs
                    bgColor={color}
                    hover={hover}
                    servicesTags={services}
                />
            </section>
            {deleteBtn && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        my: 2,
                        gap: 2,
                    }}
                >
                    <WhiteButtonCmp
                        title="Permanent Delete Job"
                        style={{ borderColor: ' red !important', color: 'red' }}
                        onClick={() => {
                            permanentJobDelete(slug)
                        }}
                    />
                    <ButtonCmp
                        style={{ width: 'max-content', padding: '10px 15px' }}
                        title="Restore Job"
                        onClick={() => {
                            restoreJob(slug)
                        }}
                    />
                </Box>
            )}
        </section>
    )
}

export default TrashJobCard
