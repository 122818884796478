import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = () => {
    const [isAuth, setIsAuth] = useState(false)
    const { userLogindata } = useSelector((state) => state.LoginReducers)

    return JSON.parse(localStorage.getItem('userData'))?.token ? (
        <Outlet />
    ) : (
        <Navigate to="/login" />
    )
}

export default ProtectedRoute
