import ActionTypes from '../Constants'

const INITIALSTATE = {
    exampleLoading: false,
    exampledata: '',
    exampleMessage: '',
}

const ExampleReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.EXAMPLE_TYPE_LOADING:
            return {
                ...state,
                exampleLoading: true,
            }
        default:
            return state
    }
}

export { ExampleReducers }
