import { useState } from 'react'
import styles from './AboutCompany.module.css'
import {
    CheckCircleOutlineOutlinedIcon,
    LocalPhoneOutlinedIcon,
    TextsmsOutlinedIcon,
    WorkOutlineOutlinedIcon,
} from '../../Assets'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const AboutCompany = ({
    ongoingProjects,
    completedProjects,
    mobileNo,
    singleBidDetailsdata,
    messageOption,
}) => {
    const [openNew, setOpenNew] = useState(false)
    const [openLocation, setOpenLocation] = useState(false)

    const { user } = singleBidDetailsdata
    console.log('user user', user)

    const navigate = useNavigate()
    const sendMessage = () => {
        navigate('/chat', {
            state: {
                userLocation: user,
            },
        })
    }

    return (
        <section className={styles.sideBarWrapper}>
            <div className={styles.myJobHeader}>
                <div>
                    <Typography variant="h6" fontWeight={'bold'}>
                        About Company
                    </Typography>
                </div>
            </div>
            <Box sx={{ padding: '0 35px', margin: '20px  0' }}>
                <Box
                    sx={{ display: 'flex', gap: 5 }}
                    className={styles.companyContact}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                            className={styles.iconBox}
                            sx={{ bgcolor: 'white' }}
                        >
                            <LocalPhoneOutlinedIcon fontSize={'12px'} />
                        </Box>
                        <Typography fontSize={'medium'} fontWeight="bold">
                            {user?.phone ? user?.phone : '+1 123 123 1234'}
                        </Typography>
                    </Box>
                    {messageOption ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Box
                                className={styles.iconBox}
                                sx={{ bgcolor: 'white' }}
                                onClick={sendMessage}
                            >
                                <TextsmsOutlinedIcon fontSize={'12px'} />
                            </Box>
                            <Typography fontSize={'medium'} fontWeight="bold">
                                Message
                            </Typography>
                        </Box>
                    ) : null}
                </Box>

                {/* ////AWARDED PROJECTS// */}

                <div className={styles.projectHeadings}>
                    <CheckCircleOutlineOutlinedIcon
                        sx={{ color: 'var(--primary-color)' }}
                    />
                    <Typography>Ongoing Jobs</Typography>
                </div>
                {user && user?.awarded_projects?.length > 0 ? (
                    user?.awarded_projects?.map((awarded_project, index) => {
                        return (
                            <section
                                key={index}
                                className={styles.awardedProjects}
                            >
                                <Box
                                    className={styles.awardedProjects__details}
                                >
                                    <section
                                        className={styles.awardedProjects__icon}
                                    >
                                        <WorkOutlineOutlinedIcon
                                            fontSize="small"
                                            sx={{ color: 'var(--lightIcon )' }}
                                        />
                                    </section>
                                    <Box
                                        className={
                                            styles.awardedProjects__projectDetails
                                        }
                                    >
                                        <Typography fontSize={'14px'}>
                                            {awarded_project?.project?.label}
                                        </Typography>
                                        <Typography
                                            fontSize={'12px'}
                                            sx={{ color: 'var(--textColor)' }}
                                        >
                                            Job Start Date:
                                            {
                                                awarded_project?.project
                                                    ?.project_start_date
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                                <div>
                                    <Typography
                                        sx={{ color: 'var(--primary-color)' }}
                                        fontSize={15}
                                        fontWeight={'bold'}
                                    >
                                        ${' '}
                                        {
                                            awarded_project?.project
                                                ?.budget_amount
                                        }
                                    </Typography>
                                </div>
                            </section>
                        )
                    })
                ) : (
                    <Typography fontSize={'14px'}>No Awarded Jobs</Typography>
                )}

                {/* ////Completed PROJECTS// */}

                <div className={styles.projectHeadings}>
                    <CheckCircleOutlineOutlinedIcon
                        sx={{ color: 'var(--green)' }}
                    />
                    <Typography>Completed Projects</Typography>
                </div>
                {user && user?.completed_projects.length > 0 ? (
                    user?.completed_projects?.map(
                        (completed_project, index) => {
                            return (
                                <section
                                    key={index}
                                    className={styles.awardedProjects}
                                >
                                    <Box
                                        className={
                                            styles.awardedProjects__details
                                        }
                                    >
                                        <section
                                            className={
                                                styles.awardedProjects__icon
                                            }
                                        >
                                            <WorkOutlineOutlinedIcon
                                                fontSize="small"
                                                sx={{
                                                    color: 'var(--lightIcon )',
                                                }}
                                            />
                                        </section>
                                        <Box
                                            className={
                                                styles.awardedProjects__projectDetails
                                            }
                                        >
                                            <Typography fontSize={13}>
                                                {
                                                    completed_project?.project
                                                        ?.label
                                                }
                                            </Typography>

                                            <Typography
                                                fontSize={'12px'}
                                                sx={{
                                                    color: 'var(--textColor)',
                                                }}
                                            >
                                                Job Start Date:
                                                {
                                                    completed_project?.project
                                                        ?.project_start_date
                                                }
                                            </Typography>
                                            <Typography
                                                fontSize={'12px'}
                                                sx={{
                                                    color: 'var(--textColor)',
                                                }}
                                            >
                                                Job End Date:
                                                {
                                                    completed_project?.project
                                                        ?.job_completion_date
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <div>
                                        <Typography
                                            sx={{
                                                color: 'var(--primary-color)',
                                            }}
                                            fontSize={15}
                                            fontWeight={'bold'}
                                        >
                                            ${' '}
                                            {
                                                completed_project?.project
                                                    ?.budget_amount
                                            }
                                        </Typography>
                                    </div>
                                </section>
                            )
                        }
                    )
                ) : (
                    <Typography fontSize={'14px'}>No Completed Jobs</Typography>
                )}
            </Box>
        </section>
    )
}

export default AboutCompany
