import ActionTypes from '../Constants'

const INITIALSTATE = {
    ///add product
    addProductLoading: false,
    addProductdata: '',
    addProductError: '',

    //user products
    userProductsLoading: false,
    userProductsdata: '',
    userProductsError: '',

    //product detail
    productDetailsLoading: false,
    productDetailsdata: '',
    productDetailsError: '',

    //delete product
    deleteProductLoading: false,
    deleteProductMessage: '',
    deleteProductError: '',

    //get deleted products
    getDeletedProductsLoading: false,
    getDeletedProductsdata: '',
    getDeletedProductsError: '',

    //restore deleted product
    restoreDeletedProductLoading: false,
    isRestore: false,
    restoreDeletedProductError: '',
    restoreDeletedProductMsg: '',

    // permanently delete product
    permanentDeleteProductLoading: false,
    isPermanentDelete: false,
    permanentDeleteProductError: '',
    permanentDeleteProductMsg: '',

    ///Update product
    updateProductLoading: false,
    updateProductdata: '',
    updateProductError: '',

    ///bulk import product
    productBulkImportLoading: false,
    productBulkImportdata: '',
    productBulkImportError: '',
}

const AddProductReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.ADD_PRODUCT_REQUEST:
            return {
                ...state,
                addProductLoading: true,
            }
        case ActionTypes.ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                addProductLoading: false,
                addProductdata: action.payload.data,
                addProductError: '',
            }
        case ActionTypes.ADD_PRODUCT_FAIL:
            return {
                ...state,
                addProductLoading: false,
                addProductdata: [],
                addProductError: action.payload.message,
            }
        default:
            return state
    }
}

const UserProductsReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_USER_PRODUCTS_REQUEST:
            return {
                ...state,
                userProductsLoading: true,
            }
        case ActionTypes.GET_USER_PRODUCTS_SUCCESS:
            return {
                ...state,
                userProductsLoading: false,
                userProductsdata: action.payload.data,
                userProductsError: '',
            }
        case ActionTypes.GET_USER_PRODUCTS_FAIL:
            return {
                ...state,
                userProductsLoading: false,
                userProductsdata: [],
                userProductsError: action.payload.message,
            }
        default:
            return state
    }
}

const ProductDetailsReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                productDetailsLoading: true,
            }
        case ActionTypes.GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsdata: action.payload.data,
                productDetailsError: '',
            }
        case ActionTypes.GET_PRODUCT_DETAILS_FAIL:
            return {
                ...state,
                productDetailsLoading: false,
                productDetailsdata: [],
                productDetailsError: action.payload.message,
            }
        default:
            return state
    }
}

const DeleteProductReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                deleteProductLoading: true,
            }
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductMessage: action.payload.message,
                deleteProductError: '',
            }
        case ActionTypes.DELETE_PRODUCT_FAIL:
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductMessage: '',
                deleteProductError: action.payload.message,
            }
        case ActionTypes.EMPTY__DELETE_PRODUCT_STATE:
            return {
                ...state,
                deleteProductLoading: false,
                deleteProductMessage: '',
                deleteProductError: '',
            }
        default:
            return state
    }
}

const GetDeletedProductsReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_DELETED_PRODUCTS_REQUEST:
            return {
                ...state,
                getDeletedProductsLoading: true,
            }
        case ActionTypes.GET_DELETED_PRODUCTS_SUCCESS:
            return {
                ...state,
                getDeletedProductsLoading: false,
                getDeletedProductsdata: action.payload.data,
                getDeletedProductsError: '',
            }
        case ActionTypes.GET_DELETED_PRODUCTS_FAIL:
            return {
                ...state,
                getDeletedProductsLoading: false,
                getDeletedProductsdata: [],
                getDeletedProductsError: action.payload.message,
            }
        default:
            return state
    }
}

const RestoreDeletedProductReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.RESTORE_DELETED_PRODUCT_REQUEST:
            return {
                ...state,
                restoreDeletedProductLoading: true,
            }
        case ActionTypes.RESTORE_DELETED_PRODUCT_SUCCESS:
            return {
                ...state,
                restoreDeletedProductLoading: false,
                isRestore: action.payload.success,
                restoreDeletedProductError: '',
                restoreDeletedProductMsg: action.payload.message,
            }
        case ActionTypes.RESTORE_DELETED_PRODUCT_FAIL:
            return {
                ...state,
                restoreDeletedProductLoading: false,
                isRestore: false,
                restoreDeletedProductError: action.payload.message,
                restoreDeletedProductMsg: '',
            }
        case ActionTypes.EMPTY_RESTORE_DELETED_PRODUCT_STATE:
            return {
                ...state,
                restoreDeletedProductLoading: false,
                isRestore: false,
                restoreDeletedProductError: '',
                restoreDeletedProductMsg: '',
            }
        default:
            return state
    }
}

const PermanentDeleteProductReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.PERMANENT_DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                permanentDeleteProductLoading: true,
            }
        case ActionTypes.PERMANENT_DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                permanentDeleteProductLoading: false,
                isPermanentDelete: action.payload.success,
                permanentDeleteProductError: '',
                permanentDeleteProductMsg: action.payload.message,
            }
        case ActionTypes.PERMANENT_DELETE_PRODUCT_FAIL:
            return {
                ...state,
                permanentDeleteProductLoading: false,
                isPermanentDelete: false,
                permanentDeleteProductError: action.payload.message,
                permanentDeleteProductMsg: '',
            }
        case ActionTypes.EMPTY_PERMANENT_DELETE_PRODUCT_STATE:
            return {
                ...state,
                permanentDeleteProductLoading: false,
                isPermanentDelete: false,
                permanentDeleteProductError: '',
                permanentDeleteProductMsg: '',
            }
        default:
            return state
    }
}

const UpdateProductReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProductLoading: true,
            }
        case ActionTypes.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                updateProductLoading: false,
                updateProductdata: action.payload.data,
                updateProductError: '',
            }
        case ActionTypes.UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                updateProductLoading: false,
                updateProductdata: '',
                updateProductError: action.payload.message,
            }
        default:
            return state
    }
}

const ProductBulkImportReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.PRODUCT_BULK_IMPORT_REQUEST:
            return {
                ...state,
                productBulkImportLoading: true,
            }
        case ActionTypes.PRODUCT_BULK_IMPORT_SUCCESS:
            return {
                ...state,
                productBulkImportLoading: false,
                productBulkImportdata: action.payload.data,
                productBulkImportError: '',
            }
        case ActionTypes.PRODUCT_BULK_IMPORT_FAIL:
            return {
                ...state,
                productBulkImportLoading: false,
                productBulkImportdata: [],
                productBulkImportError: action.payload.message,
            }
        default:
            return state
    }
}
export {
    AddProductReducer,
    UserProductsReducer,
    ProductDetailsReducer,
    DeleteProductReducer,
    GetDeletedProductsReducer,
    RestoreDeletedProductReducer,
    PermanentDeleteProductReducer,
    UpdateProductReducer,
    ProductBulkImportReducer,
}
