import React from 'react'
import styles from './ProfileCompletionForm.module.css'

import { Box, Grid, Typography } from '@mui/material'
import {
    ButtonCmp,
    DropDownCmp,
    InputCmp,
    TextAreaCmp,
    WhiteButtonCmp,
} from '../../Components'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    EmptyProfileWizardState,
    ProfileWizardAction,
} from '../../Store/Actions/ProfileAction'
import useAlert from '../../Hooks/useAlert/useAlert'

const DocsAndRegsForm = ({
    setWhichForm,
    setBarPercent,
    setDocsAndRegComplete,
}) => {
    ///=>>>gli = general Liability Insurance
    const [gliStatus, setGliStatus] = useState(true)
    const [workersComp, setWorkersComp] = useState(true)

    const { setAlert } = useAlert()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { profileWizarddata } = useSelector(
        (state) => state.ProfielWizardReducer
    )
    const { control, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            licensedProfession: '',
            licenseNumber: '',
            FEIN: '',
            bonded: '',
            generalLiabilityInsurance: '',
            gliPolicy: '',
            gliPolicyMax: '',
            workersComp: '',
            wcPolicy: '',
            wcPolicyMax: '',
            businessDescription: '',
            affiliations: '',
            certifications: '',
        },
    })

    const gliState = watch('generalLiabilityInsurance')
    const workersCompState = watch('workersComp')

    useEffect(() => {
        if (gliState) {
            if (gliState === 'YES') {
                setGliStatus(false)
            } else {
                setGliStatus(true)
            }
        }
    }, [gliState])

    useEffect(() => {
        if (workersCompState) {
            if (workersCompState === 'YES') {
                setWorkersComp(false)
            } else {
                setWorkersComp(true)
            }
        }
    }, [workersCompState])

    const onSumit = (obj) => {
        dispatch(ProfileWizardAction(obj, 'documentAndRegistration', setAlert))

        // setWhichForm("services");
        // setBarPercent("50%");
        // setDocsAndRegComplete(true);
    }

    useEffect(() => {
        if (profileWizarddata) {
            setWhichForm('services')
            setBarPercent('50%')
            setDocsAndRegComplete(true)
            dispatch(EmptyProfileWizardState())
        }
    }, [profileWizarddata])
    return (
        <section className={styles.docsRegsInfo}>
            <div>
                <Typography noWrap variant="h5" fontWeight={'bold'}>
                    Document and registration
                </Typography>
            </div>
            <form onSubmit={handleSubmit(onSumit)}>
                <section className={styles.formInputsFields}>
                    <div>
                        <InputCmp
                            name="licensedProfession" //imporant
                            control={control} //imporant
                            label="Licensed Profession"
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="licenseNumber" //imporant
                            control={control} //imporant
                            label="License Number #1"
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="FEIN" //imporant
                            control={control} //imporant
                            label=" #, Corporate #, Charter #, or ASID #"
                        />
                    </div>
                </section>
                <section className={styles.formInputsFields}>
                    <div>
                        <DropDownCmp
                            name="bonded" //imporant
                            control={control} //imporant
                            label="Bonded"
                            options={['YES', 'NO']}
                            disableClearable
                            freeSolo={false}
                        />
                    </div>
                    <div></div>
                    <div></div>
                </section>

                <section className={styles.formInputsFields}>
                    <div>
                        <DropDownCmp
                            name="generalLiabilityInsurance" //imporant
                            control={control} //imporant
                            label="General Liability Insurance"
                            options={['YES', 'NO']}
                            disableClearable
                            freeSolo={false}
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="gliPolicy" //imporant
                            control={control} //imporant
                            label="Policy #"
                            disabled={gliStatus}
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="gliPolicyMax" //imporant
                            control={control} //imporant
                            label="Policy Maximum"
                            disabled={gliStatus}
                            type="number"
                        />
                    </div>
                </section>
                <section className={styles.formInputsFields}>
                    <div>
                        <DropDownCmp
                            name="workersComp" //imporant
                            control={control} //imporant
                            label="Worker's Comp?"
                            options={['YES', 'NO']}
                            disableClearable
                            freeSolo={false}
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="wcPolicy" //imporant
                            control={control} //imporant
                            label="Policy #"
                            disabled={workersComp}
                        />
                    </div>
                    <div>
                        <InputCmp
                            name="wcPolicyMax" //imporant
                            control={control} //imporant
                            label="Policy Maximum"
                            disabled={workersComp}
                            type="number"
                        />
                    </div>
                </section>

                <section className={styles.formInputsFields}>
                    <div>
                        <TextAreaCmp
                            control={control}
                            name="businessDescription"
                            label="Business description"
                            rows={5}
                        />
                    </div>
                </section>
                <section className={styles.formInputsFields}>
                    <div>
                        <TextAreaCmp
                            control={control}
                            name="affiliations"
                            label="Affiliations"
                            rows={5}
                        />
                    </div>
                    <div>
                        <TextAreaCmp
                            control={control}
                            name="certifications"
                            label="Certificationss"
                            rows={5}
                        />
                    </div>
                </section>
                {/* ////btn */}
                <section className={styles.formBtns}>
                    {/* <WhiteButtonCmp title="Cancel" /> */}
                    <WhiteButtonCmp
                        title="Cancel"
                        onClick={() => {
                            navigate('/profile')
                        }}
                    />
                    <ButtonCmp
                        title="Skip Step"
                        onClick={() => {
                            setWhichForm('services')
                        }}
                    />
                    <ButtonCmp title="Save and Continue" type="submit" />
                </section>
            </form>
        </section>
    )
}

export default DocsAndRegsForm
