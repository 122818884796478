import Paper from '@mui/material/Paper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import './ProjectWidget.css'
import { Button, Typography } from '@mui/material'

const ProjectWidget = ({ bids }) => {
    return (
        <Paper className="bigWidgetsPaper">
            <div className="bigWidgetsPaperInner">
                <div>My Projects</div>
                {/* <div>
          <Button
            className="bigWidgetButton"
            variant="contained"
            color="buttonColor"
          >
            This Week
          </Button>
          <Button
            className="bigWidgetButton"
            variant="contained"
            color="buttonWhite"
          >
            Last Week
          </Button>
        </div> */}
            </div>
            {bids?.length > 0
                ? bids.map((bids) => {
                      return (
                          <>
                              <div className="bigWidgetsPaperInner2">
                                  <div>
                                      <Typography>
                                          {bids?.label}
                                          {/* - Build Studio */}
                                      </Typography>
                                      <div className="bigWidgetsTypeArea2">
                                          <Typography>
                                              {bids?.description.length > 50
                                                  ? bids?.description.slice(
                                                        0,
                                                        50
                                                    )
                                                  : bids?.description}
                                          </Typography>
                                          <div
                                              style={{ margin: '0 5px 0 5px' }}
                                          >
                                              <AccessTimeIcon />
                                          </div>
                                          <Typography>Pending</Typography>
                                      </div>
                                  </div>
                                  <ArrowForwardIosIcon />
                              </div>
                              <div></div>
                          </>
                      )
                  })
                : 'NO BIDS'}
        </Paper>
    )
}

const ProjectListing = () => {
    return <></>
}

export default ProjectWidget
