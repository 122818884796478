// import * as React from "react";
// import { styled, useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import { SideBarNav } from "../../Components";

// const drawerWidth = 240;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// export default function MiniDrawer({ handleSideBarToggle, sideBarClose }) {
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(true);

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <Drawer variant="permanent" open={sideBarClose}>
//         <SideBarNav icon={true} handleDrawerToggle={handleSideBarToggle} />
//       </Drawer>
//     </Box>
//   );
// }

//// 1/1/23
import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'

const drawerWidth = 240

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default function NewSideBarDesign({
    handleDrawerToggle,
    sideBarClose,
    setSideBarClose,
}) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setSideBarClose(true)
        // handleDrawerToggle();
    }

    const handleDrawerClose = () => {
        setSideBarClose(false)
        // handleDrawerToggle();
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'block',
                    },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                    },
                }}
                variant="permanent"
                open={sideBarClose}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map(
                        (text, index) => (
                            <ListItem
                                key={text}
                                disablePadding
                                sx={{ display: 'block' }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: sideBarClose
                                            ? 'initial'
                                            : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: sideBarClose ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {index % 2 === 0 ? (
                                            <InboxIcon />
                                        ) : (
                                            <MailIcon />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text}
                                        sx={{ opacity: sideBarClose ? 1 : 0 }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </Drawer>
        </Box>
    )
}

// const BuildBidOptions = ({ handleClick }) => {
//   let navigate = useNavigate();

//   return (
//     <List
//       sx={{
//         width: "100%",
//         maxWidth: 360,
//         bgcolor: "background.paper",
//       }}
//       component="nav"
//       aria-labelledby="nested-list-subheader"
//     >
//       <NavLink
//         to="/dashboard"
//         className={({ isActive }) =>
//           isActive ? styles.activeClassName : undefined
//         }
//       >
//         <ListItemButton
//           sx={{ margin: "10px !important" }}
//           className={styles.listBtn}
//         >
//           <ListItemIcon>
//             <DashboardSvg />
//           </ListItemIcon>
//           <ListItemText className={styles.list} primary="Dashboard" />
//         </ListItemButton>
//       </NavLink>
//       <Divider />

//       {/* /// Jobs Listing// */}

//       <NavLink
//         to="/jobs"
//         className={({ isActive }) =>
//           isActive ? styles.activeClassName : undefined
//         }
//       >
//         <ListItemButton
//           className={styles.listBtn}
//           sx={{ mt: "20px !important" }}
//           onClick={handleClick}
//         >
//           <ListItemIcon>
//             <Briefcase />
//           </ListItemIcon>
//           <ListItemText className={styles.list} primary="Jobs" />
//           {/* {open ? <ExpandLess /> : <ExpandMore />} */}
//         </ListItemButton>
//       </NavLink>
//       {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
//       <List component="div" disablePadding>
//         {[
//           {
//             icon: <BriefcasePlus />,
//             text: "Create New Job",
//             to: "createjob",
//           },
//           {
//             icon: <CheckBox />,
//             text: "Awarded Jobs",
//             to: "awardedjob",
//           },
//           {
//             icon: <MultiCheckBox />,
//             text: "Completed Jobs",
//             to: "completedjob",
//           },
//           {
//             icon: <TrashSvg />,
//             text: "Trash",
//             to: "trashjob",
//           },
//         ].map((list, index) => (
//           <NavLink
//             to={`/${list.to}`}
//             className={({ isActive }) =>
//               isActive ? styles.activeClassName : undefined
//             }
//             key={index}
//           >
//             <ListItemButton
//               className={styles.listBtn}
//               sx={{ pl: 4 }}
//               // onClick={list.onClick}
//             >
//               <ListItemIcon sx={{ minWidth: "35px" }}>{list.icon}</ListItemIcon>
//               <ListItemText className={styles.list} primary={list.text} />
//             </ListItemButton>
//           </NavLink>
//         ))}
//       </List>
//       {/* </Collapse> */}

//       {/* ///My Bids Listing// */}

//       <NavLink
//         to="/buildbid/mybids"
//         className={({ isActive }) =>
//           isActive ? styles.activeClassName : undefined
//         }
//       >
//         <ListItemButton className={styles.listBtn} onClick={handleClick}>
//           <ListItemIcon>
//             <GavelSvg />
//           </ListItemIcon>
//           <ListItemText className={styles.list} primary="My Bids" />
//           {/* {open ? <ExpandLess /> : <ExpandMore />} */}
//         </ListItemButton>
//       </NavLink>
//       {/* <Collapse in={open} timeout="auto" unmountOnExit> */}
//       <List component="div" disablePadding>
//         {[
//           {
//             icon: <ClockCheckSvg />,
//             text: "Applied Bids",
//             onClick: () => navigate("/"),
//             to: "appliedprojects",
//           },
//           {
//             icon: <CheckBox />,
//             text: "Awarded Bids",
//             onClick: () => navigate("/about"),
//             to: "awardedproject",
//           },
//           {
//             icon: <MultiCheckBox />,
//             text: "Completed Bids",
//             onClick: () => navigate("/"),
//             to: "completedproject",
//           },
//           {
//             icon: <WhishListSvg />,
//             text: "Whish list",
//             onClick: () => navigate("/"),
//             to: "whishlist",
//           },
//         ].map((list, index) => (
//           <NavLink
//             to={`/${list.to}`}
//             className={({ isActive }) =>
//               isActive ? styles.activeClassName : undefined
//             }
//             key={index}
//           >
//             <ListItemButton
//               className={styles.listBtn}
//               sx={{ pl: 4 }}
//               key={index}
//             >
//               <ListItemIcon sx={{ minWidth: "35px" }}>{list.icon}</ListItemIcon>
//               <ListItemText className={styles.list} primary={list.text} />
//             </ListItemButton>
//           </NavLink>
//         ))}
//       </List>
//       {/* </Collapse> */}
//     </List>
//   );
// };
