import React from 'react'
import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIconRight from '../../Assets/Icons/Navigation-Right.png'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import styles from './Navbar.module.css'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { NavLink } from 'react-router-dom'
import { NotificationsOutlinedIcon } from '../../Assets'
import { useWindowDimensions } from '../../Hooks'
import { AvatarCmp } from '..'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAlert from '../../Hooks/useAlert/useAlert'
import { LoaderCmp } from '..'
import { GetNotificationCount } from '../../Store/Actions/NotificationAction'
import BuildLinkLogo from '../../Assets/Images/BuildLinkCompleteLogo.png'

const Navbar = () => {
    const { profileDetails, profileDetailsLoading } = useSelector(
        (state) => state.UserProfileReducer
    )

    const { width } = useWindowDimensions()

    return width >= '768' ? (
        <AppNavBar
            profileDetails={profileDetails}
            profileDetailsLoading={profileDetailsLoading}
        />
    ) : (
        <AppMobileNavBar
            profileDetails={profileDetails}
            profileDetailsLoading={profileDetailsLoading}
        />
    )
}

const goToShop = () => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const shopUserData = {
        company_name: userData.company_name,
        all_roles: userData.all_roles,
        name: userData.name,
        email: userData.email,
        company_email: userData.company_email,
        token: userData.token,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone: userData.phone,
        street_address: userData.street_address,
        zip: userData.zip,
    }

    const str = `?data=${encodeURIComponent(JSON.stringify(shopUserData))}`
    window.location.href = `https://shop.buildlink.com/login${str}`
}

const AppNavBar = ({ profileDetails, profileDetailsLoading }) => {
    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    useEffect(() => {
        dispatch(GetNotificationCount(setAlert))
    }, [])

    const { all_roles } = profileDetails || []

    return (
        <AppBar
            position="static"
            sx={{
                background: 'white',
            }}
        >
            <Box
                sx={{
                    padding: '0.8rem',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                    }}
                >
                    <img
                        src={BuildLinkLogo}
                        alt="icon"
                        className={styles.menuIcon}
                        width={100}
                        height={40}
                        style={{
                            objectFit: 'cover',
                            marginRight: '2rem',
                        }}
                    />
                    <NavLink
                        to="/dashboard"
                        className={({ isActive }) =>
                            isActive
                                ? styles.activeClassName
                                : styles.inActiveClassName
                        }
                    >
                        <ListItemButton>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </NavLink>

                    {all_roles &&
                        (all_roles.includes('Pro Connection - Connections') ||
                            all_roles.includes(
                                'Pro Connection - My Project Gallery'
                            )) && (
                            <NavLink
                                to="/buildlink/proconnection"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                            >
                                <ListItemButton>
                                    <ListItemText primary="Pro Connections" />
                                </ListItemButton>
                            </NavLink>
                        )}
                    {all_roles &&
                        (all_roles.includes('Build Bid - Jobs') ||
                            all_roles.includes('Build Bid - My Bids')) && (
                            <NavLink
                                to="/buildbid/jobs"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                            >
                                <ListItemButton>
                                    <ListItemText primary="Build Bid" />
                                </ListItemButton>
                            </NavLink>
                        )}
                    {all_roles && all_roles.includes('Build Studio - Jobs') && (
                        <NavLink
                            to="/buildstudio/jobs"
                            className={({ isActive }) =>
                                isActive
                                    ? styles.activeClassName
                                    : styles.inActiveClassName
                            }
                        >
                            <ListItemButton>
                                <ListItemText primary="Build Studio" />
                            </ListItemButton>
                        </NavLink>
                    )}

                    {all_roles &&
                        (all_roles.includes('Shop - Products') ||
                            all_roles.includes('Shop - Orders')) && (
                            <NavLink
                                to="/buildshop/viewproduct"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                            >
                                <ListItemButton>
                                    <ListItemText primary="Build Shop" />
                                </ListItemButton>
                            </NavLink>
                        )}

                    {all_roles &&
                        all_roles.includes('Linksurance - Orders') && (
                            <NavLink
                                to="/buildinsurance/orders/general"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                            >
                                <ListItemButton>
                                    <ListItemText primary="Linksurance" />
                                </ListItemButton>
                            </NavLink>
                        )}

                    <NavLink
                        to="/chat"
                        className={({ isActive }) =>
                            isActive
                                ? styles.activeClassName
                                : styles.inActiveClassName
                        }
                    >
                        <ListItemButton>
                            <ListItemText primary="Chat" />
                        </ListItemButton>
                    </NavLink>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                ></Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <section className={styles.shoplink}>
                        <ShoppingCartOutlinedIcon
                            sx={{ color: 'white', marginRight: '0.2rem' }}
                            fontSize="small"
                        />
                        <a onClick={goToShop}>Go to Shop</a>
                    </section>
                    <section className={styles.navIconWrapper}>
                        <div>
                            <NotificationsOutlinedIcon fontSize={'small'} />
                        </div>
                    </section>
                    <div className={styles.navAvatarName}>
                        {profileDetailsLoading ? (
                            <LoaderCmp />
                        ) : (
                            <>
                                <p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
                                <small>{profileDetails?.type}</small>
                            </>
                        )}
                    </div>
                    <AvatarCmp
                        img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
                        firstLetter={profileDetails?.first_name}
                    />
                </Box>
            </Box>
        </AppBar>
    )
}

const AppMobileNavBar = ({ profileDetails, profileDetailsLoading }) => {
    const dispatch = useDispatch()
    const { setAlert } = useAlert()

    useEffect(() => {
        dispatch(GetNotificationCount(setAlert))
    }, [])
    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleNavbarOpen = () => {
        setNavbarOpen(!navbarOpen)
    }

    const closeMenu = () => {
        setNavbarOpen(false)
    }
    const { all_roles } = profileDetails || []

    return (
        <AppBar
            position={navbarOpen ? 'absolute' : 'static'}
            sx={{
                background: 'white',
            }}
        >
            <Box
                sx={{
                    padding: '0.8rem',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <img
                    src={BuildLinkLogo}
                    alt="icon"
                    className={styles.menuIcon}
                    width={100}
                    height={40}
                    style={{
                        objectFit: 'cover',
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <section className={styles.navIconWrapper}>
                        <div>
                            <NotificationsOutlinedIcon fontSize={'small'} />
                        </div>
                    </section>
                    <IconButton onClick={handleNavbarOpen}>
                        <img src={MenuIconRight} alt="icon" />
                    </IconButton>
                </Box>
            </Box>

            {navbarOpen && (
                <Box
                    sx={{
                        margin: '0.8rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: 'calc(100vh - 70px)',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <div className={styles.navAvatarName}>
                            {profileDetailsLoading ? (
                                <LoaderCmp />
                            ) : (
                                <>
                                    <p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
                                    <small>{profileDetails?.type}</small>
                                </>
                            )}
                        </div>
                        <AvatarCmp
                            img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
                            firstLetter={profileDetails?.first_name}
                        />
                    </Box>

                    <NavLink
                        to="/dashboard"
                        className={({ isActive }) =>
                            isActive
                                ? styles.activeClassName
                                : styles.inActiveClassName
                        }
                        onClick={closeMenu}
                    >
                        <ListItemButton>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </NavLink>
                    {all_roles &&
                        (all_roles.includes('Pro Connection - Connections') ||
                            all_roles.includes(
                                'Pro Connection - My Project Gallery'
                            )) && (
                            <NavLink
                                to="/buildlink/proconnection"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                                onClick={closeMenu}
                            >
                                <ListItemButton>
                                    <ListItemText primary="Pro Connections" />
                                </ListItemButton>
                            </NavLink>
                        )}
                    {all_roles &&
                        (all_roles.includes('Build Bid - Jobs') ||
                            all_roles.includes('Build Bid - My Bids')) && (
                            <NavLink
                                to="/buildbid/jobs"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                                onClick={closeMenu}
                            >
                                <ListItemButton>
                                    <ListItemText primary="Build Bid" />
                                </ListItemButton>
                            </NavLink>
                        )}
                    {all_roles && all_roles.includes('Build Studio - Jobs') && (
                        <NavLink
                            to="/buildstudio/jobs"
                            className={({ isActive }) =>
                                isActive
                                    ? styles.activeClassName
                                    : styles.inActiveClassName
                            }
                            onClick={closeMenu}
                        >
                            <ListItemButton>
                                <ListItemText primary="Build Studio" />
                            </ListItemButton>
                        </NavLink>
                    )}

                    {all_roles &&
                        (all_roles.includes('Shop - Products') ||
                            all_roles.includes('Shop - Orders')) && (
                            <NavLink
                                to="/buildshop/viewproduct"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                                onClick={closeMenu}
                            >
                                <ListItemButton>
                                    <ListItemText primary="Build Shop" />
                                </ListItemButton>
                            </NavLink>
                        )}

                    {all_roles &&
                        all_roles.includes('Linksurance - Orders') && (
                            <NavLink
                                to="/buildinsurance/orders/general"
                                className={({ isActive }) =>
                                    isActive
                                        ? styles.activeClassName
                                        : styles.inActiveClassName
                                }
                                onClick={closeMenu}
                            >
                                <ListItemButton>
                                    <ListItemText primary="Linksurance" />
                                </ListItemButton>
                            </NavLink>
                        )}

                    <NavLink
                        to="/chat"
                        className={({ isActive }) =>
                            isActive
                                ? styles.activeClassName
                                : styles.inActiveClassName
                        }
                        onClick={closeMenu}
                    >
                        <ListItemButton>
                            <ListItemText primary="Chat" />
                        </ListItemButton>
                    </NavLink>
                </Box>
            )}
        </AppBar>
    )
}

export default Navbar
