// import React from "react";
// import Lottie from "react-lottie";
import { EmptyAnimation } from '../../Assets'

// const LottieFilesCmp = ({ animation }) => {
//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: EmptyAnimation,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };
//   return (
//     <div>
//       <Lottie options={defaultOptions} height={400} width={400} />
//     </div>
//   );
// };

// export default LottieFilesCmp;
import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { useRef } from 'react'

const LottieFilesCmp = ({ animation, style }) => {
    const lottieRef = useRef(null)

    const handleEvent = (e) => {
        if (e === 'load') {
            lottieRef.current?.play()
        }
    }
    return (
        <>
            <Player
                autoplay
                loop={true}
                src={animation}
                style={{ height: '300px', width: '300px', ...style }}
                onEvent={handleEvent}
                ref={lottieRef}
            ></Player>
        </>
    )
}

export default LottieFilesCmp
