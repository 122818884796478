import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import TextTruncate from 'react-text-truncate'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import {
    BidIcon,
    BiEdit,
    LocationOnOutlinedIcon,
    RiDeleteBin6Line,
    EventAvailableOutlinedIcon,
    EventBusyOutlinedIcon,
} from '../../../Assets'
// import { postedDuration } from "../../Utils/utils";
// import { DeleteJobAction } from "../../Store/Actions/BuildBidsJobAction";
import styles from './ProjectListView.module.css'
import ScrollableTabs from '../../ScrollAbleTabs/ScrollAbleTabs'
import IconCmp from '../../IconCmp/IconCmp'
import { postedDuration } from '../../../Utils/utils'
import { DeleteBuildStudioProject } from '../../../Store/Actions/BuildStudio/BuildStudioAction'
// import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from "..";

const ProjectListView = ({
    deleteBtn,
    color,
    path,
    setAlert,
    projectDetails,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [paraLine, setParaLine] = useState(true)
    const [hover, setHover] = useState(false)

    const paraHandler = (e) => {
        e.stopPropagation()
        setParaLine(!paraLine)
    }

    const hoverHandler = (event) => {
        if (event === 'enter') {
            setHover(true)
            return
        }
        setHover(false)
    }

    const editJob = (e) => {
        e.stopPropagation()
        console.log('edit')
        navigate(`/buildstudio/editbsproject/${slug}`)
    }

    const {
        title,
        created_at,
        city,
        state,
        address,
        zipcode,
        services_count,
        description,
        services,
        slug,
    } = projectDetails || {}

    const deleteJob = (e) => {
        e.stopPropagation()
        dispatch(DeleteBuildStudioProject(slug, setAlert))
    }

    return (
        <section
            className={styles.jobCardWrapper}
            onMouseEnter={() => hoverHandler('enter')}
            onMouseLeave={() => hoverHandler('leave')}
            onClick={() => {
                navigate(`jobdetail/${slug}`)
            }}
        >
            <Box
                className={styles.cardHeader}
                // onClick={() => navigate(`${path}/${slug}`)}
            >
                <Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                            {title || 'title'}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {postedDuration(created_at)}
                    </Typography>
                </Box>
                <section className={styles.cardmodifyBtn}>
                    <Box
                        sx={{
                            //   backgroundColor: "var(--editBtnLight)",
                            padding: '5px 10px',
                            borderRadius: '5px',
                            color: 'var(--milestoneText)',
                        }}
                        className={styles.editbtn}
                        onClick={editJob}
                    >
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Typography fontSize={'12px'}>
                                Edit Project
                            </Typography>
                        </Box>
                        <div className={styles.editBtnIcon}>
                            <IconCmp>
                                <BiEdit />
                            </IconCmp>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            padding: '5px 10px',
                            borderRadius: '5px',
                        }}
                        className={styles.deleteBtn}
                    >
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Typography fontSize={'12px'}>
                                Delete Project
                            </Typography>
                        </Box>
                        <div
                            className={styles.deleteBtnIcon}
                            onClick={(e) => deleteJob(e)}
                        >
                            <IconCmp>
                                <RiDeleteBin6Line />
                            </IconCmp>
                        </div>
                    </Box>
                </section>
            </Box>
            <section
                className={styles.headerList}
                // onClick={() => navigate(`${path}/${slug}`)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        // ml: index !== 0 && "20px",
                    }}
                    className={styles.jobDetails}
                >
                    <LocationOnOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--green)',
                            ml: '-5px',
                            fontSize: '18px',
                        }}
                    />
                    <small>
                        {address}, {state?.label}, {zipcode}
                    </small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        my: '5px',
                        ml: '20px',
                    }}
                    className={styles.jobDetails}
                >
                    <EventAvailableOutlinedIcon
                        sx={{
                            marginRight: ' 5px',
                            color: 'var(--primary-color)',
                            fontSize: '18px',
                        }}
                    />
                    <small>Create Date: {created_at}</small>
                </Box>
                <Box
                    sx={{
                        backgroundColor: 'var(--light-green)',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        color: 'var(--green)',
                        ml: 'auto',
                    }}
                    alignSelf={'flex-end'}
                    className={styles.cardSeriveWrapper}
                >
                    <div>
                        <Typography fontSize={'12px'}>
                            {services_count} Services required
                        </Typography>
                    </div>
                </Box>
            </section>
            <section
                className={styles.cardBody}
                // onClick={() => navigate(`${path}/${slug}`)}
            >
                <TextTruncate
                    line={paraLine && 3}
                    element="span"
                    truncateText="…"
                    text={description}
                    // textTruncateChild={}
                    style={{ color: 'var(--textColor)', fontSize: 'medium' }}
                />
            </section>
            <small className={styles.paraMoreBtn} onClick={paraHandler}>
                {paraLine ? 'More' : 'Less'}
            </small>
            <section className={styles.cardFooter}>
                <div>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Services:
                    </Typography>
                </div>
                <ScrollableTabs
                    bgColor={color}
                    hover={hover}
                    servicesTags={services}
                />
            </section>
            {/* {deleteBtn && (
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", my: 2, gap: 2 }}
        >
        
          <WhiteButtonCmp
            title="Permanent Delete Job"
            style={{ borderColor: " red !important", color: "red" }}
          />
          <ButtonCmp
            style={{ width: "max-content", padding: "10px 15px" }}
            title="Restore Job"
          />
        </Box>
      )} */}
        </section>
    )
}

export default ProjectListView
