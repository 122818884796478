import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import styles from './ProfileSelection.module.css'
import { BuildLinkLogo } from '../../Assets'
import TocIcon from '@mui/icons-material/Toc'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { BASE_URL } from '../../Utils/response'
import { getUserToken } from '../../Utils/LoginAuth'
import useAlert from '../../Hooks/useAlert/useAlert'

import { useNavigate, useParams, useLocation } from 'react-router-dom'

import Avatar from '../../Assets/Images/no_image.png'
import { ButtonCmp } from '../../Components'

const FormNavBar = () => {
    return (
        <section className={styles.navbarWrapper}>
            <div className={styles.navbarLogo}>
                <img src={BuildLinkLogo} alt="" />
            </div>
            <div className={styles.navOtherOptions}>
                <section className={styles.navAvatar}>
                    <div className={styles.navAvatarName}></div>
                    <Link to="/profile">
                        <img src={Avatar} alt="" width="32px" />
                    </Link>
                </section>
                <section className={styles.navProfile}>
                    <TocIcon />
                </section>
            </div>
        </section>
    )
}

const ProfileSelection = () => {
    const [selectedOption, setSelectedOption] = useState('')

    const handleChange = (event) => {
        setSelectedOption(event.target.value)
    }
    const { setAlert } = useAlert()
    const navigate = useNavigate()

    const getHeader = () => {
        return {
            Authorization: 'Bearer' + ' ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }

    const handleSubmit = () => {
        const formData = new FormData()
        formData.append('type', selectedOption)

        axios
            .post(`${BASE_URL}/user/change-type`, formData, {
                headers: getHeader(),
            })
            .then((response) => {
                setAlert('Selection Saved', 'success')
                navigate(`/profilecompletion`)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setAlert(
                    error.response.data.message || 'An error occurred',
                    'error'
                )
            })
            .finally(() => {})
    }

    return (
        <Box>
            <FormNavBar />
            <Box sx={{ padding: '2rem' }}>
                <Box sx={{ padding: '2rem' }}>
                    <Typography variant="h3" fontWeight={'bold'}>
                        Welcome to BuildLink
                    </Typography>
                    <Typography>
                        Find Perfect Contractors and Sub Contractor to complete
                        you projects.
                    </Typography>
                </Box>
                <Box sx={{ padding: '1rem', backgroundColor: '#f1f5f9' }}>
                    <Box sx={{ paddingLeft: '2rem' }}>
                        <Typography variant="h5" fontWeight={'bold'}>
                            Select your User type
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '2rem',
                            height: '40vh',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'white',
                                padding: '4rem',
                                width: '40rem',
                            }}
                        >
                            <input
                                type="radio"
                                value="Pro"
                                checked={selectedOption === 'Pro'}
                                onChange={handleChange}
                            />
                            <Typography
                                variant="h3"
                                fontWeight={'bold'}
                                color={'#faa501'}
                            >
                                Pro
                            </Typography>
                            <Typography fontWeight={'bold'}>
                                Access to all features
                            </Typography>
                            <Typography sx={{ marginTop: '1rem' }}>
                                A Pro user has access to all features.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                bgcolor: 'white',
                                padding: '4rem',
                                width: '40rem',
                            }}
                        >
                            <input
                                type="radio"
                                value="Insurance Agent"
                                checked={selectedOption === 'Insurance Agent'}
                                onChange={handleChange}
                            />
                            <Typography
                                variant="h3"
                                fontWeight={'bold'}
                                color={'#faa501'}
                            >
                                {' '}
                                Insurance Agent
                            </Typography>
                            <Typography fontWeight={'bold'}>
                                This is specifically for Insurance Agents
                            </Typography>
                            <Typography sx={{ marginTop: '1rem' }}>
                                An Insurnace Agent user has access to
                                linksurance features
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            margin: '1rem',
                        }}
                    >
                        <ButtonCmp title={'Continue'} onClick={handleSubmit} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ProfileSelection
