import React, { useEffect, useState } from 'react'

import './bodyLayout.css'
import RightArrow from '../../Assets/Icons/Path - chevron-down.png'
import LeftArrow from '../../Assets/Icons/leftchevron-down -.png'
import { useWindowDimensions } from '../../Hooks'

const BodyLayout = ({ children, sideBar, byDefaultClose }) => {
    const [sideBarClass, setSideBarClass] = useState(false)
    const { width } = useWindowDimensions()

    const rightSideBarHandler = () => {
        setSideBarClass(!sideBarClass)
    }

    useEffect(() => {
        if (width <= 950) {
            return setSideBarClass(true)
        }
    }, [width])

    useEffect(() => {
        if (byDefaultClose) {
            setSideBarClass(true)
        }
    }, [])

    return (
        <div className={'bodyWrapper'}>
            <section
                style={{ width: !sideBar && '100%' }}
                className={`bodyContentWrapper  ${sideBarClass && 'close'}`}
            >
                {children}

                {sideBar && sideBarClass && (
                    <div
                        onClick={rightSideBarHandler}
                        className={'leftarrowBox'}
                    >
                        <img src={LeftArrow} alt="" />
                    </div>
                )}
            </section>
            {sideBar && (
                <section className={`rightSideBar ${sideBarClass && 'close'}`}>
                    {sideBar}
                    <div onClick={rightSideBarHandler} className={'arrowBox'}>
                        <img src={RightArrow} alt="" />
                    </div>
                </section>
            )}
        </div>
    )
}

export default BodyLayout
