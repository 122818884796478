import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ProductTabPanel from './ProductTabPanel'
import { useDispatch } from 'react-redux'
import styles from './AddProduct.module.css'
import { InputCmp } from '../../../Components'

const OtherTab = (props) => {
    const { tabValue, control } = props

    return (
        <ProductTabPanel value={tabValue} index={4}>
            <section className={styles.productIntro_wrapper}>
                <Grid container columnSpacing={2} rowSpacing={5}>
                    <Grid item lg={4}>
                        <InputCmp name="type" control={control} label="Type" />
                    </Grid>
                    <Grid item lg={4}>
                        <InputCmp
                            name="installer"
                            control={control}
                            label="Installer"
                        />
                    </Grid>
                    {/* <Grid
						item
						lg={4}
					>
						<InputCmp
							name="sku"
							control={control}
							label="SKU"
						/>
					</Grid> */}
                </Grid>
            </section>
        </ProductTabPanel>
    )
}

export default OtherTab
