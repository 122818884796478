import React from 'react'
import InsuranceListViewCard from './InsuranceListViewCard'
import { BodyLayout } from '../../Components'
import { Box, Typography } from '@mui/material'
import { BASE_URL } from '../../Utils/response'
import { getUserToken } from '../../Utils/LoginAuth'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

const InsuranceList = () => {
    const getHeader = () => {
        return {
            Authorization: 'Bearer ' + getUserToken(),
            'content-type': 'multipart/form-data',
        }
    }
    const [orders, setOrders] = useState([])
    // console.log(orders);
    const { status } = useParams()
    // console.log(status)

    useEffect(() => {
        axios
            .get(`${BASE_URL}/user-verifications-orders?status=${status}`, {
                headers: getHeader(),
            })
            .then((response) => {
                setOrders(response.data.data)
            })
    }, [status])
    return (
        <div
            style={{
                backgroundColor: '#f1f5f9',
            }}
        >
            <BodyLayout sideBar={false}>
                <section
                    style={{
                        marginTop: '20px',
                    }}
                >
                    {orders.records &&
                        orders.records.map((order) => (
                            <Box
                                sx={{
                                    width: '90%',
                                    margin: 'auto',
                                }}
                            >
                                <InsuranceListViewCard order={order} />
                            </Box>
                        ))}
                </section>
                <section>
                    {!orders.records && (
                        <Box
                            sx={{
                                width: '100%',
                                backgroundColor: '#f1f5f9',
                                marginTop: '20px',
                            }}
                        >
                            <Typography
                                fontSize="16px"
                                sx={{
                                    color: 'var(--black)',
                                    fontWeight: 'bold',
                                }}
                            >
                                No orders right now
                            </Typography>
                        </Box>
                    )}
                </section>
            </BodyLayout>
        </div>
    )
}

export default InsuranceList
