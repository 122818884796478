import ActionTypes from '../../Constants'

const INITIALSTATE = {
    // BS all Projects
    buildStudioProjectLoading: false,
    buildStudioProjectdata: '',
    buildStudioProjectError: '',

    ///get bs project
    deleteBSProjectLoading: false,
    isDeleteBSProject: false,
    deleteBSProjectError: false,

    //get delete bs project
    getDeleteBSProjectLoading: false,
    getDeleteBSProjectdata: '',
    getDeleteBSProjectError: '',

    //restore delete bs project
    restoreDeleteBSProjectLoading: false,
    isRestoreDeleteBSProject: false,
    restoreDeleteBSProjectError: false,

    //permanent delete bs project
    permanentDeleteBSProjectLoading: false,
    isPermanentDeleteBSProject: false,
    permanentDeleteBSProjectError: false,

    // BS single  Projects get
    singleBSProjectLoading: false,
    singleBSProjectdata: '',
    singleBSProjectError: '',

    // Get Service Symbols
    getServiceSymbolsLoading: false,
    getServiceSymbolsdata: '',
    getServiceSymbolsError: '',

    // Get All Product Symbols
    getAllProductLoading: false,
    getAllProductdata: '',
    getAllProductError: '',

    // GSave services data
    saveServicesLoading: false,

    //create BS-project
    createBSProjectLoading: false,
    createBSProjectdata: '',
    createBSProjectError: '',

    //uploadSinglePDF
    uploadSinglePDFLoading: false,
    isUploadSinglePDF: '',
    uploadSinglePDFError: '',

    //get single BS project
    getSingleBsProjectLoading: false,
    getSingleBsProjectdata: '',
    getSingleBsProjectError: '',

    //edit BS-project
    editBSProjectLoading: false,
    editBSProjectdata: '',
    editBSProjectError: '',
}

const BuildStduioProjectReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_BUILDSTUDIO_PROJECTS_REQUEST:
            return {
                ...state,
                buildStudioProjectLoading: true,
            }
        case ActionTypes.GET_BUILDSTUDIO_PROJECTS_SUCCESS:
            return {
                ...state,
                buildStudioProjectLoading: false,
                buildStudioProjectdata: action.payload.data,
                buildStudioProjectError: '',
            }
        case ActionTypes.GET_BUILDSTUDIO_PROJECTS_FAIL:
            return {
                ...state,
                buildStudioProjectLoading: false,
                buildStudioProjectdata: '',
                buildStudioProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const DeleteBuildStduioProjectReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_REQUEST:
            return {
                ...state,
                deleteBSProjectLoading: true,
            }
        case ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_SUCCESS:
            return {
                ...state,
                deleteBSProjectLoading: false,
                isDeleteBSProject: !state.isDeleteBSProject,
                deleteBSProjectError: '',
            }
        case ActionTypes.DELETE_BUILDSTUDIO_PROJECTS_FAIL:
            return {
                ...state,
                deleteBSProjectLoading: false,
                deleteBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const GetDeleteBSProjectReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_DELETE_BS_PROJECTS_REQUEST:
            return {
                ...state,
                getDeleteBSProjectLoading: true,
            }
        case ActionTypes.GET_DELETE_BS_PROJECTS_SUCCESS:
            return {
                ...state,
                getDeleteBSProjectLoading: false,
                getDeleteBSProjectdata: action.payload.data,
                getDeleteBSProjectError: '',
            }
        case ActionTypes.GET_DELETE_BS_PROJECTS_FAIL:
            return {
                ...state,
                getDeleteBSProjectLoading: false,
                getDeleteBSProjectdata: '',
                getDeleteBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const RestoreBuildStduioProjectReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.RESTORE_DELETE_BS_PROJECTS_REQUEST:
            return {
                ...state,
                restoreDeleteBSProjectLoading: true,
            }
        case ActionTypes.RESTORE_DELETE_BS_PROJECTS_SUCCESS:
            return {
                ...state,
                restoreDeleteBSProjectLoading: false,
                isRestoreDeleteBSProject: !state.isRestoreDeleteBSProject,
                restoreDeleteBSProjectError: '',
            }
        case ActionTypes.RESTORE_DELETE_BS_PROJECTS_FAIL:
            return {
                ...state,
                restoreDeleteBSProjectLoading: false,
                restoreDeleteBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const PermanentBuildStduioProjectReducers = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.PARMANENT_DELETE_BS_PROJECTS_REQUEST:
            return {
                ...state,
                permanentDeleteBSProjectLoading: true,
            }
        case ActionTypes.PARMANENT_DELETE_BS_PROJECTS_SUCCESS:
            return {
                ...state,
                permanentDeleteBSProjectLoading: false,
                isPermanentDeleteBSProject: !state.isPermanentDeleteBSProject,
                permanentDeleteBSProjectError: '',
            }
        case ActionTypes.PARMANENT_DELETE_BS_PROJECTS_FAIL:
            return {
                ...state,
                permanentDeleteBSProjectLoading: false,
                permanentDeleteBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const GetSingleBSProjectReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_SINGLE_BS_PROJECT_REQUEST:
            return {
                ...state,
                singleBSProjectLoading: true,
            }
        case ActionTypes.GET_SINGLE_BS_PROJECT_SUCCESS:
            return {
                ...state,
                singleBSProjectLoading: false,
                singleBSProjectdata: action.payload.data,
                singleBSProjectError: '',
            }
        case ActionTypes.GET_SINGLE_BS_PROJECT_FAIL:
            return {
                ...state,
                singleBSProjectLoading: false,
                singleBSProjectdata: '',
                singleBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const GetServiceSymbolsReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_SERVICE_SYMBOL_REQUEST:
            return {
                ...state,
                getServiceSymbolsLoading: true,
            }
        case ActionTypes.GET_SERVICE_SYMBOL_SUCCESS:
            return {
                ...state,
                getServiceSymbolsLoading: false,
                getServiceSymbolsdata: action.payload.data.symbols,
                getServiceSymbolsError: '',
            }
        case ActionTypes.GET_SERVICE_SYMBOL_FAIL:
            return {
                ...state,
                getServiceSymbolsLoading: false,
                getServiceSymbolsdata: '',
                getServiceSymbolsError: action.payload.message,
            }
        default:
            return state
    }
}

const GetAllProductsReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_PRODUCTS_REQUEST:
            return {
                ...state,
                getAllProductLoading: true,
            }
        case ActionTypes.GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                getAllProductLoading: false,
                getAllProductdata: action.payload.data,
                getAllProductError: '',
            }
        case ActionTypes.GET_ALL_PRODUCTS_FAIL:
            return {
                ...state,
                getAllProductLoading: false,
                getAllProductdata: '',
                getAllProductError: action.payload.message,
            }
        default:
            return state
    }
}
const saveServicesDataReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.SET_PDF_DESIGNER_DATA:
            return {
                ...state,
                saveServicesLoading: true,
            }
        case ActionTypes.SET_PDF_DESIGNER_DATA_SUCCESS:
            return {
                ...state,
                saveServicesLoading: false,
            }
        case ActionTypes.SET_PDF_DESIGNER_DATA_FAIL:
            return {
                ...state,
                saveServicesLoading: false,
            }
        default:
            return state
    }
}

const CreateBSProjectReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_BS_PROJECT_REQUEST:
            return {
                ...state,
                createBSProjectLoading: true,
            }
        case ActionTypes.CREATE_BS_PROJECT_SUCCESS:
            return {
                ...state,
                createBSProjectLoading: false,
                createBSProjectdata: action.payload.data,
                createBSProjectError: '',
            }
        case ActionTypes.CREATE_BS_PROJECT_FAIL:
            return {
                ...state,
                createBSProjectLoading: false,
                createBSProjectdata: '',
                createBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const UploadSinglePDFReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.UPLOAD_SINGLE_PDF_REQUEST:
            return {
                ...state,
                uploadSinglePDFLoading: true,
            }
        case ActionTypes.UPLOAD_SINGLE_PDF_SUCCESS:
            return {
                ...state,
                uploadSinglePDFLoading: false,
                isUploadSinglePDF: !state.isUploadSinglePDF,
                uploadSinglePDFError: '',
            }
        case ActionTypes.UPLOAD_SINGLE_PDF_FAIL:
            return {
                ...state,
                uploadSinglePDFLoading: false,
                uploadSinglePDFError: action.payload.message,
            }
        default:
            return state
    }
}

const GetBSProjectReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_BS_PROJECT_REQUEST:
            return {
                ...state,
                getSingleBsProjectLoading: true,
            }
        case ActionTypes.GET_BS_PROJECT_SUCCESS:
            return {
                ...state,
                getSingleBsProjectLoading: false,
                getSingleBsProjectdata: action.payload.data,
                getSingleBsProjectError: '',
            }
        case ActionTypes.GET_BS_PROJECT_FAIL:
            return {
                ...state,
                getSingleBsProjectLoading: false,
                getSingleBsProjectdata: [],
                getSingleBsProjectError: action.payload.message,
            }
        default:
            return state
    }
}

const EditBSProjectReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.EDIT_BS_PROJECT_REQUEST:
            return {
                ...state,
                editBSProjectLoading: true,
            }
        case ActionTypes.EDIT_BS_PROJECT_SUCCESS:
            return {
                ...state,
                editBSProjectLoading: false,
                editBSProjectdata: action.payload.data,
                editBSProjectError: '',
            }
        case ActionTypes.EDIT_BS_PROJECT_FAIL:
            return {
                ...state,
                editBSProjectLoading: false,
                editBSProjectdata: [],
                editBSProjectError: action.payload.message,
            }
        default:
            return state
    }
}

export {
    BuildStduioProjectReducers,
    DeleteBuildStduioProjectReducers,
    GetDeleteBSProjectReducers,
    RestoreBuildStduioProjectReducers,
    PermanentBuildStduioProjectReducers,
    GetSingleBSProjectReducer,
    GetServiceSymbolsReducer,
    GetAllProductsReducer,
    saveServicesDataReducer,
    CreateBSProjectReducer,
    UploadSinglePDFReducer,
    GetBSProjectReducer,
    EditBSProjectReducer,
}
