import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import TextTruncate from "react-text-truncate";
// import moment from "moment";

// import {
//   BidIcon,
//   BiEdit,
//   LocationOnOutlinedIcon,
//   RiDeleteBin6Line,
//   EventAvailableOutlinedIcon,
//   EventBusyOutlinedIcon,
// } from "../../Assets";
// import { postedDuration } from "../../Utils/utils";
// import { DeleteJobAction } from "../../Store/Actions/BuildBidsJobAction";
import styles from './ListView.module.css'
// import { ButtonCmp, ScrollableTabs, WhiteButtonCmp } from "..";
import {
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material'

const InsuranceListViewCard = ({ deleteBtn, color, path, order, setAlert }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const slug = 'ad'

    //   const [paraLine, setParaLine] = useState(true);
    //   const [hover, setHover] = useState(false);

    //   const paraHandler = (e) => {
    //     e.stopPropagation();
    //     setParaLine(!paraLine);
    //   };

    //   const hoverHandler = (event) => {
    //     if (event ===  "enter") {
    //       setHover(true);
    //       return;
    //     }
    //     setHover(false);
    //   };

    const { requested_for_user, requested_by_user } = order

    return (
        <>
            <div
                onClick={() => {
                    navigate(`../orders/detail/${order.ref_number}`, {
                        state: order,
                    })
                }}
            >
                <List
                    sx={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <ListItem
                        sx={{
                            backgroundColor: 'white',
                            padding: '10px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            borderRadius: '10px',
                            margin: '0 !important',
                            borderBottom: '2px solid #f1f5f9',
                        }}
                        disablePadding
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={`${requested_for_user?.name}`} />
                        <ListItemText primary={`Supplier`} />
                        <ListItemText primary={`New Orleans, La`} />
                        <ListItemText primary={`${order.status}`} />
                        <ListItemText
                            primary={`${order.requested_for_user.certificates?.length} Certificates`}
                        />
                    </ListItem>
                </List>
            </div>
        </>
    )
}

export default InsuranceListViewCard
