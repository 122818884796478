import React from 'react'
import Navbar from './Components/Navbar'
import AboutImg from '../../Assets/websiteImages/img_72.png'
import { Box, Container, Grid, Typography } from '@mui/material'
import Footer from './Components/Footer'

const AboutPage = () => {
    return (
        <>
            <Navbar />
            <Box sx={{ mt: '150px' }}>
                {/* <Container maxWidth="lg" sx={{ background: "red" }}> */}
                <Grid
                    container
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '90vh',
                    }}
                >
                    <Grid item lg={5}>
                        <img src={AboutImg} alt="" />
                    </Grid>

                    <Grid item lg={5}>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                padding: '36px 0 28px',
                            }}
                        >
                            Over &nbsp;
                            <span
                                style={{
                                    color: '#000',
                                    textDecoration: 'underline',
                                }}
                            >
                                150,000+ client
                            </span>
                        </Typography>

                        <Typography
                            sx={{
                                color: '#000',
                                fontSize: '48px',
                                lineHeight: '1.59em',
                                paddingBottom: '40px',
                            }}
                        >
                            We created over 27,000+ stunning and quality
                            products over last 5 years with satisfaction.
                        </Typography>

                        <Typography
                            sx={{
                                color: '#000',
                                fontSize: '20px',
                                fontStyle: 'italic',
                                fontWeight: '500',
                                marginTop: '32px',
                                paddingLeft: '30px',
                                position: 'relative',
                            }}
                        >
                            ------ Carl Henderson
                        </Typography>
                    </Grid>
                </Grid>
                {/* </Container> */}
                <Container sx={{ mt: '100px' }}>
                    <Footer />
                </Container>
            </Box>
        </>
    )
}

export default AboutPage
