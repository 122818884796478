import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import { useNavigate } from 'react-router-dom'
import { IconCmp } from '..'
import {
    AppliedIcon,
    EmptyAnimation,
    LocationOnOutlinedIcon,
} from '../../Assets'
import { LottieFilesCmp } from '../../Components'
import styles from '../BidsListCard/BidsGridCard.module.css'

const AppliedProjectGridCard = ({
    whishList,
    applied,
    path,
    myBidsAppliedProjectsData,
}) => {
    return (
        <>
            {myBidsAppliedProjectsData &&
            myBidsAppliedProjectsData.length === 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <LottieFilesCmp animation={EmptyAnimation} />
                    <Typography variant="h5" fontWeight="bold">
                        You have not yet applied for any jobs
                    </Typography>
                </Box>
            ) : (
                <Grid container className={styles.jobGridCardWrapper}>
                    {myBidsAppliedProjectsData.map((jobs, index) => {
                        return (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card
                                    whishList={whishList}
                                    color={
                                        index % 2 === 0
                                            ? 'white'
                                            : 'var(--bgColor)'
                                    }
                                    applied={applied}
                                    path={path}
                                    myBidsAppliedProjectsData={jobs}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </>
    )
}

const Card = ({ path, myBidsAppliedProjectsData }) => {
    const navigate = useNavigate()

    const {
        label,
        address,
        slug,
        zipcode,
        biding_end_date,
        biding_start_date,
        user,
    } = myBidsAppliedProjectsData.project
    return (
        <div
            className={styles.gridviewCardWrapper}
            onClick={() =>
                navigate(`${path}/${slug}/${myBidsAppliedProjectsData.id}`)
            }
        >
            <div className={styles.cardInfo}>
                <section className={styles.cardInfoDetails}>
                    <Typography variant="h5" className={styles.jobName}>
                        {label}
                    </Typography>
                    <Typography
                        sx={{ fontSize: 'small', color: 'var(--textColor)' }}
                    >
                        {/* Posted -
            {" " + moment(created_at).startOf("data").fromNow(created_at) + " "}
            ago */}
                        {/* Jay rutter Construction */}
                        {user?.company_name ? user.company_name : user.label}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                        }}
                    >
                        <LocationOnOutlinedIcon
                            fontSize="10px"
                            sx={{
                                marginRight: '5px',
                                ml: '-3px',
                                color: 'var(--primary-color)',
                            }}
                        />
                        <small>{address + ',' + zipcode}</small>
                    </Box>
                </section>
                <Box className={styles.whishList}>
                    <Box sx={{ mr: '15px' }}>
                        <Typography fontSize={'medium'}>Applied</Typography>
                    </Box>
                    <IconCmp style={{ background: 'var(--green)' }}>
                        {/* <AppliedIcon sx={{ color: "white" }} /> */}
                        <img src={AppliedIcon} width="20" alt="" />
                    </IconCmp>
                </Box>
            </div>
            <Box className={styles.cardInfoDate2}>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Create Date</td>
                                <td>:</td>
                                {/* <td>{biding_start_date}</td> */}
                                <td>{biding_start_date}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Bids Closing Date</td>
                                <td>:</td>
                                {/* <td>{biding_end_date}</td> */}
                                <td>{biding_end_date}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </div>
    )
}

export default AppliedProjectGridCard
