import React, { useRef, useCallback, useState } from 'react'
import { Typography } from '@mui/material'

import { CloudUploadIcon } from '../../Assets'
import styles from './DragNDrop.module.css'
import { FileUploader } from 'react-drag-drop-files'
import './dnd.css'

const DragNDrop = ({ textSize, pdf, images, setImageSrc, getFile }) => {
    const inputFile = useRef()

    const [file, setFile] = useState(null)
    const handleChange = (file) => {
        setFile(file)
        if (pdf) {
            getFile(file, URL.createObjectURL(file))
            return
        }
        getFile(file, URL.createObjectURL(file))
        setImageSrc(URL.createObjectURL(file))
    }

    return (
        <div className={styles.dndBox}>
            <FileUploader handleChange={handleChange} name="file">
                {pdf && (
                    <div className={styles.dragBox}>
                        <Typography variant={textSize && 'h5'}>
                            Drag and drop a file here or click
                        </Typography>
                        <CloudUploadIcon
                            sx={{
                                color: 'var(--iconGreyColor)',
                                fontSize: '60px',
                            }}
                        />
                    </div>
                )}
                {images && (
                    <div className={styles.dragBox}>
                        <CloudUploadIcon
                            sx={{
                                color: 'var(--iconGreyColor)',
                                fontSize: '60px',
                            }}
                        />
                        <Typography
                            variant={textSize && 'h5'}
                            sx={{ my: '8px' }}
                        >
                            Drag and drop a file here or click
                        </Typography>
                        <Typography
                            variant={textSize && 'h5'}
                            sx={{ my: '8px' }}
                        >
                            Please upload .jpg, .png format
                        </Typography>
                        <Typography
                            variant={textSize && 'h5'}
                            sx={{ my: '8px' }}
                        >
                            Max size 5 mb
                        </Typography>
                    </div>
                )}
            </FileUploader>
        </div>
    )
}

export default DragNDrop
