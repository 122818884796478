import ActionTypes from '../Constants'

const INITIALSTATE = {
    fabCanvas: null,
}

const fabricCanvas = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case ActionTypes.SET_FAB_CANVAS:
            return {
                ...state,
                fabCanvas: action.payload,
            }
        default:
            return state
    }
}

export { fabricCanvas }
