import React from 'react'

import {
    HorizontalScrollContainer,
    HorizontalScrollItem,
} from 'react-simple-horizontal-scroller'
import { AccountAlert } from '../../Assets'
import styles from './ScrollAbleTabs.module.css'

export default function ScrollableTabs({ bgColor, hover, servicesTags }) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflow: 'hidden',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.tabWrapper}>
                    <HorizontalScrollContainer>
                        {servicesTags &&
                            servicesTags.map((tag, index) => {
                                return (
                                    <HorizontalScrollItem
                                        id="1"
                                        onClick={function noRefCheck() {}}
                                        className={styles.scrollTabsBar}
                                        key={index}
                                        style={{ background: hover && bgColor }}
                                    >
                                        {/* <img src={AccountAlert} /> */}
                                        <img
                                            src={
                                                tag?.service?.ImgURL +
                                                tag?.service?.icon
                                            }
                                            width={16}
                                            height={16}
                                            alt=""
                                        />
                                        {tag?.service?.title}
                                    </HorizontalScrollItem>
                                )
                            })}

                        {!servicesTags &&
                            [1, 2, 3, 45, 6, 78, 9, 78].map((tag, index) => {
                                return (
                                    <HorizontalScrollItem
                                        id="1"
                                        onClick={function noRefCheck() {}}
                                        className={styles.scrollTabsBar}
                                        key={index}
                                        style={{ background: hover && bgColor }}
                                    >
                                        <img src={AccountAlert} alt="" />
                                        SERVICES
                                    </HorizontalScrollItem>
                                )
                            })}
                    </HorizontalScrollContainer>
                </div>
            </div>
        </>
    )
}
