import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import styles from './productModals.module.css'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import ProductDetails from './ProductDetails'
import StickyHeadTable from './ProductTable'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    height: 'calc(100vh - 70px)',
    overflowY: 'hidden',
    outline: 'none',
}

export default function ViewAllProductModal({
    open,
    setOpen,
    setViewProductModal,
}) {
    //   const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            // firstForm
            productName: '',
        },
    })

    const onSubmit = () => {}
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={styles.modalMainBox}>
                        <Box
                            sx={{
                                px: 4,
                                py: 2,
                                // background: "green",
                                // maxHeight: "100%",
                                // display: "flex",
                            }}
                            className={styles.viewProductModalWrapper}
                        >
                            {/* FILTER HEADER */}
                            <Box></Box>

                            {/* ///BODY  */}

                            <Box sx={{ width: '100%', border: 'none' }}>
                                <StickyHeadTable
                                    handleClose={handleClose}
                                    setViewProductModal={setViewProductModal}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
