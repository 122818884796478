import React from 'react'

const formatDate = (dateString, timeString) => {
    const year = dateString.substring(0, 4)
    const month = dateString.substring(4, 6)
    const day = dateString.substring(6, 8)
    const hours = timeString.substring(0, 2)
    const minutes = timeString.substring(2, 4)
    const seconds = timeString.substring(4, 6)

    const formattedDate = `${year}-${month}-${day}`
    const formattedTime = `${hours}:${minutes}:${seconds}`

    return `${formattedDate} ${formattedTime}`
}

const NewFunc = ({ data }) => (
    <div className="mb-[3rem] mt-[2rem] w-full">
        <ul className="">
            <li className="before:content-['■'] before:mr-2 before:text-black">
                {data?.status?.description}
            </li>
            <li>{`${data?.location?.address?.city}, ${data?.location?.address?.countryCode}`}</li>
            <li>{formatDate(data?.date, data?.time)}</li>
        </ul>
    </div>
)

const TrackingOrderBox = ({ trackingData }) => (
    <div className="px-4 sm:px-6 md:px-8 lg:px-12 xl:px-6 h-full w-full">
        <div className="mt-3 mb-3 w-full max-w-8xl mx-auto px-3 py-4 border-gray-300 bg-white shadow-lg h-96 overflow-y-auto">
            <h1 className="mb-4 text-xl font-bold text-gray-800">
                Shipping Details
            </h1>
            <ul className="text-sm text-gray-700">
                <li className="font-semibold mb-2">Delivered</li>
                {trackingData.map((d, i) => (
                    <NewFunc data={d} key={i} />
                ))}
            </ul>
        </div>
    </div>
)

export default TrackingOrderBox
